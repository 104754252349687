import { theme, Typography, TypographyProps } from "@suraasa/placebo-ui"
import { CheckCircle, InfoEmpty, WarningCircle } from "iconoir-react"
import { useEffect } from "react"
import { createUseStyles } from "react-jss"

import { Toast } from "./types"

const useStyles = createUseStyles(({ spacing }) => ({
  root: {
    position: "relative",
    padding: theme.spacing(1.5),
    backgroundColor: "white",
    marginBottom: spacing(1),
    minWidth: 350,
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${theme.colors.onSurface[300]}`,
  },
}))

export type ToastItemProps<T> = {
  onExit?: (item: T) => void
  item: T
}

const getConfig = (
  variant: Toast["variant"]
): {
  icon: JSX.Element
  color: TypographyProps["color"]
} => {
  switch (variant) {
    case "error": {
      return {
        icon: <WarningCircle color={theme.colors.critical[500]} />,
        color: "critical.500",
      }
    }

    case "success": {
      return {
        icon: <CheckCircle color={theme.colors.success[500]} />,
        color: "success.500",
      }
    }

    default: {
      return {
        icon: <InfoEmpty color={theme.colors.primary[500]} />,
        color: "primary.500",
      }
    }
  }
}

export const ToastItem = ({ onExit, item }: ToastItemProps<Toast>) => {
  const classes = useStyles()

  useEffect(() => {
    if (!item.open) {
      if (onExit) onExit(item)
    }

    const timeout = setTimeout(() => {
      if (onExit) onExit(item)
    }, 3000)

    return () => {
      clearTimeout(timeout)
    }
  }, [onExit, item])

  const { icon, color } = getConfig(item.variant)

  return (
    <div className={classes.root}>
      <div className="flex items-center gap-1">
        {icon}
        <Typography color={color} variant="strongSmallBody">
          {item.message}
        </Typography>
      </div>
      {item.body && (
        <Typography className="ml-4" variant="smallBody">
          {item.body}
        </Typography>
      )}
    </div>
  )
}
