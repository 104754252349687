import {
  Avatar,
  Button,
  CircularProgress,
  Pagination,
  Tag,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { ActiveUser, Job, JobApplicantStatus } from "api/resources/jobs/types"
import { PaginatedResponse } from "api/types"
import { ReactComponent as VerifiedBadge } from "assets/home/verifiedBadge.svg"
import clsx from "clsx"
import { format } from "date-fns"
import React, { useCallback, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"
import { routes } from "utils/routes"

const useStyles = createUseStyles(theme => ({
  root: {
    [theme.breakpoints.down("md")]: {
      overflowX: "auto",
    },
  },
  container: {
    background: "white",
    borderRadius: "4px",
    border: `1px solid ${theme.colors.surface[200]}`,
    minWidth: "800px",
  },

  tableGrid: {
    display: "grid",
    gridTemplateColumns: "70px 1.25fr 1.25fr 1fr 1.5fr 50px",
  },

  tableItem: {
    borderTop: `1px solid ${theme.colors.surface[200]}`,
    position: "relative",
  },
  error: {
    borderLeft: `3px solid ${theme.colors.warning[400]}`,
  },

  image: {
    width: "32px",
    height: "32px",
  },

  textSmall: {
    fontSize: "12px",
    lineHeight: "15.6px",
  },

  warningIcon: {
    position: "absolute",
    left: "-40px",
    height: "100%",
    color: theme.colors.warning[500],
  },

  noApplicantsDiv: {
    background: "#f8fafc",
    border: `1px solid ${theme.colors.onSurface[200]}`,
    borderRadius: theme.spacing(0.5),
    paddingLeft: theme.spacing(9),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

type Props = {
  job: Pick<Job, "position" | "id" | "dateReopened">
}

const getJobApplicationStatus = (item: ActiveUser) => {
  switch (item.status) {
    case JobApplicantStatus.JOB_OFFER_REJECTED:
      return <Tag color="critical" label="Job Offer Rejected" />
    case JobApplicantStatus.WITHDRAWN:
      return <Tag color="critical" label="Application Withdrawn" />
    case JobApplicantStatus.JOB_OFFER_ACCEPTED:
      return <Tag color="success" label="Job Offer Accepted" />
    case JobApplicantStatus.CANCELLED:
      return <Tag color="onSurface" label="Cancelled" />
    case JobApplicantStatus.REJECTED:
      return <Tag color="onSurface" label="Application Rejected" />
    default:
      break
  }
}

const PreviousApplicationTab = ({ job }: Props) => {
  const classes = useStyles()

  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)

  // const applicants = useArray<ActiveUser>([])
  const [applicants, setApplicants] = useState<
    PaginatedResponse<ActiveUser[]>["data"]
  >({
    data: [],
    nextPage: null,
    previousPage: null,
    total: 0,
  })

  /**
   * isCreating is used to differentiate,
   * whether the dialog was opened for add or edit
   * for interview and live demo
   */

  const listApplicants = useCallback(async () => {
    setLoading(true)
    const res = await api.jobs.jobApplicant.listActive({
      urlParams: {
        jobId: job.id,
      },
      params: {
        status: "previous",
        page,
      },
    })
    if (res.isSuccessful) {
      setApplicants(res.data)
      setLoading(false)
    }
  }, [page, job.id])

  useEffect(() => {
    if (job.id) listApplicants()
  }, [job.id, page, listApplicants])

  if (loading)
    return (
      <div className="flex justify-center mt-5">
        <CircularProgress />
      </div>
    )

  return (
    <>
      <div className={clsx(classes.root, "mt-1.5 mb-3")}>
        <div className={classes.container}>
          <div className={clsx(classes.tableGrid, "py-2")}>
            <div />
            <Typography variant="strongSmallBody">Name</Typography>

            <div className="flex items-center gap-0.5">
              <Typography variant="strongSmallBody">Status</Typography>
            </div>

            <Typography variant="strongSmallBody">Time</Typography>
          </div>
          {applicants.data.length > 0 ? (
            <>
              {applicants.data.map(item => (
                <div
                  className={clsx(
                    classes.tableGrid,
                    classes.tableItem,

                    "py-2"
                  )}
                  key={item.id}
                >
                  <div className="pl-3">
                    <Avatar
                      name={item.user.fullName}
                      size="xs"
                      src={item.user.profile.picture ?? undefined}
                    />
                  </div>
                  <div>
                    <div className="flex items-center gap-0.5">
                      <Button
                        className="-ml-0.75"
                        component={Link}
                        to={routes.teacherTimeline
                          .replace(":jobApplicantId", String(item.id))
                          .replace(":username", item.user.username)}
                        variant="link"
                      >
                        {item.user.fullName}
                      </Button>
                      {item.user.profile.isVerified && <VerifiedBadge />}
                    </div>

                    <div className="flex items-center">
                      <Typography
                        className={classes.textSmall}
                        color="onSurface.500"
                      >
                        {item.user.email}
                      </Typography>
                    </div>

                    {item.user.profile.phoneNumber && (
                      <Typography
                        className={classes.textSmall}
                        color="onSurface.500"
                      >
                        +{item.user.profile.phoneNumber.code}
                        {item.user.profile.phoneNumber.number}
                      </Typography>
                    )}
                  </div>

                  <div className="flex flex-col justify-center">
                    {getJobApplicationStatus(item)}
                  </div>

                  <div className="flex flex-col justify-center">
                    {item.currentStep?.dueDate ? (
                      <>
                        <Typography variant="smallBody">
                          <b>
                            {format(
                              new Date(item.currentStep.dueDate),
                              "d MMM yyyy"
                            )}
                          </b>
                          <br />
                          {format(
                            new Date(item.currentStep.dueDate),
                            "hh:mm aa"
                          )}
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="smallBody">-</Typography>
                    )}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div>
              <Typography
                className={classes.noApplicantsDiv}
                color="onSurface.500"
                variant="smallBody"
              >
                No Previous Applications
              </Typography>
            </div>
          )}
        </div>
      </div>
      <Pagination page={page} total={applicants.total} onChange={setPage} />
    </>
  )
}
export default PreviousApplicationTab
