import { Typography } from "@suraasa/placebo-ui"
// import { InfoEmpty } from "iconoir-react"
import PreviewJobCard from "components/jobs/PreviewJobCard"
import JobCard from "components/profile/JobCard"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  svg: {
    width: theme.spacing(2.09),
    height: theme.spacing(2.09),
  },
}))

type PreviewJobProps = {
  currencyCode?: string
  position: string
  salaryOffered: number
  maximumSalaryOffered: number | null
  subject?: string
}

const PreviewJob = ({
  currencyCode,
  position,
  salaryOffered,
  maximumSalaryOffered,
  subject,
}: PreviewJobProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const classes = useStyles()
  return (
    <div>
      <div className="flex items-center gap-1 mb-2">
        <Typography variant="preTitle">Public Job Listing</Typography>
        {/* <InfoEmpty className={classes.svg} color="#64748B" /> */}
      </div>
      <PreviewJobCard
        currencyCode={currencyCode}
        datePublished={new Date().toISOString()}
        maximumSalaryOffered={maximumSalaryOffered}
        position={position}
        salaryOffered={salaryOffered}
        subject={subject}
      />
      <div className="flex items-center gap-1 mt-4 mb-2">
        <Typography variant="preTitle">Your profile</Typography>
        {/* <InfoEmpty className={classes.svg} color="#64748B" /> */}
      </div>
      <div className="grid grid-cols-8">
        <div className="col-span-8 md:col-span-4">
          <JobCard
            currencyCode={currencyCode}
            datePublished={new Date().toISOString()}
            id={-1}
            maximumSalaryOffered={maximumSalaryOffered}
            position={position}
            salaryOffered={salaryOffered}
            subject={subject}
            previewMode
          />
        </div>
      </div>
    </div>
  )
}

export default PreviewJob
