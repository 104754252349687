import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { JobApplicantStepStatus } from "api/resources/jobs/types"
import React from "react"
import { ToggleValue } from "utils/hooks/useToggle"
import toast from "utils/toast"

export type MarkAsCompleteDialogData = {
  id: number
  name: string
  afterSubmit?: () => void
}

type Props = {
  toggle: ToggleValue
  data: MarkAsCompleteDialogData
} & Pick<DialogProps, "open">

const MarkAsCompleteDialog = ({
  open,
  data: { id, afterSubmit, name },
  toggle,
}: Props) => {
  const handleSubmit = async () => {
    const res = await api.jobs.jobApplicantStep.update({
      urlParams: {
        jobApplicantStepId: id,
      },
      data: {
        status: JobApplicantStepStatus.COMPLETED,
      },
    })
    if (res.isSuccessful) {
      toast.success(`${name} marked completed`)
      toggle()
      if (afterSubmit) afterSubmit()
    }
  }

  return (
    <Dialog open={open} width="md" onRequestClose={() => toggle()}>
      <DialogTitle>Mark as Complete</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to mark <b>{name}</b> as complete?
        </Typography>
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: "Mark as Complete",
            onClick: handleSubmit,
          },
        }}
      />
    </Dialog>
  )
}

export default MarkAsCompleteDialog
