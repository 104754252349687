import { AuthData } from "api/resources/users/types"
import { transformKeys } from "api/utils"
import camelCase from "lodash/camelCase"
import { BROWSER_STORAGE_KEYS } from "utils/constants"

export function saveAuthInfo(authInfo: AuthData) {
  const newAuthInfoObj: AuthData = transformKeys(authInfo, camelCase)
  localStorage.setItem(
    BROWSER_STORAGE_KEYS.auth,
    JSON.stringify(newAuthInfoObj)
  )
}

export function getAuthInfo() {
  const authInfo = localStorage.getItem(BROWSER_STORAGE_KEYS.auth)

  if (authInfo !== null) {
    const newAuthInfoObj: AuthData = transformKeys(
      JSON.parse(authInfo),
      camelCase
    )
    return newAuthInfoObj
  }

  return authInfo
}

export function clearAuthInfo() {
  const { auth, schoolId } = BROWSER_STORAGE_KEYS
  localStorage.removeItem(auth)
  localStorage.removeItem(schoolId)
}

export function getSchoolId() {
  return localStorage.getItem(BROWSER_STORAGE_KEYS.schoolId)
}

export function setSchoolId(id: string) {
  localStorage.setItem(BROWSER_STORAGE_KEYS.schoolId, id)
}
