import assessments from "./resources/assessments"
import assignments from "./resources/assignments"
import global from "./resources/global"
import help from "./resources/help"
import jobs from "./resources/jobs"
import notifications from "./resources/notifications"
import profile from "./resources/profile"
import school from "./resources/school"
import teacher from "./resources/teacher"
import users from "./resources/users"

export default {
  help,
  jobs,
  school,
  profile,
  assessments,
  assignments,
  notifications,
  users,
  teacher,
  global,
}
