import TabItem from "components/Tab/TabItem"
import React from "react"
import { createUseStyles } from "react-jss"
import { Tab, Tabs as TabsType } from "utils/hooks/useTabs"

const useStyles = createUseStyles({
  tabContainer: {
    display: "flex",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
})

type Props = {
  tabs: TabsType
  activeTab: Tab
  onChange: (newTab: string) => void
}

const Tabs = ({ tabs, activeTab, onChange }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.tabContainer}>
      {tabs.map(tab => (
        <TabItem
          isActive={tab.name === activeTab.name}
          key={tab.name}
          onClick={() => onChange(tab.name)}
        >
          {tab.startAdornment ? (
            <div className="flex items-center gap-1">
              <>
                {tab.startAdornment} {tab.name}
              </>
            </div>
          ) : (
            <>{tab.name}</>
          )}
        </TabItem>
      ))}
    </div>
  )
}

export default Tabs
