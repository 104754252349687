import { Typography } from "@suraasa/placebo-ui"
import { Gender } from "api/resources/teacher/types"
import { Female, Male, Minus, PinAlt, WhiteFlag } from "iconoir-react"
import React, { useContext } from "react"
import { createUseStyles } from "react-jss"
import ProfileContext from "views/teacher/profileContext"

import Section from "./Section"
import SectionContent from "./Section/SectionContent"
import SectionHeading from "./Section/SectionHeading"
import SectionTitle from "./Section/SectionTitle"

const useStyles = createUseStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    gap: theme.spacing(3),
  },

  tile: { display: "flex", alignItems: "center" },
  tileData: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4 / 8),
  },
  iconContainer: {
    display: "grid",
    placeItems: "center",
    border: `${theme.spacing(1 / 8)} solid ${theme.colors.surface[200]}`,
    color: theme.colors.onSurface[500],
    width: theme.spacing(43 / 8),
    height: theme.spacing(43 / 8),
    marginRight: theme.spacing(1),

    "&>svg": {
      width: theme.spacing(20 / 8),
      height: theme.spacing(20 / 8),
    },
  },
}))

const PersonalDetailTile = ({
  heading,
  value,
  icon,
}: {
  heading: string
  value?: number | string | null
  icon: React.ReactNode
}) => {
  const classes = useStyles()
  return (
    <div className={classes.tile}>
      <div className={classes.iconContainer}>{icon} </div>
      <div className={classes.tileData}>
        <Typography color="onSurface.500" variant="smallBody">
          {heading}
        </Typography>
        <Typography color="onSurface.800" variant="strong">
          {value ?? "-"}
        </Typography>
      </div>
    </div>
  )
}

function getGenderIcon(gender: Gender | null): React.ReactNode {
  if (!gender) return <Minus />

  if (Gender.MALE === gender) {
    return <Male />
  }
  if (Gender.FEMALE === gender) {
    return <Female />
  }

  return <Minus />
}

function getGenderDisplay(gender: Gender | null): string {
  if (!gender) return "-"

  if (Gender.MALE === gender) {
    return "Male"
  }
  if (Gender.FEMALE === gender) {
    return "Female"
  }

  return "Prefer not to say"
}

const PersonalInfo = () => {
  const classes = useStyles()

  const {
    profile: { country, gender, nationality },
  } = useContext(ProfileContext)

  return (
    <Section>
      <SectionHeading heading={<SectionTitle title="Additional Details" />} />
      <SectionContent className={classes.root} xPadding={2}>
        <PersonalDetailTile
          heading="Location"
          icon={<PinAlt />}
          value={country?.name}
        />

        <PersonalDetailTile
          heading="Gender"
          icon={getGenderIcon(gender)}
          value={getGenderDisplay(gender)}
        />
        <PersonalDetailTile
          heading="Nationality"
          icon={<WhiteFlag />}
          value={nationality?.name}
        />
      </SectionContent>
    </Section>
  )
}

export default PersonalInfo
