import {
  Button,
  CircularProgress,
  Container,
  Theme,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import { Profile, SkillEvidence } from "api/resources/teacher/types"
import Navbar from "components/shared/Navbar"
import ReactHelmet from "components/shared/ReactHelmet"
import SlidingSheet from "components/SlidingSheet"
import { NavArrowLeft } from "iconoir-react"
import metadata from "metadata.json"
import { useEffect, useState } from "react"
import { useTheme } from "react-jss"
import { useNavigate, useParams } from "react-router-dom"

import {
  SkillProfileRoutes,
  useGenerateURL,
} from "./hooks/useSkillProfileNavigation"
import EvidenceDetails from "./SlidingSheetPages/EvidenceDetails"
import Evidences from "./SlidingSheetPages/Evidences"
import SkillOverview from "./SlidingSheetPages/SkillOverview"
import VerificationProcess from "./SlidingSheetPages/VerificationProcess"

const SkillProfile = () => {
  const navigate = useNavigate()
  const { username } = useParams<{ username?: string }>()
  const theme = useTheme<Theme>()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const {
    navigate: skillPortfolioNavigation,
    isOverview,
    isEvidence,
    isEvidenceDetails,
    isVerificationProcess,
  } = useGenerateURL()

  const [profile, setProfile] = useState<Profile>()

  useEffect(() => {
    api.teacher.profile
      .retrieve({
        urlParams: {
          username: username ?? "",
        },
      })
      .then(res => {
        if (res.isSuccessful) {
          setProfile(res.data)
        }
      })
  }, [username])

  const [skillEvidenceList, setSkillEvidenceList] = useState<{
    skills: SkillEvidence[]
    user: { fullName: string; photo: string | null } | null
  }>()

  useEffect(() => {
    api.teacher.skillEvidences
      .list({
        urlParams: {
          username: username ?? "",
        },
      })
      .then(res => {
        if (res.isSuccessful) {
          setSkillEvidenceList(res.data)
        }
      })
  }, [username])

  if (!profile || !skillEvidenceList)
    return (
      <div className="flex items-center justify-center p-5">
        <CircularProgress />
      </div>
    )

  const pages = (
    <>
      {isEvidence && <Evidences skillEvidences={skillEvidenceList.skills} />}

      {isEvidenceDetails && (
        <EvidenceDetails firstName={profile.user.firstName} />
      )}

      {isVerificationProcess && <VerificationProcess />}
    </>
  )

  const getBackButton = () => (
    <Button
      className="-ml-1"
      color="black"
      startAdornment={<NavArrowLeft />}
      variant="text"
      onClick={() => {
        if (window.history?.state?.idx > 1) {
          navigate(-1)
        } else {
          skillPortfolioNavigation(SkillProfileRoutes.OVERVIEW)
        }
      }}
    >
      Back
    </Button>
  )

  if (isXsDown) {
    return (
      <div>
        {isOverview && (
          <SkillOverview
            profile={profile}
            skillEvidences={skillEvidenceList.skills}
          />
        )}
        {!isOverview && <div className="px-3 mt-3">{getBackButton()}</div>}
        {pages}
      </div>
    )
  }

  return (
    <>
      <Navbar hideBackButton />
      <ReactHelmet data={metadata.home} />
      <Container className="pb-8">
        <SkillOverview
          profile={profile}
          skillEvidences={skillEvidenceList.skills}
        />
        <SlidingSheet
          className="flex flex-col h-screen rounded-l-2xl md:w-3/6 sm:w-3/4"
          from={isXsDown ? "bottom" : "end"}
          open={!isOverview}
          onClose={() => skillPortfolioNavigation(SkillProfileRoutes.OVERVIEW)}
        >
          <div className="px-3 pt-1 mt-3">{getBackButton()}</div>
          {pages}
        </SlidingSheet>
      </Container>
    </>
  )
}

export default SkillProfile
