import { Typography } from "@suraasa/placebo-ui"
import Section from "components/teacher/profile/Section"
import SectionContent from "components/teacher/profile/Section/SectionContent"
import SectionHeading from "components/teacher/profile/Section/SectionHeading"
import SectionTitle from "components/teacher/profile/Section/SectionTitle"
import capitalize from "lodash/capitalize"
import { useContext } from "react"
import { createUseStyles } from "react-jss"
import { LanguageProficiency } from "utils/constants"
import { pluralize } from "utils/helpers"
import ProfileContext from "views/teacher/profileContext"

const useStyles = createUseStyles(theme => ({
  sectionContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
}))

const Languages = () => {
  const classes = useStyles()

  const {
    languages: { data },
  } = useContext(ProfileContext)

  return (
    <>
      <Section>
        <SectionHeading
          heading={
            <SectionTitle
              itemCount={data.length}
              title={pluralize("Language", data.length, { skipCount: true })}
            />
          }
        />

        <SectionContent className={classes.sectionContent} xPadding={2}>
          {data.length > 0 ? (
            data.map((item, index) => (
              <div className="flex flex-col" key={item.id || index}>
                {item.language && (
                  <Typography color="onSurface.800" variant="strong">
                    {item.language.name}
                  </Typography>
                )}

                {item.proficiency && (
                  <Typography color="onSurface.500" variant="smallBody">
                    {capitalize(
                      LanguageProficiency[item.proficiency].replace("_", " ")
                    )}
                  </Typography>
                )}
              </div>
            ))
          ) : (
            <Typography color="onSurface.400" variant="smallBody">
              Looks like there's nothing here.
            </Typography>
          )}
        </SectionContent>
      </Section>
    </>
  )
}

export default Languages
