import { Button, theme } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { ArrowLeft } from "iconoir-react"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
  container: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      background: theme.colors.common.black[700],
      height: "100vh",
      width: "100vw",
      position: "fixed",
      zIndex: 100,
      top: 0,
      left: 0,
    },
  },

  profileImg: {
    width: "100%",
    maxWidth: "100%",
    maxHeight: "80%",
  },
})

function ViewProfilePicture({
  image,
  onBack,
  onClickChange,
}: {
  image: string
  onBack: () => void
  onClickChange: () => void
}) {
  const classes = useStyles()
  return (
    <div
      className={clsx(
        classes.container,
        "flex flex-col items-center justify-between pb-5 pt-2.75"
      )}
    >
      <div className="pl-2 mr-auto">
        <ArrowLeft color="white" onClick={onBack} />
      </div>
      <img alt="profile" className={classes.profileImg} src={image} />
      <Button color="white" variant="text" onClick={onClickChange}>
        Change Profile Picture
      </Button>
    </div>
  )
}

export default ViewProfilePicture
