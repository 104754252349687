import {
  Award,
  Certification,
  Interest,
  LanguageResponse,
  Profile,
  Project,
  Publication,
  Qualification,
  SkillEvidencesOverview,
  SubjectResponse,
  TestScore,
  WorkExperienceType,
  WorkLocationInterest,
} from "api/resources/teacher/types"
import React from "react"
import { UseArray } from "utils/hooks/useArray"

type ProfileItem<T> = {
  data: UseArray<T>["array"]
}

type Context = {
  profile: Profile
  languages: ProfileItem<Partial<LanguageResponse>>
  workExperiences: ProfileItem<Partial<WorkExperienceType>>
  subjects: ProfileItem<Partial<SubjectResponse>>
  achievements: {
    awards: ProfileItem<Partial<Award>>
    projects: ProfileItem<Partial<Project>>
    publications: ProfileItem<Partial<Publication>>
    testScores: ProfileItem<Partial<TestScore>>
  }
  academics: {
    certifications: ProfileItem<Partial<Certification>>
    qualifications: ProfileItem<Partial<Qualification>>
  }
  workLocationInterest: ProfileItem<Partial<WorkLocationInterest>>
  interests: ProfileItem<Partial<Interest>>
  skillEvidences: SkillEvidencesOverview
}

const defaultProfileItem = {
  data: [],
}

const ProfileContext = React.createContext<Context>({
  profile: {
    id: "",
    careerAspiration: null,
    countriesWorkedIn: [],
    country: null,
    currentWorkingExperience: null,
    dateOfBirth: null,
    gender: null,
    isVerified: false,
    lookingForJobs: false,
    nationality: null,
    phoneNumber: null,
    picture: null,
    state: null,
    user: {
      uuid: "",
      email: "",
      firstName: "",
      lastName: "",
      middleName: "",
      username: "",
    },
  },
  languages: defaultProfileItem,
  workExperiences: defaultProfileItem,
  subjects: defaultProfileItem,
  workLocationInterest: defaultProfileItem,
  achievements: {
    awards: defaultProfileItem,
    projects: defaultProfileItem,
    publications: defaultProfileItem,
    testScores: defaultProfileItem,
  },
  academics: {
    certifications: defaultProfileItem,
    qualifications: defaultProfileItem,
  },
  interests: defaultProfileItem,
  skillEvidences: {
    advancedSkillCount: 0,
    beginnerSkillCount: 0,
    intermediateSkillCount: 0,
    professional: 0,
  },
})

export default ProfileContext
