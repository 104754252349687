import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  Typography,
} from "@suraasa/placebo-ui"
// import { useTimer } from "react-timer-hook"
import api from "api"
import LoadingOverlay from "components/shared/LoadingOverlay"
import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import OtpInput from "react-otp-input"
import { handleErrors } from "utils/helpers"

type Props = {
  toggle: () => void
  afterSubmit: () => void
  generateOtp: (email: string, password: string) => void
  otpToken: string
  details?: { email: string | null } | null
} & Pick<DialogProps, "open">

type Form = {
  otp: string
}

const useStyles = createUseStyles(theme => ({
  containerStyle: {
    marginTop: theme.spacing(4),
    justifyContent: "space-between",
  },
  inputStyle: {
    borderRadius: 4,
    width: `41px !important`,
    height: 50,
    border: `2px solid ${theme.colors.onSurface[500]}`,
    ...theme.typography.title2,
  },
  focusStyle: {
    outline: `2px solid ${theme.colors.interactive[400]}`,
    outlineOffset: "1px",
  },
  errorStyle: {
    border: `2px solid ${theme.colors.critical[500]}`,
  },
  displaySentToContainer: {
    wordBreak: "break-word",
  },
}))

// function Timer({
//   expiryTimestamp,
//   onExpire,
// }: {
//   expiryTimestamp: string
//   onExpire: () => void
// }) {
//   const { seconds, minutes } = useTimer({
//     expiryTimestamp: new Date(expiryTimestamp),
//     onExpire,
//   })

//   return (
//     <>
//       {`${minutes.toString().padStart(2, "0")}:${seconds
//         .toString()
//         .padStart(2, "0")}`}
//     </>
//   )
// }
const OtpGenDialog = ({
  open,
  toggle,
  afterSubmit,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  generateOtp,
  otpToken,
  details,
}: Props) => {
  const {
    control,
    reset,
    setError,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<Form>()

  const classes = useStyles()
  const [otpGenerated, setOtpGenerated] = useState<boolean | null>(null)
  // const [canResendOtp, setCanResendOtp] = useState(false)
  // const [resendAfter, setResendAfter] = useState<string>(String(time))

  const onSubmit = handleSubmit(async formData => {
    const res = await api.users.changeEmailID({
      data: { token: otpToken, otp: formData.otp },
    })
    if (res.isSuccessful) {
      setOtpGenerated(false)
      afterSubmit()
      toggle()
    } else {
      handleErrors(setError, res.errors)
      setOtpGenerated(false)
    }
  })

  useEffect(() => {
    if (otpToken) {
      setOtpGenerated(false)
    } else {
      setOtpGenerated(true)
    }
  }, [otpToken])

  // const renderTimer = useCallback(() => {
  //   if (!canResendOtp && resendAfter) {
  //     return (
  //       <Timer
  //         expiryTimestamp={resendAfter}
  //         onExpire={() => setCanResendOtp(true)}
  //       />
  //     )
  //   }
  //   return (
  //     <Button
  //       disabled={!canResendOtp}
  //       size="sm"
  //       type="button"
  //       variant="text"
  //       onClick={regenOTP}
  //     >
  //       Send Again
  //     </Button>
  //   )
  // }, [canResendOtp, resendAfter, regenOTP])

  return (
    <Dialog
      open={open}
      shouldCloseOnOverlayClick={false}
      width={358}
      onAfterClose={() => reset({})}
      onRequestClose={() => toggle()}
    >
      <DialogTitle>Verify Your Email ID</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          {otpGenerated === null && <LoadingOverlay />}
          <Typography color="onSurface.800" display="inline">
            OTP sent to{" "}
          </Typography>
          <Typography
            className={classes.displaySentToContainer}
            color="onSurface.800"
            display="inline"
            variant="strong"
          >
            {details?.email}
          </Typography>
          <Controller
            control={control}
            name="otp"
            render={({ field }) => (
              <OtpInput
                numInputs={6}
                {...field}
                containerStyle={classes.containerStyle}
                errorStyle={classes.errorStyle}
                focusStyle={classes.focusStyle}
                hasErrored={Boolean(errors.otp)}
                inputStyle={classes.inputStyle}
                isInputNum
                shouldAutoFocus
              />
            )}
          />
          {Boolean(errors.otp) && (
            <Typography
              className="mt-2"
              color="critical.500"
              variant="smallBody"
            >
              {errors.otp?.message}
            </Typography>
          )}
          <div className="mt-6">
            {/* <Typography color="onSurface.800" display="inline">
              {!canResendOtp ? "You can resend otp in" : "Didn't receive OTP?"}{" "}
            </Typography>
            {renderTimer()} */}
          </div>
        </form>
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: "Verify",
            onClick: onSubmit,
            loading: isSubmitting,
          },
        }}
      />
    </Dialog>
  )
}

export default OtpGenDialog
