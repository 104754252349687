import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getSolisURL = getServiceURL(ServicePrefix.solis)
const getNebulaURL = getServiceURL(ServicePrefix.nebula)

export const urls = validateUrls({
  profile: {
    public: username =>
      getSolisURL(`/v1/profile/public/${username ? `${username}/` : ""}`),
    resume: userId => `/jobs/users/${userId}/download-resume/`,
  },

  videoPortfolio: {
    list: username =>
      `/profile/public/${username ? `${username}/` : ""}video-portfolio/`,
  },

  languages: {
    list: username =>
      getSolisURL(
        `/v1/profile/public/languages/${username ? `${username}/` : ""}`
      ),
  },

  interests: {
    list: username =>
      getSolisURL(
        `/v1/profile/public/interests/${username ? `${username}/` : ""}`
      ),
  },

  notes: {
    base: jobApplicantId => `/jobs/applicants/${jobApplicantId}/notes/`,
  },

  achievements: {
    list: username =>
      getSolisURL(
        `/v1/profile/public/achievements/${username ? `${username}/` : ""}`
      ),
  },

  workExperiences: {
    list: username =>
      getSolisURL(
        `/v1/profile/public/work-experiences/${username ? `${username}/` : ""}`
      ),
  },
  academics: {
    list: username =>
      getSolisURL(
        `/v1/profile/public/academics/${username ? `${username}/` : ""}`
      ),
  },

  liveDemo: {
    feedback: liveDemoId =>
      getNebulaURL(`/v1/live-demos/${liveDemoId}/feedback/`),
    create: () => getNebulaURL(`/v1/live-demos/`),
    detail: liveDemoId => getNebulaURL(`/v1/live-demos/${liveDemoId}/`),
  },

  interview: {
    list: () => getNebulaURL(`/v1/interviews/`),
    detail: interviewId => getNebulaURL(`/v1/interviews/${interviewId}/`),
    feedback: interviewId =>
      getNebulaURL(`/v1/interviews/${interviewId}/feedback/`),
  },
  jobApplicantStep: {
    list: jobApplicantId =>
      getNebulaURL(`/v1/hiring/applicants/${jobApplicantId}/steps/`),
    detail: id => getNebulaURL(`/v1/hiring/steps/${id}/`),
  },
  explore: {
    list: () => `/jobs/explore-teachers/`,
  },
  skillEvidences: {
    overview: username => `/analytics/public/${username}/skill-evidences/`,
    list: username => `/analytics/public/user-skill-portfolio/${username}/`,
  },
})
