import {
  Dialog,
  DialogContent,
  DialogTitle,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import React from "react"
import { createUseStyles } from "react-jss"

import ViewPortfolio from "./ViewPortfolio"

const useStyles = createUseStyles({
  root: {
    padding: theme.spacing(2, 3),
    [theme.breakpoints.only("xs")]: {
      padding: 0,
    },
  },
})

type Props = {
  video: string | null
  open: boolean
  handleClose: () => void
  setVideoPortfolioURL: React.Dispatch<React.SetStateAction<string | null>>
}

const VideoPortfolioDialog = ({ video, open, ...props }: Props) => {
  const classes = useStyles()
  const fullScreen = useMediaQuery(theme.breakpoints.only("xs"))

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      width={536}
      onRequestClose={() => props.handleClose()}
    >
      <div className={classes.root}>
        <DialogTitle>Video Portfolio</DialogTitle>
        <DialogContent>
          <ViewPortfolio videoURL={video} />
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default VideoPortfolioDialog
