import { Typography } from "@suraasa/placebo-ui"
import Section from "components/teacher/profile/Section"
import SectionHeading from "components/teacher/profile/Section/SectionHeading"
import SectionTitle from "components/teacher/profile/Section/SectionTitle"
import React, { useContext } from "react"
import ProfileContext from "views/teacher/profileContext"

import SectionContent from "../Section/SectionContent"
import Certifications from "./Certifications"
import Qualifications from "./Qualifications"

const Academics = () => {
  const {
    academics: { qualifications, certifications },
  } = useContext(ProfileContext)

  return (
    <Section>
      <SectionHeading
        heading={<SectionTitle title="Academics" />}
        xPadding={3}
      />
      <SectionContent xPadding={3}>
        {qualifications.data.length !== 0 && (
          <div className="pt-1">
            <Typography variant="subtitle2">Qualifications</Typography>
            <Qualifications />
          </div>
        )}

        {certifications.data.length !== 0 && (
          <div className="pt-1">
            <Typography className="mt-1" variant="subtitle2">
              Certifications
            </Typography>
            <Certifications />
          </div>
        )}

        {qualifications.data.length === 0 &&
          certifications.data.length === 0 && (
            <Typography color="onSurface.400" variant="smallBody">
              Looks like there's nothing here
            </Typography>
          )}
      </SectionContent>
    </Section>
  )
}

export default Academics
