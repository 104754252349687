import { GlobalContext } from "components/GlobalState"
import ShareSheet from "components/shared/ShareSheet"
import React, { useContext, useState } from "react"
import { createUseStyles } from "react-jss"
import { getShareJobContent } from "utils/helpers"

type Props = {
  jobSlug: string
  schoolSlug: string
  position: string
  subject: string
  activator: (handleOpen: () => void) => void
}

const useStyles = createUseStyles(() => ({
  root: {
    width: "100%",
  },
}))

const ShareJobButton = ({
  activator,
  schoolSlug,
  jobSlug,
  position,
  subject,
}: Props) => {
  const { profile } = useContext(GlobalContext)
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  const shareURL = `${process.env.REACT_APP_JOBS_PLATFORM_URL}/school/${schoolSlug}?jobSlug=${jobSlug}`

  const contentData = {
    profile,
    job: {
      slug: jobSlug,
      position,
      subject,
    },
  }

  return (
    <div className={classes.root}>
      <>{activator(() => setOpen(true))}</>
      <ShareSheet
        data={{
          url: shareURL,
          text: "",
          platformSpecificText: {
            linkedin: getShareJobContent("linkedin", contentData),
            whatsapp: getShareJobContent("whatsapp", contentData),
            facebook: getShareJobContent("facebook", contentData),
            telegram: getShareJobContent("telegram", contentData),
          },
        }}
        handleClose={() => setOpen(false)}
        open={open}
      />
    </div>
  )
}

export default ShareJobButton
