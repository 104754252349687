import { CircularProgress, Typography } from "@suraasa/placebo-ui"
import api from "api"
import { Submission as SubmissionType } from "api/resources/assignments/types"
import { GlobalContext } from "components/GlobalState"
import { InfoEmpty } from "iconoir-react"
import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { getAuthInfo } from "utils/auth"
import { useCopyDisable } from "utils/hooks/useCopyDisable"

import {
  SkillProfileRoutes,
  useGenerateURL,
} from "../../hooks/useSkillProfileNavigation"
import Submission from "./Submission"
import SubmissionPreview from "./Submission/SubmissionPreview"

const EvidenceDetails = ({ firstName }: { firstName: string }) => {
  const { submissionId } = useParams() as { submissionId: string }
  const { isVerified, isSalesVerified } = useContext(GlobalContext)

  const { navigate: customNavigation } = useGenerateURL()

  useCopyDisable()

  const [submissionData, setSubmissionData] = useState<SubmissionType>()

  const isCompletelyVerified = isVerified && isSalesVerified

  const isAllowedToSeeSubmission =
    Boolean(getAuthInfo()) && isCompletelyVerified

  useEffect(() => {
    const init = async () => {
      const res = await api.assignments.retrieveSubmission({
        urlParams: { submissionId },
        headers: {
          Authorization: null,
        },
      })
      if (res.isSuccessful) {
        setSubmissionData(res.data)
      }
    }

    init()
  }, [submissionId])

  if (!submissionData) {
    return (
      <div className="flex justify-center h-full p-5">
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className="mt-2.5 px-3 pb-4">
      <Typography className="mb-2" color="onSurface.800">
        Following content represents {firstName}’s original work on{" "}
        <strong>{submissionData?.assignment.title}</strong> which has undergone
        multiple levels of quality assurance and plagiarism checks by Suraasa.
      </Typography>
      <button
        className="flex items-center w-full px-2 py-1.5 bg-highlight-50 border-highlight-100 border rounded-xl mb-3"
        onClick={() =>
          customNavigation(SkillProfileRoutes.VERIFICATION_PROCESS)
        }
      >
        <InfoEmpty
          className="hidden me-2 text-highlight-500 shrink-0 sm:block"
          height={20}
          width={20}
        />
        <Typography
          color="highlight.900"
          textAlign="left"
          variant="strongSmallBody"
        >
          Learn how Suraasa verifies every single skill evidence (assignment)
        </Typography>
      </button>

      <Typography className="mb-2" color="onSurface.800" variant="title3">
        Submission
      </Typography>

      <div className="relative select-none">
        {isAllowedToSeeSubmission ? (
          <Submission
            attachments={submissionData.attachments}
            content={submissionData.submission}
            references={submissionData.references}
          />
        ) : (
          <SubmissionPreview
            content={`
            <p style="font-size: 1em; font-weight: bold; margin-bottom: 24px;">Assignment Submission</p>
            <p style="font-size: 1.5em; font-weight: bold;">Submitted By: ${firstName}</p>
            <p style="font-size: 2em; font-weight: bold;">${submissionData?.assignment.title}</p>
            <p style="font-size: 1em; margin-top: 32px;">${submissionData?.assignment.title} refers to...</p>
            `}
            isCompletelyVerified={isCompletelyVerified}
          />
        )}
      </div>
    </div>
  )
}

export default EvidenceDetails
