import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  AssessmentDetailsStep,
  JobApplicantStepStatus,
} from "api/resources/jobs/types"
import RemoveDialog from "components/RemoveDialog"
import Section from "components/Section"
import SectionContent from "components/Section/SectionContent"
import { format } from "date-fns"
import { EditPencil, Trash } from "iconoir-react"
import { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { getTag } from "utils/getStepStatusTag"
import useToggle from "utils/hooks/useToggle"
import { routes } from "utils/routes"
import toast from "utils/toast"
import TeacherTimelineContext from "views/teacher/context"

import EditAssessmentDueDate from "../Dialogs/EditAssessmentDueDate"

const Card = (props: AssessmentDetailsStep) => {
  const {
    id: jobApplicantStepId,
    step: { id, title },
    status,
    dueDate,
    dateCreated,
  } = props

  const { isRejected, assessments } = useContext(TeacherTimelineContext)

  const [assessmentDueDateDialog, toggleAssessmentDueDateDialog] =
    useToggle(false)
  const [openRemoveDialog, toggleRemoveDialog] = useToggle(false)
  const [removeLoading, setRemoveLoading] = useState(false)

  const handleRemove = async () => {
    setRemoveLoading(true)
    const res = await api.jobs.jobApplicantStep.delete({
      urlParams: {
        jobApplicantStepId,
      },
    })
    if (res.isSuccessful) {
      assessments.remove(jobApplicantStepId)
      toast.success("Assessment removed successfully")
    } else {
      toast.error(res.errors.message || "Assessment could not be removed")
    }
    setRemoveLoading(false)
  }

  const getAction = () => {
    switch (status) {
      case JobApplicantStepStatus.COMPLETED:
        return (
          <Button
            component={Link}
            to={routes.assessmentResult.replace(
              ":jobApplicantStepId",
              String(jobApplicantStepId)
            )}
            variant="text"
          >
            View Result
          </Button>
        )
      case JobApplicantStepStatus.NOT_STARTED:
        return (
          <>
            <Menu
              menuButton={
                <IconButton disabled={isRejected}>
                  <EditPencil />
                </IconButton>
              }
              overflow="auto"
              position="auto"
            >
              <MenuItem>
                <Link
                  className="link-decoration-none"
                  to={`${routes.assessmentEdit.replace(
                    ":assessmentId",
                    id.toString()
                  )}`}
                >
                  Edit Assessment
                </Link>
              </MenuItem>
              <MenuItem onClick={() => toggleAssessmentDueDateDialog()}>
                Change Deadline
              </MenuItem>
            </Menu>

            <IconButton
              color="critical"
              disabled={isRejected}
              onClick={() => toggleRemoveDialog()}
            >
              <Trash />
            </IconButton>
          </>
        )

      default:
        return null
    }
  }

  return (
    <>
      <EditAssessmentDueDate
        afterEdit={data => assessments.update(jobApplicantStepId, data)}
        data={{ dueDate, id, jobApplicantStepId }}
        isEditing={Boolean(dueDate)}
        open={assessmentDueDateDialog}
        toggle={toggleAssessmentDueDateDialog}
      />

      <RemoveDialog
        handleClose={toggleRemoveDialog}
        loading={removeLoading}
        open={openRemoveDialog}
        title="Delete Assessment"
        onRemove={handleRemove}
      >
        <Typography variant="body">
          Are you sure you want to delete this Assessment? This action can't be
          reversed.
        </Typography>
      </RemoveDialog>

      <Section>
        <SectionContent>
          <div className="flex gap-1 flex-wrap items-center">
            <div>
              <div className="flex gap-1 items-center">
                <Typography color="onSurface.800" variant="strong">
                  {title}
                </Typography>

                {getTag(props)}
              </div>
              {dueDate && (
                <div className="mt-0.75">
                  <Typography display="inline" variant="smallBody">
                    Due by{" "}
                  </Typography>
                  <Typography display="inline" variant="strongSmallBody">
                    {format(new Date(dueDate), "dd MMMM yyyy, h:mm a")}
                  </Typography>
                </div>
              )}
              {dateCreated && (
                <Typography
                  className="mt-1.25"
                  color="onSurface.500"
                  variant="smallBody"
                >
                  Posted on {format(new Date(dateCreated), "do MMM yy")}
                </Typography>
              )}
            </div>

            <div className="ml-auto flex gap-1.25 items-center">
              {getAction()}
            </div>
          </div>
        </SectionContent>
      </Section>
    </>
  )
}

export default Card
