import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getNebulaURL = getServiceURL(ServicePrefix.nebula)
const getSolisURL = getServiceURL(ServicePrefix.solis)

export const urls = validateUrls({
  jobs: {
    base: () => getNebulaURL("/v1/jobs/"),
    detail: jobId => getNebulaURL(`/v1/jobs/${jobId}/`),
    overview: () => getSolisURL(`/v1/jobs/overview/`),
  },
  jobApplicant: {
    create: jobId => getNebulaURL(`/v1/jobs/${jobId}/applicants/`),
    update: jobApplicantId =>
      getNebulaURL(`/v1/jobs/applicants/${jobApplicantId}/`),

    listInvited: jobId => `/jobs/${jobId}/applicants/invited/`,
    listApplied: jobId => `/jobs/${jobId}/applicants/applied/`,
    listActive: jobId => `/jobs/${jobId}/applications/`,

    retrieveContactDetails: userId => `/jobs/users/${userId}/`,
  },
  jobApplicantStep: {
    update: jobApplicantStepId =>
      getNebulaURL(`/v1/hiring/steps/${jobApplicantStepId}/`),
    create: jobApplicantId =>
      getNebulaURL(`/v1/hiring/applicants/${jobApplicantId}/steps/`),
  },
  jobOffer: {
    list: () => "/jobs/offers/",
    create: () => getNebulaURL(`/v1/hiring/offers/`),
    retrieve: jobOfferId => getNebulaURL(`/v1/hiring/offers/${jobOfferId}/`),
  },
})
