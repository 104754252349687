import { Button, Typography } from "@suraasa/placebo-ui"
import { Lock } from "iconoir-react"
import React from "react"
import { Link } from "react-router-dom"
import { getAuthInfo } from "utils/auth"
import { routes } from "utils/routes"

import { Paper } from "./index"
import styles from "./Submission.module.css"

const SubmissionPreview = ({ isCompletelyVerified = false, content = "" }) => {
  return (
    <>
      <Paper>
        <div
          className={styles.contentDivBlocked}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </Paper>
      <div className="absolute left-0 flex flex-col items-center w-full bg-white">
        {!getAuthInfo() && (
          <>
            <span className="p-1 mb-2 rounded-full bg-onSurface-200">
              <Lock fontSize={40} />
            </span>
            <Typography variant="largeBody" textAlign="center">
              To view the full submission, please login
            </Typography>
            <Button
              rounded
              className="mt-2"
              component={Link}
              to={routes.login + `?next=${window.location.href}`}
            >
              Login
            </Button>
          </>
        )}

        {!!getAuthInfo() && !isCompletelyVerified && (
          <>
            <span className="p-1 mb-2 rounded-full bg-onSurface-200">
              <Lock fontSize={40} />
            </span>
            <Typography variant="largeBody" textAlign="center">
              Your profile is currently under verification. To get your profile
              verified quickly, please write to us at{" "}
              <a
                href="mailto:partnerships@suraasa.com"
                className="hover:underline text-interactive-400"
              >
                partnerships@suraasa.com
              </a>
              .
            </Typography>
            <Button
              component="a"
              className="mt-2"
              rounded
              href="mailto:partnerships@suraasa.com"
            >
              Contact us
            </Button>
          </>
        )}
      </div>
    </>
  )
}

export default SubmissionPreview
