import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Profile as ProfileType } from "api/resources/profile/types"
import ProfileVerified from "assets/illustrations/profile-verified.svg"
import ProfileUnderVerification from "assets/illustrations/under-verification.svg"
import clsx from "clsx"
import { GlobalContext } from "components/GlobalState"
import Navbar from "components/shared/Navbar"
import { DeleteCircle } from "iconoir-react"
import React, { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { useNavigate, useSearchParams } from "react-router-dom"
import { routes } from "utils/routes"
import toast from "utils/toast"

import Profile from "."

const useStyles = createUseStyles(theme => ({
  unverifiedText: {
    maxWidth: "422px",
  },

  verifiedText: {
    maxWidth: "404px",
  },

  websiteLink: {
    "& > a": {
      color: theme.colors.common.white[500],
    },
  },

  topSection: { background: theme.colors.onSurface[800] },

  image: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px",
    },
  },

  dialogTitle: {
    width: "100vw",

    "& > div > div": {
      padding: 0,
    },

    "& > div > div > div": {
      width: "100%",

      "& > p": {
        width: "100%",
      },
    },
  },

  dialogContent: {
    padding: 0,
  },
}))

function ProfileVerificationStatus() {
  const classes = useStyles()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { isSalesVerified, setIsVerified, isVerified } =
    useContext(GlobalContext)

  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(
    isSalesVerified && Boolean(searchParams.get("openProfile"))
  )
  const [loading, setLoading] = useState(false)
  const [profile, setProfile] = useState<ProfileType | null>(null)

  useEffect(() => {
    const getProfile = async () => {
      const profileRes = await api.profile.retrieve({})
      if (profileRes.isSuccessful) {
        setProfile(profileRes.data)
      }
    }

    getProfile()
  }, [])

  const verifyProfile = async () => {
    setLoading(true)

    const res = await api.school.verify()
    if (res.isSuccessful) {
      toast.success("Profile saved successfully")
      setIsVerified(true)
      navigate(routes.profile, { replace: true })
    } else {
      toast.error(res.errors.message || "Something went wrong")
    }

    setLoading(false)
  }

  if (isVerified) {
    navigate(routes.profile, { replace: true })
    return null
  }

  return (
    <>
      <Navbar gutterBottom={false} hideBackButton hideBanner />

      <Dialog
        animation="slide"
        className={classes.dialogTitle}
        open={isProfileDialogOpen}
        fullScreen
      >
        <DialogTitle>
          <Container>
            <span className="flex items-center justify-between py-1">
              <IconButton
                color="black"
                size="md"
                onClick={() => setIsProfileDialogOpen(false)}
              >
                <DeleteCircle height="30px" width="30px" />
              </IconButton>

              <Button
                loading={loading}
                size="sm"
                variant="filled"
                onClick={verifyProfile}
              >
                Accept Changes to go Live
              </Button>
            </span>
          </Container>
          <Divider className="mb-2" />
          <Container className="mb-2">
            <Typography textAlign="center" variant="button">
              Here is your completed Profile. You can accept changes and go live
              or make edits as deemed necessary.
            </Typography>
          </Container>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Profile bypassVerificationCheck hideNavbar />
        </DialogContent>
      </Dialog>

      <div className={clsx("py-3 mb-4", classes.topSection)}>
        <Container>
          <Typography color="common.white.500" variant="title2">
            {profile?.name || "-"}
          </Typography>

          <div className="flex gap-6 mt-2.5">
            <div className="flex flex-col gap-0.5">
              <Typography color="common.white.500" variant="smallBody">
                Website
              </Typography>
              <Typography
                className={classes.websiteLink}
                color="common.white.500"
                variant="strong"
              >
                {profile?.website ? (
                  <a href={profile.website} rel="noreferrer" target="_blank">
                    {profile.website}
                  </a>
                ) : (
                  "-"
                )}
              </Typography>
            </div>
            <div className="flex flex-col gap-0.5">
              <Typography color="common.white.500" variant="smallBody">
                E-Mail
              </Typography>
              <Typography color="common.white.500" variant="strong">
                {profile?.email || "-"}
              </Typography>
            </div>
          </div>
        </Container>
      </div>

      <Container>
        {isSalesVerified ? (
          <div className="flex flex-col-reverse items-center justify-between pb-5 mt-8 sm:flex-row">
            <div>
              <Typography className={classes.verifiedText} variant="largeBody">
                Your profile is ready to review, you need to review your profile
                before you go live.
              </Typography>
              <Typography
                className={clsx(classes.verifiedText, "mt-1.25")}
                variant="largeBody"
              >
                You can edit your profile to your liking before and after
                accepting changes.
              </Typography>
              <div className="flex justify-center sm:justify-start">
                <Button
                  className="mt-4.5"
                  variant="filled"
                  onClick={() => setIsProfileDialogOpen(true)}
                >
                  Review Changes to go Live
                </Button>
              </div>
            </div>
            <img
              alt="profile verified"
              className={classes.image}
              src={ProfileVerified}
            />
          </div>
        ) : (
          <div className="flex flex-col items-center pb-5">
            <img
              alt="under verification"
              className={clsx("mb-0.25", classes.image)}
              src={ProfileUnderVerification}
            />
            <Typography className="mt-3.5" variant="largeBody">
              We are currently building your profile.
            </Typography>
            <Typography
              className={clsx("mt-2", classes.unverifiedText)}
              textAlign="center"
              variant="largeBody"
            >
              You can modify your profile to your liking once the verification
              is completed.
            </Typography>
          </div>
        )}
      </Container>
    </>
  )
}

export default ProfileVerificationStatus
