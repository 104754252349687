import axios from "api/axios"
import {
  APIResponse,
  Config,
  ConfigWithURLParams,
  NoContentType,
  PaginatedAPIResponse,
} from "api/types"
import {
  formatErrorResponse,
  formatPaginatedResponse,
  formatSuccessResponse,
} from "api/utils"

import {
  ExploreTeacher,
  InterviewDetailsType,
  LiveDemoDetailsType,
  Step,
} from "../jobs/types"
import {
  Award,
  Certification,
  Feedback,
  Interest,
  LanguageResponse,
  Note,
  Profile,
  Project,
  Publication,
  Qualification,
  SkillEvidence,
  SkillEvidencesOverview,
  SubjectResponse,
  TestScore,
  WorkExperienceType,
  WorkLocationInterest,
} from "./types"
import { urls } from "./urls"

export default {
  profile: {
    retrieve: async ({
      urlParams,
    }: ConfigWithURLParams<"username">): Promise<APIResponse<Profile>> => {
      try {
        const res = await axios.get(urls.profile.public(urlParams.username))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    downloadResume: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<any>> => {
      try {
        const res = await axios.get(urls.profile.resume(urlParams.userId), {
          responseType: "blob",
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  videoPortfolio: {
    retrieve: async ({
      urlParams,
    }: ConfigWithURLParams<"username">): Promise<
      APIResponse<{ videoPortfolio: string | null }>
    > => {
      try {
        const res = await axios.get(
          urls.videoPortfolio.list(urlParams.username)
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  listAchievements: async ({
    urlParams,
  }: ConfigWithURLParams<"username">): Promise<
    APIResponse<{
      publications: Publication[]
      awards: Award[]
      testScores: TestScore[]
      projects: Project[]
    }>
  > => {
    try {
      const res = await axios.get(urls.achievements.list(urlParams.username))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  languages: {
    list: async ({
      urlParams,
    }: ConfigWithURLParams<"username">): Promise<
      APIResponse<LanguageResponse[]>
    > => {
      try {
        const res = await axios.get(urls.languages.list(urlParams.username))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  interests: {
    list: async ({
      urlParams,
    }: ConfigWithURLParams<"username">): Promise<
      APIResponse<{
        preferredWorkLocations: WorkLocationInterest[]
        interests: Interest[]
        subjects: SubjectResponse[]
      }>
    > => {
      try {
        const res = await axios.get(urls.interests.list(urlParams.username))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  listAcademics: async ({
    urlParams,
  }: ConfigWithURLParams<"username">): Promise<
    APIResponse<{
      certifications: Certification[]
      qualifications: Qualification[]
    }>
  > => {
    try {
      const res = await axios.get(urls.academics.list(urlParams.username))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  workExperiences: {
    list: async ({
      urlParams,
    }: ConfigWithURLParams<"username">): Promise<
      APIResponse<WorkExperienceType[]>
    > => {
      try {
        const res = await axios.get(
          urls.workExperiences.list(urlParams.username)
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  interview: {
    create: async ({
      data,
    }: Config): Promise<
      APIResponse<
        InterviewDetailsType & { dateCreated: string; jobApplicantStep: number }
      >
    > => {
      try {
        const res = await axios.post(urls.interview.list(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    update: async ({
      urlParams,
      data,
    }: ConfigWithURLParams<"interviewId">): Promise<
      APIResponse<
        InterviewDetailsType & { dateCreated: string; jobApplicantStep: number }
      >
    > => {
      try {
        const res = await axios.put(
          urls.interview.detail(urlParams.interviewId),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    feedback: {
      create: async ({
        urlParams,
        data,
      }: ConfigWithURLParams<"interviewId">): Promise<
        APIResponse<Feedback>
      > => {
        try {
          const res = await axios.post(
            urls.interview.feedback(urlParams.interviewId),
            data
          )
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
      retrieve: async ({
        urlParams,
      }: ConfigWithURLParams<"interviewId">): Promise<
        APIResponse<{ id: number; interview: number; feedback: Feedback }>
      > => {
        try {
          const res = await axios.get(
            urls.interview.feedback(urlParams.interviewId)
          )
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
    },
  },

  liveDemo: {
    feedback: {
      create: async ({
        urlParams,
        data,
      }: ConfigWithURLParams<"liveDemoId">): Promise<APIResponse<Feedback>> => {
        try {
          const res = await axios.post(
            urls.liveDemo.feedback(urlParams.liveDemoId),
            data
          )
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
      retrieve: async ({
        urlParams,
      }: ConfigWithURLParams<"liveDemoId">): Promise<
        APIResponse<{
          feedback: Feedback
          liveDemo: Pick<
            LiveDemoDetailsType,
            "id" | "name" | "scheduledTime" | "isVirtual"
          >
        }>
      > => {
        try {
          const res = await axios.get(
            urls.liveDemo.feedback(urlParams.liveDemoId)
          )
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
    },

    create: async ({
      data,
    }: Config): Promise<
      APIResponse<
        LiveDemoDetailsType & { jobApplicantStep: number; dateCreated: string }
      >
    > => {
      try {
        const res = await axios.post(urls.liveDemo.create(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    update: async ({
      urlParams,
      data,
    }: ConfigWithURLParams<"liveDemoId">): Promise<
      APIResponse<
        LiveDemoDetailsType & { jobApplicantStep: number; dateCreated: string }
      >
    > => {
      try {
        const res = await axios.put(
          urls.liveDemo.detail(urlParams.liveDemoId),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    retrieve: async ({
      urlParams,
    }: ConfigWithURLParams<"liveDemoId">): Promise<
      APIResponse<LiveDemoDetailsType>
    > => {
      try {
        const res = await axios.get(urls.liveDemo.detail(urlParams.liveDemoId))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  jobApplicantStep: {
    list: async ({
      urlParams,
      params,
    }: ConfigWithURLParams<"jobApplicantId">): Promise<APIResponse<Step[]>> => {
      try {
        const res = await axios.get(
          urls.jobApplicantStep.list(urlParams.jobApplicantId),
          { params }
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    delete: async ({
      urlParams,
    }: ConfigWithURLParams<"stepId">): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(
          urls.jobApplicantStep.detail(urlParams.stepId)
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    update: async ({
      data,
      urlParams,
    }: ConfigWithURLParams<"stepId">): Promise<APIResponse<Step>> => {
      try {
        const res = await axios.put(
          urls.jobApplicantStep.detail(urlParams.stepId),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  explore: {
    list: async ({
      params,
    }: Config): Promise<PaginatedAPIResponse<ExploreTeacher[]>> => {
      try {
        const res = await axios.get(urls.explore.list(), { params })
        return formatPaginatedResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  notes: {
    list: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<Note[]>> => {
      try {
        const res = await axios.get(urls.notes.base(urlParams.jobApplicantId))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    create: async ({
      urlParams,
      data,
    }: ConfigWithURLParams): Promise<APIResponse<Note>> => {
      try {
        const res = await axios.post(
          urls.notes.base(urlParams.jobApplicantId),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  skillEvidences: {
    overview: async ({
      urlParams,
    }: ConfigWithURLParams<"username">): Promise<
      APIResponse<SkillEvidencesOverview>
    > => {
      try {
        const res = await axios.get(
          urls.skillEvidences.overview(urlParams.username)
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    list: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<
      APIResponse<{
        skills: SkillEvidence[]
        user: { fullName: string; photo: string | null } | null
      }>
    > => {
      try {
        const res = await axios.get(
          urls.skillEvidences.list(urlParams.username)
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
}
