import {
  Button,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  Select,
  TextField,
  theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import { Assessment, AssessmentQuestion } from "api/resources/assessments/types"
import clsx from "clsx"
import CreateQuestionCard from "components/assessments/CreateQuestionCard"
import Navbar from "components/shared/Navbar"
import { Plus } from "iconoir-react"
import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import { useNavigate, useParams } from "react-router-dom"
import { assessmentDurationOptions } from "utils/constants"
import useArray from "utils/hooks/useArray"
import { routes } from "utils/routes"
import toast from "utils/toast"

import {
  getCorrectOptions,
  getNewOption,
  getNewQuestion,
  getValidOptions,
  validateQuestion,
} from "./helpers"

const useStyles = createUseStyles({
  addButton: {
    borderRadius: "50px",
  },
  buttonsContainer: {
    paddingLeft: "50%",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
    },
  },
})

type FormData = Omit<Assessment, "id">

const Create = () => {
  const classes = useStyles()
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const navigate = useNavigate()
  const { assessmentId } = useParams()

  const [loading, setLoading] = useState(Boolean(assessmentId))
  const [isSaving, setIsSaving] = useState(false)

  const [assessment, setAssessment] = useState<Assessment | null>(null)

  const isActive = Boolean(assessment?.isActive)

  const questions = useArray<AssessmentQuestion>([getNewQuestion()])

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      duration: assessmentDurationOptions[0].value,
      title: "Untitled Assessment",
    },
  })

  const createAssessment = async () => {
    const res = await api.assessments.create({ data: getValues() })
    if (res.isSuccessful) {
      setAssessment(res.data)
      navigate(
        routes.assessmentEdit.replace(":assessmentId", res.data.id.toString()),
        { replace: true }
      )

      return new Promise<Assessment>(resolve => {
        setAssessment(res.data)
        resolve(res.data)
      })
    }
  }

  useEffect(() => {
    const retrieveAssessment = async () => {
      if (!assessmentId) return

      setLoading(true)
      const res = await api.assessments.retrieve({
        urlParams: { assessmentId },
      })
      if (res.isSuccessful) {
        const { title, duration, id, questions: apiQuestions } = res.data
        reset({
          title,
          duration,
        })
        setAssessment({
          id,
          title,
          duration,
          isActive: res.data.isActive,
        })
        if (apiQuestions.length > 0) {
          questions.set(
            apiQuestions.map(q => ({
              ...q,
              options: q.options.length
                ? q.options.map((text, i) => ({
                    ...getNewOption(),
                    isCorrect: q.correctAnswer.includes(i),
                    text,
                  }))
                : [getNewOption()],
              isCreated: true,
              errors: [],
            }))
          )
        } else {
          questions.set([getNewQuestion()])
        }
        setLoading(false)
      }
    }
    retrieveAssessment()
    // This is intentional because /create route redirects to /edit on save. We don't want to refetch data in that case
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const questionsAreValid = () => {
    let isOk = true
    const newQuestions = questions.array.map(q => {
      if (q.isDeleted) return q
      const { isValid, errors: questionErrors } = validateQuestion(q)
      if (!isValid) isOk = false

      return {
        ...q,
        errors: questionErrors,
      }
    })

    questions.set(newQuestions)

    return isOk
  }

  const markAssessmentActive = async () => {
    if (!assessmentId) return

    const res = await api.assessments.update({
      data: {
        ...getValues(),
        isActive: true,
      },
      urlParams: {
        assessmentId,
      },
    })

    if (res.isSuccessful) {
      toast.success("Assessment is live!")
      navigate(routes.assessments)
    } else {
      toast.error("Please make sure there are no errors")
    }
  }

  const bulkUpdateQuestions = async () => {
    if (!assessmentId) return

    const deleteAssessmentQuestions: number[] = []
    const apiData = {
      assessmentQuestions: questions.array
        .filter(q => {
          if (q.isCreated && q.isDeleted)
            deleteAssessmentQuestions.push(q.id as number)

          return !q.isDeleted
        })
        .map(q => {
          const validOptions = getValidOptions(q.options)

          return {
            pk: q.isCreated ? q.id : undefined,
            text: q.text,
            options: validOptions.map(({ text }) => text),
            correctAnswer: getCorrectOptions(validOptions),
          }
        }),
      deleteAssessmentQuestions,
    }

    const res = await api.assessments.bulkUpdate({
      data: apiData,
      urlParams: {
        assessmentId,
      },
    })

    if (res.isSuccessful) {
      toast.success("Assessment is live!")
      navigate(routes.assessments)
    } else {
      toast.error("Please make sure there are no errors")
    }
  }

  const onSubmit = handleSubmit(async () => {
    if (isSaving) return

    if (!questionsAreValid()) return

    if (!isActive) {
      await markAssessmentActive()
    } else {
      await bulkUpdateQuestions()
    }
  })

  const handleRemove = async (question: AssessmentQuestion) => {
    if (!isActive && question.isCreated) {
      const res = await api.assessments.questions.delete({
        urlParams: {
          questionId: question.id,
        },
      })
      if (res.isSuccessful) {
        toast.success("Question removed")
        questions.removeByKey(question.id)
      }
      return
    }

    questions.updateByKey(question.id, { ...question, isDeleted: true })
  }

  if (loading)
    return (
      <>
        <Navbar />
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      </>
    )

  return (
    <>
      <Navbar />
      <div className="xl:grid grid-cols-12 justify-center mt-4">
        <div className="col-span-10 col-start-2">
          <Container className="mb-3">
            <form
              className="flex flex-col gap-2 sm:flex-row sm:gap-4.5"
              onSubmit={onSubmit}
            >
              <TextField
                disabled={isActive}
                label="Assessment name"
                placeholder="Enter Assessment Name"
                type="text"
                fullWidth
                {...register("title")}
              />
              <Controller
                control={control}
                name="duration"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    error={Boolean(errors.duration)}
                    helperText={errors.duration?.message}
                    inputLabelProps={{
                      required: true,
                    }}
                    isDisabled={isActive}
                    label="Duration (Minutes)"
                    name="duration"
                    options={assessmentDurationOptions}
                    placeholder="Select Duration"
                    value={
                      value
                        ? assessmentDurationOptions.find(c => c.value === value)
                        : null
                    }
                    fullWidth
                    mountOnBody
                    onBlur={onBlur}
                    onChange={newValue => onChange(newValue?.value)}
                  />
                )}
                rules={{
                  required: { value: true, message: "Required" },
                }}
              />
            </form>
            <div className="flex items-center pt-3.5 pb-1.5">
              <Typography variant="strong">Questions</Typography>
              <div className="pl-2 flex-grow">
                <Divider weight="light" />
              </div>
            </div>
          </Container>

          <Container className="mb-3" fullWidth={xsDown}>
            {questions.array
              .filter(({ isDeleted }) => !isDeleted)
              .map((question, index) => (
                <CreateQuestionCard
                  assessment={{
                    create: () => createAssessment(),
                    created: Boolean(assessment?.id),
                    id: assessment?.id,
                    isActive: Boolean(assessment?.isActive),
                  }}
                  key={question.id}
                  question={question}
                  questionNumber={index + 1}
                  setIsSaving={setIsSaving}
                  xsDown={xsDown}
                  onRemove={() => handleRemove(question)}
                  onUpdate={value =>
                    questions.updateByKey(question.id, value, "id")
                  }
                />
              ))}
            <div
              className={clsx(
                "flex items-center mt-3 justify-center flex-col gap-3 sm:gap-0 sm:flex-row sm:justify-between",
                classes.buttonsContainer
              )}
            >
              <IconButton
                className={classes.addButton}
                color="primary"
                size="lg"
                variant="filled"
                onClick={() => {
                  questions.push(getNewQuestion())
                }}
              >
                <Plus height={32} width={32} />
              </IconButton>

              <Button
                className="mr-2 sm:mr-0"
                color="primary"
                disabled={isSaving}
                loading={isSubmitting}
                onClick={() => onSubmit()}
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {isSaving
                  ? "Saving questions..."
                  : isActive
                  ? "Save Changes"
                  : "Publish Assessment"}
              </Button>
            </div>
          </Container>
        </div>
      </div>
    </>
  )
}

export default Create
