import { Avatar, Button, Chip, Tag, Typography } from "@suraasa/placebo-ui"
import { AppliedUser, JobUser } from "api/resources/jobs/types"
import { ReactComponent as VerifiedBadge } from "assets/home/verifiedBadge.svg"
import clsx from "clsx"
import React, { useState } from "react"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"
import { formatWorkDuration, pluralize } from "utils/helpers"
import { routes } from "utils/routes"

import ContactDetailsDialog from "./ContactDetailsDialog"

const useStyles = createUseStyles(theme => ({
  root: {
    background: "white",
    border: `1px solid ${theme.colors.surface[200]}`,
    borderRadius: "4px",
  },
  userName: {
    "&:hover": {
      textDecorationColor: "black",
    },
  },
  imageContainer: {
    position: "relative",
    height: "max-content",
    width: "48px",
  },

  imageBadge: {
    position: "absolute",
    bottom: "0",
    right: "-3px",
  },
  separator: {
    color: theme.colors.primary[300],
  },
  userWorkExperiences: {
    height: "100%",
  },
}))

type Props = {
  applications?: AppliedUser["applications"]
  user: JobUser
  canViewContactDetails?: boolean
  action?: JSX.Element
  verifiedSkillEvidences?: number
  className?: string
  jobApplicantId?: number
}

const UserDetailsCard = ({
  applications,
  canViewContactDetails,
  verifiedSkillEvidences,
  user,
  action,
  className,
  jobApplicantId,
}: Props) => {
  const [contactDialogOpen, setContactDialogOpen] = useState(false)

  const classes = useStyles()

  return (
    <div
      className={clsx(
        "flex flex-col gap-1 sm:flex-row sm:gap-2 px-2 py-2 mb-2",
        classes.root,
        className
      )}
    >
      <div className="flex gap-1.5">
        <div className={classes.imageContainer}>
          <Avatar
            name={user.fullName}
            size="md"
            src={user.profile.picture ?? undefined}
          />
          {user.profile.isVerified && (
            <VerifiedBadge className={classes.imageBadge} />
          )}
        </div>

        <div className="flex flex-col gap-0.5 sm:hidden">
          <Button
            className={classes.userName}
            color="black"
            component={Link}
            nudge="left"
            to={
              jobApplicantId
                ? `${routes.teacherProfile.replace(
                    ":username",
                    user.username
                  )}?jobApplicantId=${jobApplicantId}`
                : routes.teacherProfile.replace(":username", user.username)
            }
            variant="link"
          >
            <Typography display="inline" variant="strong">
              {user.fullName}
            </Typography>
          </Button>
          {verifiedSkillEvidences !== undefined &&
            verifiedSkillEvidences > 0 && (
              <Tag
                className="inline"
                color="success"
                label={pluralize("Verified skill", verifiedSkillEvidences)}
              />
            )}
        </div>
      </div>

      <div className="flex-grow">
        <div className="flex flex-col gap-1 sm:gap-0 sm:flex-row justify-between mb-1 sm:mb-2">
          <div className="flex-grow">
            <div className="hidden sm:block">
              <Button
                className={classes.userName}
                color="black"
                component={Link}
                nudge="left"
                to={
                  jobApplicantId
                    ? `${routes.teacherProfile.replace(
                        ":username",
                        user.username
                      )}?jobApplicantId=${jobApplicantId}`
                    : routes.teacherProfile.replace(":username", user.username)
                }
                variant="link"
              >
                <Typography display="inline" variant="strong">
                  {user.fullName}
                </Typography>
              </Button>
              {verifiedSkillEvidences !== undefined &&
                verifiedSkillEvidences > 0 && (
                  <Tag
                    className="inline ml-1"
                    color="success"
                    label={pluralize("Verified skill", verifiedSkillEvidences)}
                  />
                )}
            </div>
            <div className="flex flex-col sm:flex-row sm:gap-0.5 sm:items-center flex-wrap">
              {Boolean(
                user.workExperiences.subjectInterests &&
                  user.workExperiences.subjectInterests.length > 0
              ) && (
                <Typography className="mt-0.5" variant="smallBody">
                  Interested in teaching{" "}
                  <b>{user.workExperiences.subjectInterests?.join(", ")}</b>
                </Typography>
              )}

              {Boolean(
                user.workExperiences.subjectInterests &&
                  user.workExperiences.subjectInterests.length > 0 &&
                  user.workExperiences.daysOfExperience
              ) && (
                <Typography
                  className="hidden md:block"
                  color="primary.300"
                  display="initial"
                >
                  {" • "}
                </Typography>
              )}

              {user.workExperiences.daysOfExperience ? (
                <Typography className="mt-0.5" variant="smallBody">
                  <b>
                    {formatWorkDuration(user.workExperiences.daysOfExperience)}
                  </b>{" "}
                  of experience
                </Typography>
              ) : null}
            </div>
            {user.workExperiences.subjects.length > 0 && (
              <Typography className="mt-0.5" variant="smallBody">
                Teaches <b>{user.workExperiences.subjects.join(", ")}</b>
              </Typography>
            )}

            {user.profile.state?.name && user.profile.country?.name && (
              <Typography className="mt-0.5" variant="smallBody">
                Lives in{" "}
                <b>
                  {user.profile.state.name}, {user.profile.country.name}
                </b>
              </Typography>
            )}

            {applications !== undefined && applications > 0 && (
              <Typography className="mt-0.5" variant="smallBody">
                Interviewing with <b>{applications}</b> other{" "}
                {pluralize("school", applications, { skipCount: true })}
              </Typography>
            )}
          </div>

          {user.workExperiences.curricula.length > 0 && (
            <div
              className={clsx(
                classes.userWorkExperiences,
                "flex gap-0.5 flex-wrap"
              )}
            >
              {user.workExperiences.curricula.map((board, index) => (
                <Chip
                  color="secondary"
                  key={index}
                  label={board}
                  size="sm"
                  variant="outlined"
                />
              ))}
            </div>
          )}
        </div>

        <div className="flex flex-col sm:flex-row sm:items-center justify-between">
          <Button
            className="-ml-1"
            variant="text"
            onClick={() => {
              setContactDialogOpen(true)
            }}
          >
            View Contact Details
          </Button>
          <br />
          <div className="flex items-center justify-end">{action}</div>
        </div>
      </div>

      {contactDialogOpen && (
        <ContactDetailsDialog
          canViewContactDetails={canViewContactDetails}
          handleClose={() => setContactDialogOpen(false)}
          open={contactDialogOpen}
          teacher={{
            name: user.fullName,
            uuid: user.uuid,
          }}
        />
      )}
    </div>
  )
}

export default UserDetailsCard
