import { Checkbox, theme, Typography } from "@suraasa/placebo-ui"
import { BackendQuestion } from "api/resources/assessments/types"
import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
  ReadOnlyQuestionCard: {
    background: "white",
    boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      borderRadius: 0,
    },
  },

  optionContainer: {
    borderRadius: theme.spacing(0.5),
    width: "100%",
  },

  questionText: {
    wordBreak: "break-word",
  },
})

function ReadOnlyQuestionCard({
  question,
  questionNumber,
}: {
  question: BackendQuestion
  questionNumber: number
}) {
  const classes = useStyles()

  return (
    <div className={clsx(classes.ReadOnlyQuestionCard, "px-2 py-3 mb-2")}>
      <Typography className="mb-1" variant="strongSmallBody">
        Question {questionNumber}
      </Typography>
      <Typography className={classes.questionText} variant="body">
        {question.text}
      </Typography>

      <div className="mt-3.5 flex flex-col gap-1">
        {question.options.map((option, index) => (
          <div
            className={clsx(
              classes.optionContainer,
              "flex items-center py-0.5 px-0.5"
            )}
            key={index}
          >
            <Checkbox
              checked={question.correctAnswer.includes(index)}
              color="success"
              readOnly
            />
            <Typography className="ml-1.25" variant="body">
              {option}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ReadOnlyQuestionCard
