import {
  Button,
  CircularProgress,
  Container,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Job } from "api/resources/jobs/types"
import clsx from "clsx"
import { GlobalContext } from "components/GlobalState"
import ActiveApplicationsTab from "components/jobs/ActiveApplicationsTab"
import AppliedTab from "components/jobs/AppliedTab"
import InvitedTab from "components/jobs/InvitedTab"
import JobOffersTab from "components/jobs/JobOffersTab"
import JobOpeningDetailsDialog from "components/jobs/JobOpeningDetailsDialog"
import ReopenJobConfirmationDialog from "components/jobs/ReopenJobConfirmationDialog"
import ShareJobButton from "components/jobs/ShareJobButton"
import Tabs from "components/jobs/Tabs"
import BackButton from "components/shared/BackButton"
import Navbar from "components/shared/Navbar"
import { OpenInWindow, ShareAndroid } from "iconoir-react"
import React, { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { Link, useParams } from "react-router-dom"
import { JobStatus } from "utils/constants"
import useTabs, { Tabs as TabsType } from "utils/hooks/useTabs"
import useToggle from "utils/hooks/useToggle"
import toast from "utils/toast"

const useStyles = createUseStyles(theme => ({
  root: {
    background: "white",
    borderBottom: `1px solid ${theme.colors.surface[200]}`,
  },
  inviteButton: {
    borderRadius: "4px 0px 0px 4px",
    marginRight: "2px",
  },
  splitButton: {
    "& a": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& button": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
}))

const activeApplication: TabsType = [
  {
    name: "Active Applications",
    content: ActiveApplicationsTab,
  },
  {
    name: "Applicants",
    content: AppliedTab,
  },
  {
    name: "Invited",
    content: InvitedTab,
  },
  {
    name: "Job Offers Sent",
    content: JobOffersTab,
  },
]

const previousApplication: TabsType = [
  {
    name: "Previous Applications",
    content: ActiveApplicationsTab,
  },
  {
    name: "Applicants",
    content: AppliedTab,
  },
  {
    name: "Invitations",
    content: InvitedTab,
  },
  {
    name: "Job Offers Sent",
    content: JobOffersTab,
  },
]

const Details = () => {
  const classes = useStyles()

  const { profile } = useContext(GlobalContext)

  const { jobId } = useParams()

  const currentTabType = (job: Job) =>
    job.status === JobStatus.CLOSED ? previousApplication : activeApplication

  const [jobOpeningDetailsDialog, toggleJobOpeningDetailsDialog] =
    useToggle(false)
  const [job, setJob] = useState<Job | null>(null)
  const [tabDetails, setTabDetails] = useState<TabsType>(activeApplication)
  const [loading, setLoading] = useState(false)
  const [isReopeningJob, setIsReopeningJob] = useState(false)

  const [activeTab, setActiveTab] = useTabs({
    tabs: tabDetails,
    initialTab: tabDetails[0].name,
  })
  const toggleReopenJobDialog = () => {
    setIsReopeningJob(false)
  }

  const onSubmitReopenJob = async () => {
    if (jobId) {
      const res = await api.jobs.update({
        urlParams: { jobId },
        data: {
          status: 2,
        },
      })
      if (res.isSuccessful) {
        toast.success("Job Reopen successfully")
        setIsReopeningJob(false)
        window.location.reload()
      } else {
        toast.error(res.errors.message)
      }
    }
  }

  const actionButton = (job: Job) => {
    if (job.status === JobStatus.CLOSED) {
      return (
        <Button
          onClick={() => {
            setIsReopeningJob(true)
          }}
        >
          Reopen Job
        </Button>
      )
    }
    return (
      <div className={clsx(classes.splitButton, "flex gap-0.25")}>
        <Button
          component={Link}
          to={`/explore/?jobId=${job.id}&jobPosition=${job.position}`}
        >
          Invite Teachers
        </Button>
        <ShareJobButton
          activator={handleOpen => (
            <IconButton size="md" variant="filled" onClick={handleOpen}>
              <ShareAndroid />
            </IconButton>
          )}
          jobSlug={job.slug}
          position={job.position}
          schoolSlug={profile?.slug || ""}
          subject={job.subject.name}
        />
      </div>
    )
  }

  useEffect(() => {
    const retrieveJob = async () => {
      if (!jobId) return
      setLoading(true)
      const res = await api.jobs.retrieve({
        urlParams: {
          jobId,
        },
      })

      if (res.isSuccessful) {
        setJob(res.data)
        setTabDetails(currentTabType(res.data))
        setLoading(false)
      } else {
        setLoading(false)
      }
    }

    retrieveJob()
  }, [jobId])

  if (!jobId || !job) return null

  return (
    <>
      <JobOpeningDetailsDialog
        data={job}
        open={jobOpeningDetailsDialog}
        toggle={toggleJobOpeningDetailsDialog}
      />
      <Navbar gutterBottom={false} hideBackButton />

      <div className={clsx(classes.root, "pb-1.5")}>
        <Container>
          <BackButton className="my-2" nudge="left" />
          {loading ? (
            <div className="flex justify-center mt-5">
              <CircularProgress />
            </div>
          ) : (
            <div>
              <div className="flex justify-between mt-2 mb-4 items-center">
                <div>
                  <div className="flex gap-1">
                    <Typography variant="title2">{job.position}</Typography>
                    <IconButton
                      color="black"
                      onClick={() => toggleJobOpeningDetailsDialog()}
                    >
                      <OpenInWindow />
                    </IconButton>
                  </div>
                  <Typography color="onSurface.500" variant="smallBody">
                    {job.subject.name}
                  </Typography>
                </div>
                {job && actionButton(job)}
              </div>
              <div className="flex gap-2 justify-between">
                <Tabs
                  activeTab={activeTab}
                  tabs={tabDetails}
                  onChange={setActiveTab}
                />
              </div>
            </div>
          )}
        </Container>
      </div>
      <Container className="mb-3">
        <activeTab.content
          isClosed={job.status === JobStatus.CLOSED}
          job={job}
        />
      </Container>
      <ReopenJobConfirmationDialog
        open={isReopeningJob}
        position={job.position}
        subjectName={job.subject.name}
        toggle={toggleReopenJobDialog}
        onSubmit={onSubmitReopenJob}
      />
    </>
  )
}
export default Details
