import {
  Country,
  Currency,
  Curriculum,
  State,
  Subject,
} from "api/resources/global/types"
import { JobStatus } from "utils/constants"

export type Overview = {
  subjects: number
}

export type School = {
  id: number
  name: string
  slug: string
  logo: string | null
  country: Country
  state: State
}

export type JobOverview = Pick<Job, "id" | "slug" | "position" | "subject">

export type SchoolOverview = Pick<
  School,
  "id" | "logo" | "name" | "country" | "state" | "slug"
>

export type JobOfferType = {
  id: number
  status: JobOfferStatus
  school: SchoolOverview
  job: JobOverview
  position: string
  dateCreated: string
  user: {
    email: string
    fullName: string
    profile: {
      isVerified: boolean
      phoneNumber: {
        code: number
        number: number
      }
      picture: string
    }
    username: string
    uuid: string
  }
  isViewed: boolean
  subject: Subject
  currency: Currency
  salaryOffered: number
  maximumSalaryOffered: number | null
}

export type Job = {
  id: number
  position: string
  slug: string
  subject: Subject
  currency: Currency
  salaryOffered: number
  maximumSalaryOffered: number | null
  datePublished?: string
  dateReopened?: string | null
  dateClosed?: string | null
  status: JobStatus
  description: string | null
  curriculumBoard?: {
    curriculumId: string
  }[]
  school: {
    id: number
    name: string
    slug: string
    logo: string | null
    country: Country | null
    state: State | null
    curriculumBoard: Curriculum
    branch: string | null
    city: string | null
  }
  numberOfApplicants?: number | null
  jobApplicant?: JobApplicant
}

export enum ApplicationStatus {
  PENDING = 1,
  ACCEPTED = 2,
  REJECTED = 3,
}
export enum InvitationStatus {
  NOT_SENT = 1,
  PENDING,
  ACCEPTED,
  REJECTED,
  REVOKED,
  CANCELLED,
}

export type JobApplicant = {
  id: number
  applicationStatus: ApplicationStatus | null
  invitationStatus: InvitationStatus | null
  invitationSentOn: string // ISODate
  applicationSentOn: string // ISODate
  status: JobApplicantStatus
  attemptNumber: number
  rejectionReason: string | null
  isViewed: boolean
  job: number
  user: string // userID
  jobOffer?: {
    id: number
    status: JobOfferStatus
  }
}

export type JobUser = {
  uuid: string
  fullName: string
  username: string
  workExperiences: {
    subjectInterests?: string[]
    daysOfExperience: null | number
    curricula: string[]
    subjects: string[]
  }
  profile: {
    picture: null | string
    isVerified: boolean
    country: Country | null
    state: State | null
  }
}
export interface ContactDetails extends JobUser {
  email: string
  profile: JobUser["profile"] & {
    phoneNumber: {
      code: number
      number: number
    } | null
  }
}

export type AppliedUser = {
  id: number
  applications: number
  dateApplied: string // ISODate
  applicationStatus: ApplicationStatus | null
  invitationStatus: InvitationStatus | null
  user: JobUser
}

export type AssessmentDetailsType = {
  id: number
  title: string
  duration: number
  dueDate: string | null
  dateCreated: string
  scheduledTime: string
  totalNumberOfQuestions: number
  feedback: any | null
}

export type InterviewDetailsType = {
  scheduledTime: string
  id: number
  duration: number
  name: string
  isTelephonic: boolean
  url: string | null
  videoMeeting: {
    meetingUrl: string
    endTime: string
    startTime: string
  } | null
  feedback: any | null
}

export type LiveDemoDetailsType = {
  id: number
  name: string
  scheduledTime: string
  instructions: string
  isVirtual: boolean
  duration: number
  topic: string | null
  address: string | null
  meetingUrl: string | null
  feedback: any | null
}

export enum JobApplicantStatus {
  PENDING = 1,
  IN_PROGRESS = 2,
  REJECTED = 3,
  JOB_OFFER_SENT = 4,
  JOB_OFFER_ACCEPTED = 5,
  JOB_OFFER_REJECTED = 6,
  WITHDRAWN = 7,
  CANCELLED = 8,
}

export enum JobApplicantStepStatus {
  NOT_STARTED = 1,
  IN_PROGRESS,
  COMPLETED,
  SKIPPED,
  CANCEL,
}

export enum StepType {
  ASSESSMENT = "assessment",
  INTERVIEW = "interview",
  LIVE_DEMO = "livedemo",
}

export type BaseStep = {
  id: number
  stepId: number
  status: JobApplicantStepStatus
  dueDate: string

  dateCreated: string

  step: InterviewDetailsType | LiveDemoDetailsType | AssessmentDetailsType
  stepType: StepType
}

export interface AssessmentDetailsStep extends BaseStep {
  stepType: StepType.ASSESSMENT
  step: AssessmentDetailsType
}
export interface InterviewDetailsStep extends BaseStep {
  stepType: StepType.INTERVIEW
  step: InterviewDetailsType
}
export interface LiveDemoDetailsStep extends BaseStep {
  stepType: StepType.LIVE_DEMO
  step: LiveDemoDetailsType
}

export type Step =
  | AssessmentDetailsStep
  | LiveDemoDetailsStep
  | InterviewDetailsStep

export type CurrentStep = Step & {
  score: number | null
  startTime: string | null // ISODate
  endTime: string | null // ISODate
}

export type ActiveUser = {
  id: number
  currentStep: CurrentStep | null
  jobOffer: Pick<JobOfferType, "id" | "status"> | null
  status: JobApplicantStatus
  user: {
    fullName: string
    username: string
    uuid: string
    email: string
    profile: {
      picture: null | string
      isVerified: boolean
      phoneNumber: {
        code: number
        number: number
      } | null
    }
  }
}

export type InvitedUser = {
  id: number
  applications: number
  dateApplied: string | null // ISODate
  invitationStatus: InvitationStatus | null
  invitationExpiresAt: string
  user: {
    username: string
    fullName: string

    uuid: string
    workExperiences: {
      daysOfExperience: null | number
      noOfJobs: number
      salaryDrawn: null | number
      currency: Currency | null
    }
    profile: {
      picture: null | string
      isVerified: boolean
    }
  }
}

export type ExploreTeacher = {
  interviewingSchools: number
  canViewContactDetails?: boolean
  jobApplicant?: null | {
    id: number
    applicationStatus: ApplicationStatus | null
    invitationStatus: InvitationStatus | null
  } // this key is only sent when job_id is passed in query params
  user: {
    uuid: string
    username: string
    workExperiences: {
      subjectInterests: string[]
      subjects: string[]
      curricula: string[]
      daysOfExperience: number | null
    }
    profile: {
      picture: null
      isVerified: true
      country: Country | null
      state: State | null
    }
    firstName: string
    fullName: string
  }
  verifiedSkillEvidences: number
}

export enum JobOfferStatus {
  SENT = 1,
  ACCEPTED,
  REJECTED,
}

export type JobOffer = {
  description: string
  finalSalary: number
  currency: Currency
  joiningDate: string
  offerLetter: string
  offerLetterName: string
  status: JobOfferStatus
  job: Job
  user: string
}
