import clsx from "clsx"
import React from "react"

type Props = {
  className?: string
}

const ItemList: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
}) => {
  React.Children.forEach(children, child => {
    // @ts-expect-error displayName exists
    const displayName = child?.type?.displayName

    if (displayName !== "ItemRow")
      throw new Error(
        "Invalid child supplied to `ItemList`. Children should be of type `ItemRow`."
      )
  })

  return (
    <div
      className={clsx(
        className,
        "bg-white py-1 border border-surface-200 rounded-xl"
      )}
    >
      {React.Children.map(children, (child, index) => {
        const isLastElement = Array.isArray(children)
          ? children.length - 1 === index
          : true

        // @ts-expect-error can't type this
        return React.cloneElement(child, {
          padding: 0,
          border: 0,
          // @ts-expect-error can't type this
          className: clsx(child.props.className, "px-2 py-1.5 rounded-b-none", {
            "border-b border-surface-200": !isLastElement,
          }),
        })
      })}
    </div>
  )
}

export default ItemList
