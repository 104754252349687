import { colors, Tooltip, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { NavArrowRight } from "iconoir-react"
import React from "react"
import { createUseStyles } from "react-jss"
import { useNavigate } from "react-router-dom"
import { ProfileNavTabs } from "utils/constants"
import { routes } from "utils/routes"

import MobileMenu from "./MobileMenu"

const useStyles = createUseStyles(theme => ({
  selectMenu: {
    boxShadow: `-2px 0 ${theme.colors.primary[500]}`,
    [theme.breakpoints.down("xs")]: {
      boxShadow: "none",
    },
  },

  menuItem: {
    width: "100%",
    border: `1px solid ${colors.surface[200]}`,
    borderBottom: "none",
  },
  lastItem: {
    borderBottom: `1px solid ${colors.surface[200]}`,
  },
  spacer: {
    height: theme.spacing(2),
    borderTop: `1px solid ${colors.surface[200]}`,
    background: colors.surface[50],
  },
}))

const ProfileNavigation = ({
  selectedItem,
  setSelectedItem,
  onClick,
  disableProfile,
}: {
  disableProfile?: boolean
  selectedItem: ProfileNavTabs
  onClick: () => void
  setSelectedItem: React.Dispatch<React.SetStateAction<ProfileNavTabs>>
}) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const handleTabChange = (tab: ProfileNavTabs) => {
    navigate(
      `${routes.editProfile}?tab=${Object.keys(ProfileNavTabs).find(
        key => ProfileNavTabs[key as keyof typeof ProfileNavTabs] === tab
      )}`,
      { replace: true }
    )
    setSelectedItem(tab)
    onClick()
  }

  return (
    <>
      <MobileMenu
        title="Edit Details"
        onBack={() => navigate(routes.profile)}
      />
      {Object.values(ProfileNavTabs).map((tabName, index) => (
        <React.Fragment key={index}>
          {index === 2 && <div className={classes.spacer} />}

          <Tooltip
            disabled={
              tabName !== ProfileNavTabs.publicDetails || !disableProfile
            }
            title="Your profile needs to be verified before you can edit public details."
          >
            <span>
              <button
                className={clsx(
                  classes.menuItem,
                  "sm:pr-0 pr-3.5 py-2 pl-2 flex items-center justify-between",
                  {
                    [classes.selectMenu]: selectedItem === tabName,
                  },
                  {
                    [classes.lastItem]:
                      index === Object.values(ProfileNavTabs).length - 1,
                  }
                )}
                disabled={
                  disableProfile && tabName === ProfileNavTabs.publicDetails
                }
                onClick={() => handleTabChange(tabName)}
              >
                <Typography color="common.black.700" variant="body">
                  {tabName}
                </Typography>
                <NavArrowRight className="sm:hidden" />
              </button>
            </span>
          </Tooltip>
        </React.Fragment>
      ))}
    </>
  )
}

export default ProfileNavigation
