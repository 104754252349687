import { Button, Container, TextField, Typography } from "@suraasa/placebo-ui"
import api from "api"
import Navbar from "components/shared/Navbar"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { getAuthInfo } from "utils/auth"
import { handleErrors } from "utils/helpers"
import toast from "utils/toast"
import isURL from "validator/es/lib/isURL"

type FormData = {
  fullName?: string
  email?: string
  schoolName?: string
  schoolWebsite?: string
  subject: string
  description: string
}

const PLATFORM = 2 // School

function Help() {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormData>()

  const auth = getAuthInfo()
  const [loading, setLoading] = useState(false)

  const onSubmit = handleSubmit(async formData => {
    setLoading(true)
    const { email, subject, fullName, schoolName, description, schoolWebsite } =
      formData
    const res = await api.help.create({
      data: auth
        ? {
            subject,
            description,
            category: null,
            platform: PLATFORM,
            subCategory: null,
            email: getAuthInfo()?.user.email,
          }
        : {
            fullName,
            email,
            subject,
            description,
            category: null,
            subCategory: null,
            platform: PLATFORM,
            additionalDetails: {
              schoolName,
              schoolWebsite,
            },
          },
    })
    if (res.isSuccessful) {
      toast.success("Submitted successfully")
      reset({})
    } else {
      handleErrors(setError, res.errors)
    }
    setLoading(false)
  })

  return (
    <>
      <Navbar gutterBottom={false} />

      <Container className="pb-10">
        <Typography variant="title2">Need Help?</Typography>
        <Typography className="mt-1.5" variant="body">
          We understand not everything in this world is perfect. Please let us
          know, if anything is not working well.
        </Typography>
        <form className="flex flex-col gap-3 mt-5" onSubmit={onSubmit}>
          {!auth && (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-13 gap-y-3">
              <TextField
                error={Boolean(errors.fullName)}
                helperText={errors.fullName?.message}
                label="Your full name"
                placeholder="Enter your full name"
                fullWidth
                {...register("fullName", {
                  required: { value: true, message: "Required" },
                })}
              />
              <TextField
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
                label="Your email"
                placeholder="Enter your official e-mail address"
                fullWidth
                {...register("email", {
                  required: { value: true, message: "Required" },
                })}
                type="email"
              />

              <TextField
                error={Boolean(errors.schoolName)}
                helperText={errors.schoolName?.message}
                label="School name"
                placeholder="Enter your school name"
                fullWidth
                {...register("schoolName", {
                  required: { value: true, message: "Required" },
                })}
              />
              <TextField
                error={Boolean(errors.schoolWebsite)}
                helperText={errors.schoolWebsite?.message}
                label="school website"
                placeholder="Enter school website"
                fullWidth
                {...register("schoolWebsite", {
                  required: { value: true, message: "Required" },
                  validate: value =>
                    isURL(value || "", {
                      protocols: ["https", "http"],
                    }) || "Invalid URL",
                })}
              />
            </div>
          )}

          <TextField
            error={Boolean(errors.subject)}
            helperText={errors.subject?.message}
            label="Subject"
            placeholder="Enter subject"
            fullWidth
            {...register("subject", {
              required: { value: true, message: "Required" },
            })}
          />

          {/* @ts-expect-error placebo-issue */}
          <TextField
            className="mb-1.5"
            error={Boolean(errors.description)}
            helperText={errors.description?.message}
            label="Description"
            rows={4}
            fullWidth
            multiLine
            {...register("description", {
              required: { value: true, message: "Required" },
            })}
            placeholder="How can we help?"
          />
          <Button loading={loading} onClick={onSubmit}>
            Submit
          </Button>
        </form>
      </Container>
    </>
  )
}

export default Help
