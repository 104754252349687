import { Button, Divider, Typography } from "@suraasa/placebo-ui"
import { Evidence, EvidenceType } from "api/resources/teacher/types"
import twitterVerifiedBadge from "assets/twitter-verified-badge.png"
import { format } from "date-fns"
import React, { useContext } from "react"
import ProfileContext from "views/teacher/profileContext"

const getEvidenceLink = (evidence: Evidence) => {
  if (evidence.evidenceType === EvidenceType.File) return evidence.file

  return evidence.url
}

const Certifications = () => {
  const {
    academics: {
      certifications: { data },
    },
  } = useContext(ProfileContext)

  return (
    <>
      {data.map((certification, index) => (
        <div className="pt-2 ml-2" key={certification.id || index}>
          <div className="pb-2">
            <div className="flex items-center gap-0.5">
              <Typography variant="strongSmallBody">
                {certification.name}
              </Typography>

              {certification.isVerified && (
                <img
                  alt="✅"
                  height="24px"
                  src={twitterVerifiedBadge}
                  width="24px"
                />
              )}
            </div>

            <Typography
              className="mb-0.25"
              color="onSurface.600"
              variant="smallBody"
            >
              {certification.organisationName}
            </Typography>

            {certification.completionDate && (
              <Typography
                className="mb-0.5"
                color="onSurface.600"
                variant="smallBody"
              >
                {format(new Date(certification.completionDate), "MMMM yyyy")}
              </Typography>
            )}

            {certification.evidences && certification.evidences.length > 0 && (
              <Button
                component="a"
                href={getEvidenceLink(certification.evidences[0])}
                nudge="left"
                target="_blank"
                variant="link"
              >
                View Certificate
              </Button>
            )}
          </div>

          {index < data.length - 1 && (
            <Divider color="onSurface.200" weight="light" />
          )}
        </div>
      ))}
    </>
  )
}

export default Certifications
