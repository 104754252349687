import axios from "api/axios"
import { APIResponse, Config, NoContentType } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { urls } from "./urls"

export default {
  create: async ({ data }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(urls.help(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
