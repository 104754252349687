import { Button, Tag, Tooltip, Typography } from "@suraasa/placebo-ui"
import api from "api"
import { Gender, Profile } from "api/resources/teacher/types"
import { ReactComponent as LookingForJobsRibbon } from "assets/looking-for-jobs.svg"
import Female from "assets/profileFemale.png"
import Male from "assets/profileMale.png"
import Neutral from "assets/profileNeutral.png"
import Section from "components/Section"
import VideoPortfolioDialog from "components/VideoPortfolio/VideoPortfolioDialog"
import {
  Mail,
  Page,
  Play,
  SmartphoneDevice,
  TwitterVerifiedBadge,
} from "iconoir-react"
import { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { getAuthInfo } from "utils/auth"
import { saveBlobAsFile } from "utils/helpers"
import toast from "utils/toast"

const AVATAR_SIZE = { lg: 142, sm: 91 }

const useStyles = createUseStyles(theme => ({
  root: {
    background: "#fff",
    padding: theme.spacing(0, 3),
    paddingBottom: theme.spacing(1),
  },

  shareButton: {
    marginLeft: "auto",
  },

  displayPictureContainer: {
    position: "relative",

    "& > *": {
      borderRadius: "50%",
      height: AVATAR_SIZE.lg,
      width: AVATAR_SIZE.lg,
      [theme.breakpoints.down("xs")]: {
        height: AVATAR_SIZE.sm,
        width: AVATAR_SIZE.sm,
      },
    },
  },

  displayPicture: {
    outline: `3px solid ${theme.colors.common.white[500]}`,
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
    objectFit: "cover",
  },

  displayPictureAndFilter: {
    position: "absolute",
    left: theme.spacing(0),
    top: 0,
  },

  verifiedBadge: {
    display: "inline-flex",
    color: theme.colors.primary[500],
    background: theme.colors.primary[50],
    padding: theme.spacing(1 / 2),
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.spacing(1 / 2),
    "& svg": {
      fill: theme.colors.primary[500],
    },
    [theme.breakpoints.down("xs")]: {
      background: "transparent",
      padding: theme.spacing(1 / 2, 0),
      "& span": {
        display: "none",
      },
    },
  },

  title: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  titleAndBadge: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gap: theme.spacing(1 / 2),
    },
  },

  position: {
    marginBottom: theme.spacing(1 / 2),
  },

  locations: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(6 / 8),
    [theme.breakpoints.down("xs")]: {
      gap: theme.spacing(1),
    },
    marginTop: theme.spacing(1),
  },

  content: {
    display: "flex",
    gap: theme.spacing(2),
    padding: theme.spacing(2, 3),
    minHeight: "calc(284.1px - 175px)",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      padding: theme.spacing(2),
      paddingTop: theme.spacing(5),
    },
  },

  footerButtons: {
    paddingTop: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(3),
  },
}))

function getGenderImage(gender: Gender | null): string {
  if (!gender) return Neutral

  if (Gender.MALE === gender) {
    return Male
  }
  if (Gender.FEMALE === gender) {
    return Female
  }

  return Neutral
}

const COUNTRIES_TO_SHOW = 2

const Overview = ({
  user,
  isVerified,
  currentWorkingExperience,
  picture,
  countriesWorkedIn,
  lookingForJobs = false,
  gender,
  phoneNumber,
  actions,
}: Profile & {
  actions?: React.ReactNode
}) => {
  const classes = useStyles()
  const isLoggedIn = Boolean(getAuthInfo())

  const [videoPortfolioDialogOpen, setVideoPortfolioDialogOpen] =
    useState(false)
  const [downloadingCV, setDownloadingCV] = useState(false)
  const [canDownloadCV, setCanDownloadCV] = useState(false)
  const [videoPortfolioURL, setVideoPortfolioURL] = useState<string | null>(
    null
  )

  useEffect(() => {
    async function getVideoPortfolio() {
      const res = await api.teacher.videoPortfolio.retrieve({
        urlParams: { username: user.username ?? null },
      })
      if (res.isSuccessful) {
        setVideoPortfolioURL(res.data.videoPortfolio)
      }
    }
    getVideoPortfolio()
  }, [user.username])

  useEffect(() => {
    async function getCVDownload() {
      const res = await api.users.retrieveUserJobInfo({
        params: {
          fields: ["can_view_contact_details"],
        },
        data: {
          users: [user.uuid],
        },
      })
      if (res.isSuccessful) {
        if (res.data[0].canViewContactDetails) {
          setCanDownloadCV(true)
        } else {
          setCanDownloadCV(false)
        }
      }
    }
    getCVDownload()
  }, [user.uuid])

  const downloadCV = async () => {
    setDownloadingCV(true)

    const res = await api.teacher.profile.downloadResume({
      urlParams: { userId: user.uuid },
    })

    if (res.isSuccessful) {
      saveBlobAsFile({
        data: res.__data?.data,
        type: "application/pdf",
        name: `Resume_${user.firstName}`,
      })
    } else {
      toast.error("We're unable to process your request")
    }
    setDownloadingCV(false)
  }

  return (
    <>
      <Section>
        {videoPortfolioDialogOpen && (
          <VideoPortfolioDialog
            handleClose={() => setVideoPortfolioDialogOpen(false)}
            open={videoPortfolioDialogOpen}
            setVideoPortfolioURL={setVideoPortfolioURL}
            video={videoPortfolioURL}
          />
        )}
        <header>
          <div className={classes.content}>
            <div className={classes.displayPictureContainer}>
              <img
                alt="avatar"
                className={classes.displayPicture}
                src={picture || getGenderImage(gender)}
              />
              {lookingForJobs && (
                <LookingForJobsRibbon
                  className={classes.displayPictureAndFilter}
                />
              )}
            </div>

            <div className="flex flex-col flex-grow">
              <div className="flex-grow">
                <div className={classes.title}>
                  <div className={classes.titleAndBadge}>
                    <Typography
                      component="h1"
                      display="inline"
                      variant="title3"
                    >
                      {user.firstName} {user.lastName}
                    </Typography>
                    {isVerified && (
                      <span className={classes.verifiedBadge}>
                        <TwitterVerifiedBadge
                          color="white"
                          height="22"
                          width="22"
                        />
                        <span>Verified</span>
                      </span>
                    )}
                  </div>

                  {actions}
                </div>

                {currentWorkingExperience && (
                  <>
                    <Typography className={classes.position} variant="body">
                      {currentWorkingExperience.title}
                    </Typography>

                    <Typography className="mb-1" variant="body">
                      {currentWorkingExperience.organisationName}
                    </Typography>

                    {currentWorkingExperience.teachingLevel && (
                      <div className="flex flex-wrap items-center gap-1 mb-1">
                        <Typography color="onSurface.500" variant="smallBody">
                          Teaching Level:
                        </Typography>
                        <Tag
                          color="onSurface"
                          label={currentWorkingExperience.teachingLevel}
                          size="sm"
                        />
                      </div>
                    )}

                    {Boolean(currentWorkingExperience.subjects?.length) && (
                      <div className="flex flex-wrap items-center gap-1 mb-1">
                        <Typography color="onSurface.500" variant="smallBody">
                          Subjects:
                        </Typography>
                        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                        {currentWorkingExperience.subjects!.map(x => (
                          <Tag color="onSurface" key={x} label={x} size="sm" />
                        ))}
                      </div>
                    )}
                  </>
                )}

                {countriesWorkedIn.length > 0 && (
                  <div className={classes.locations}>
                    <Typography color="onSurface.500" variant="smallBody">
                      Worked in:
                    </Typography>
                    {countriesWorkedIn.slice(0, COUNTRIES_TO_SHOW).map(item => (
                      <Tag
                        color="onSurface"
                        key={item.name}
                        label={item.name}
                        size="sm"
                      />
                    ))}
                    {countriesWorkedIn.length - COUNTRIES_TO_SHOW > 0 && (
                      <Typography color="onSurface.400" variant="smallBody">
                        + {countriesWorkedIn.length - COUNTRIES_TO_SHOW} more
                      </Typography>
                    )}
                  </div>
                )}
              </div>

              <div className="flex flex-wrap justify-between gap-1 mt-1 md:gap-2">
                <div className="flex flex-wrap gap-1 md:gap-2">
                  {phoneNumber && (
                    <div className="flex items-center">
                      <SmartphoneDevice />
                      <Typography
                        className="flex items-baseline"
                        color="onSurface.800"
                        variant="smallBody"
                      >
                        {phoneNumber.code} {phoneNumber.number}
                      </Typography>
                    </div>
                  )}

                  {user.email && (
                    <div className="flex items-center gap-0.75">
                      <Mail />
                      <Typography color="onSurface.800" variant="smallBody">
                        {user.email}
                      </Typography>
                    </div>
                  )}

                  {/* <Button
                    component="a"
                    href={`mailto:${user.email}`}
                    nudge="left"
                    variant="text"
                  >
                    Send email
                  </Button> */}
                </div>

                <div className="flex gap-1 sm:gap-2">
                  <Tooltip
                    disabled={videoPortfolioURL !== null}
                    offsetY={10}
                    title="Candidate has not uploaded a video portfolio"
                  >
                    <span>
                      <Button
                        className="ml-auto"
                        disabled={videoPortfolioURL === null}
                        nudge="left"
                        startAdornment={<Play />}
                        variant="text"
                        onClick={() => setVideoPortfolioDialogOpen(true)}
                      >
                        Video Portfolio
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip
                    disabled={isLoggedIn && canDownloadCV}
                    maxWidth="300px"
                    offsetY={10}
                    title={
                      // eslint-disable-next-line no-nested-ternary
                      !isLoggedIn
                        ? "Please sign in to unlock this feature"
                        : !canDownloadCV
                        ? "You can download the candidate's CV only after they accept an invite from you or apply to a job opening directly"
                        : ""
                    }
                  >
                    <span>
                      <Button
                        disabled={!isLoggedIn || !canDownloadCV}
                        loading={downloadingCV}
                        startAdornment={<Page />}
                        variant="text"
                        onClick={downloadCV}
                      >
                        Download CV
                      </Button>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </header>
      </Section>
    </>
  )
}

export default Overview
