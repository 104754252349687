import { Button } from "@suraasa/placebo-ui"
import React, { useContext } from "react"
import { useParams } from "react-router-dom"
import useToggle from "utils/hooks/useToggle"
import TeacherTimelineContext from "views/teacher/context"

import AddAssessmentDialog from "../Dialogs/AddAssessmentDialog"
import Card from "./Card"

const Assessments = () => {
  const { assessments, actionsAllowed } = useContext(TeacherTimelineContext)

  const [addAssessmentDialog, toggleAddAssessmentDialog] = useToggle(false)
  const { jobApplicantId } = useParams()

  return (
    <>
      {jobApplicantId && (
        <AddAssessmentDialog
          afterSubmit={data => assessments.add(data)}
          jobApplicant={jobApplicantId}
          open={addAssessmentDialog}
          toggle={toggleAddAssessmentDialog}
        />
      )}
      <div className="flex flex-col gap-2">
        <Button
          className="ml-auto"
          disabled={!actionsAllowed}
          variant="outlined"
          onClick={() => toggleAddAssessmentDialog()}
        >
          Add Assessment
        </Button>
        {assessments.data.map(assessment => (
          <Card {...assessment} key={assessment.id} />
        ))}
      </div>
    </>
  )
}

export default Assessments
