import { Checkbox, Tag, theme, Typography } from "@suraasa/placebo-ui"
import { Solutions } from "api/resources/assessments/types"
import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
  questionResultCard: {
    background: "white",
    boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(1),
  },
  optionContainer: {
    borderRadius: theme.spacing(0.5),
    width: "100%",
  },
  correctOption: {
    backgroundColor: theme.colors.success[50],
  },
  incorrectOption: {
    backgroundColor: theme.colors.critical[50],
  },
})

function QuestionResultCard({
  question,
  questionNumber,
}: {
  question: Solutions["assessmentPerformances"][0]
  questionNumber: number
}) {
  const classes = useStyles()

  const correctOptions = question.question.options.filter((item, index) =>
    question.question.correctAnswer.includes(index)
  )

  const { isCorrect } = question

  function getQuestionStatus() {
    if (isCorrect) {
      return "Correct"
    }
    if (question.response && question.response.length === 0) {
      return "Not Attempted"
    }
    return "Incorrect"
  }

  return (
    <div className={clsx(classes.questionResultCard, "px-2 py-3 mb-2")}>
      <Typography className="mb-1" variant="strongSmallBody">
        Question {questionNumber}
      </Typography>

      <div className="flex justify-between gap-8">
        <Typography variant="body">{question.question.text}</Typography>
        <Tag
          color={isCorrect ? "success" : "critical"}
          label={getQuestionStatus()}
        />
      </div>

      <div className="my-3.5 flex flex-col gap-1">
        {question.question.options.map((item, index) => (
          <div
            className={clsx(
              classes.optionContainer,
              "flex items-center py-0.5 px-0.5",
              {
                [classes.correctOption]:
                  question.question.correctAnswer.includes(index) &&
                  question.response?.includes(index),
              },
              {
                [classes.incorrectOption]:
                  !question.question.correctAnswer.includes(index) &&
                  question.response?.includes(index),
              }
            )}
            key={index}
          >
            <Checkbox
              checked={question.response?.includes(index)}
              color={
                question.question.correctAnswer.includes(index)
                  ? "success"
                  : "critical"
              }
              readOnly
            />
            <Typography className="ml-1.25" variant="body">
              {item}
            </Typography>
          </div>
        ))}
      </div>

      <div>
        {!isCorrect && (
          <>
            <Typography className="mb-0.5" variant="strongSmallBody">
              Correct Answer
            </Typography>

            {correctOptions.map((item, index) => (
              <div className="flex flex-col gap-1 mt-1" key={index}>
                <div
                  className={clsx(
                    classes.optionContainer,
                    "flex items-center py-0.5 px-0.5"
                  )}
                >
                  <Checkbox checked readOnly />
                  <Typography className="ml-1.25" variant="body">
                    {item}
                  </Typography>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default QuestionResultCard
