import { GlobalContext } from "components/GlobalState"
import LoadingOverlay from "components/shared/LoadingOverlay"
import { useContext } from "react"
import { Outlet } from "react-router-dom"

const CheckAccess = () => {
  const { canAccessPortal, loading } = useContext(GlobalContext)

  if (loading) return <LoadingOverlay showLogo />

  if (canAccessPortal) return <Outlet />

  return null
}

export default CheckAccess
