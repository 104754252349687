import { Typography, TypographyProps } from "@suraasa/placebo-ui"
import { ReactComponent as ChemistryIcon } from "assets/home/chemistry.svg"
import { ReactComponent as EconomicsIcon } from "assets/home/economics.svg"
import { ReactComponent as GearIcon } from "assets/home/gears.svg"
import { ReactComponent as MathIcon } from "assets/home/math.svg"
import { ReactComponent as SocialIcon } from "assets/home/social.svg"
import clsx from "clsx"
import { Search } from "iconoir-react"
import React from "react"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"

const useStyles = createUseStyles(theme => ({
  subjectCards: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },

  cardRoot: {
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    background: theme.colors.warning[100],
    borderColor: theme.colors.warning[200],
    padding: theme.spacing(2, 2),
    gap: theme.spacing(1.5),
    border: "1px solid",
    textDecoration: "none",
    boxShadow: " 0px 6px 15px rgba(0, 0, 0, 0.05)",
    transition: "0.25s ease-in box-shadow",

    "&:hover, &:focus-visible": {
      boxShadow: " 0px 6px 15px rgba(0, 0, 0, 0.15)",
    },
    "&:focus-visible": {
      outline: `3px solid ${theme.colors.interactive[400]}`,
    },
  },

  cardRootSuccess: {
    background: theme.colors.success[100],
    borderColor: theme.colors.success[200],
  },
  cardRootCritical: {
    background: theme.colors.critical[100],
    borderColor: theme.colors.critical[200],
  },
  cardRootHighlight: {
    background: theme.colors.highlight[100],
    borderColor: theme.colors.highlight[200],
  },
  cardRootDecorativeTwo: {
    background: theme.colors.decorative.two[50],
    borderColor: "#E8D6FA",
  },
  cardRootSurface: {
    background: theme.colors.surface[100],
    borderColor: theme.colors.surface[200],
  },

  cardTitle: {
    width: "168px",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      whiteSpace: "nowrap",
    },
  },

  cardButton: {
    marginTop: theme.spacing(1),
    color: theme.colors.warning[600],
  },

  searchIcon: {
    color: theme.colors.onSurface[500],
  },
}))

const {
  REACT_APP_MATHEMATICS_SUBJECT_UUID: MATHEMATICS_UUID,
  REACT_APP_SOCIAL_STUDIES_SUBJECT_UUID: SOCIAL_STUDIES_UUID,
  REACT_APP_ECONOMICS_SUBJECT_UUID: ECONOMICS_UUID,
  REACT_APP_CHEMISTRY_SUBJECT_UUID: CHEMISTRY_UUID,
  REACT_APP_PHYSICS_SUBJECT_UUID: PHYSICS_UUID,
} = process.env

const SubjectCards = () => {
  const classes = useStyles()

  const cards: {
    title: string
    typographyColor: TypographyProps["color"]
    rootClassName: string
    icon: React.ReactElement
    to: string
  }[] = [
    {
      title: "Mathematics Teacher",
      typographyColor: "warning.900",
      rootClassName: "",
      icon: <MathIcon />,
      to: `/explore?subjects[]=${MATHEMATICS_UUID}`,
    },
    {
      title: "Social Studies Teacher",
      typographyColor: "decorative.two.200",
      rootClassName: classes.cardRootDecorativeTwo,
      icon: <SocialIcon />,
      to: `/explore?subjects[]=${SOCIAL_STUDIES_UUID}`,
    },
    {
      title: "Economics Teacher",
      typographyColor: "critical.900",
      rootClassName: classes.cardRootCritical,
      icon: <EconomicsIcon />,
      to: `/explore?subjects[]=${ECONOMICS_UUID}`,
    },
    {
      title: "Chemistry Teacher",
      typographyColor: "highlight.800",
      rootClassName: classes.cardRootHighlight,
      icon: <ChemistryIcon />,
      to: `/explore?subjects[]=${CHEMISTRY_UUID}`,
    },
    {
      title: "Physics Teacher",
      typographyColor: "success.900",
      rootClassName: classes.cardRootSuccess,
      icon: <GearIcon />,
      to: `/explore?subjects[]=${PHYSICS_UUID}`,
    },
    {
      title: "Other Subjects",
      typographyColor: "onSurface.800",
      rootClassName: classes.cardRootSurface,
      icon: <Search className={classes.searchIcon} />,
      to: "/explore",
    },
  ]

  return (
    <div className={classes.subjectCards}>
      {cards.map(card => (
        <Link
          className={clsx(classes.cardRoot, card.rootClassName)}
          key={card.title}
          to={card.to}
        >
          {card.icon}
          <Typography
            className={clsx(classes.cardTitle, "sm:mb-2")}
            color={card.typographyColor}
            variant="title2"
          >
            {card.title}
          </Typography>

          <Typography
            className={classes.cardButton}
            color={card.typographyColor}
            variant="button"
          >
            Hire Now
          </Typography>
        </Link>
      ))}
    </div>
  )
}

export default SubjectCards
