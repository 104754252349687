import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Pagination,
  theme,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { ExploreTeacher, InvitationStatus, Job } from "api/resources/jobs/types"
import clsx from "clsx"
import InviteToJobDialog from "components/explore/InviteToJobDialog"
import JobPostingCard from "components/home/JobPostingCard"
import Overview from "components/home/Overview"
import SubjectCards from "components/home/SubjectCards"
import UserDetailsCard from "components/home/UserDetailsCard"
import Navbar from "components/shared/Navbar"
import ReactHelmet from "components/shared/ReactHelmet"
import { ArrowRight, Cancel, Check, Plus } from "iconoir-react"
import metadata from "metadata.json"
import React, { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { Link, useNavigate } from "react-router-dom"
import { routes } from "utils/routes"

const useStyles = createUseStyles(({ colors }) => ({
  jobsPlaceholder: {
    border: `1px solid ${colors.surface[200]}`,
    borderRadius: "4px",
  },

  viewMoreButton: {
    "& > span": {
      justifyContent: "flex-end",
    },
  },
}))

const Home = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const [inviteDialogOpen, setInviteDialogOpen] = useState(false)
  const [teacher, setTeacher] = useState<ExploreTeacher>()

  const [teachers, setTeachers] = useState<ExploreTeacher[]>([])

  const [loading, setLoading] = useState(true)
  const [postings, setPostings] = useState<{
    data: Job[]
    total: number
    page: number
  }>({
    data: [],
    total: 0,
    page: 1,
  })

  const removeJobPostingFromList = (id: number) => {
    setPostings(item => ({
      ...item,
      data: item.data.filter(v => v.id !== id),
    }))
  }

  useEffect(() => {
    const listPostings = async () => {
      setLoading(true)
      const res = await api.jobs.list({
        params: {
          page: postings.page,
          fields: ["school", "number_of_applicants", "date_published"],
        },
      })

      if (res.isSuccessful) {
        setPostings(prevState => ({
          ...prevState,
          data: res.data.data,
          total: res.data.total,
        }))
        setLoading(false)
      }
    }
    listPostings()
  }, [postings.page])

  useEffect(() => {
    const listTeachers = async () => {
      const res = await api.teacher.explore.list({
        params: { page: 1, isVerified: true },
      })

      if (res.isSuccessful) {
        setTeachers(res.data.data)
      }
    }

    listTeachers()
  }, [postings.page])

  const getAction = (item: ExploreTeacher) => {
    if (item.jobApplicant?.invitationStatus) {
      if (item.jobApplicant.invitationStatus === InvitationStatus.PENDING)
        return (
          <div className="flex items-center gap-0.5">
            <Check color={theme.colors.success[500]} />
            <Typography color="success.500">Invited</Typography>
          </div>
        )
      if (item.jobApplicant.invitationStatus === InvitationStatus.REJECTED)
        return (
          <div className="flex items-center gap-0.5">
            <Cancel color={theme.colors.critical[500]} />
            <Typography color="critical.500">Rejected</Typography>
          </div>
        )
      if (item.jobApplicant.invitationStatus === InvitationStatus.ACCEPTED)
        return (
          <div className="flex items-center gap-0.5">
            <Check color={theme.colors.success[500]} />
            <Typography color="success.500">Accepted</Typography>
          </div>
        )
    }
    return (
      <Button
        startAdornment={<Plus />}
        variant="text"
        onClick={() => {
          setTeacher(item)
          setInviteDialogOpen(true)
        }}
      >
        Invite
      </Button>
    )
  }

  return (
    <>
      <ReactHelmet data={metadata.home} />
      <Navbar hideBackButton />
      <Container className="mb-4">
        <div className="flex justify-end mb-7">
          <Button
            className="pl-0"
            component={Link}
            startAdornment={<Plus />}
            to={routes.jobCreate}
          >
            Create New Job Opening
          </Button>
        </div>

        {/* eslint-disable-next-line no-nested-ternary */}
        {loading ? (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        ) : postings.data.length > 0 ? (
          <div>
            {postings.data.map((item, index) => (
              <JobPostingCard
                afterSubmit={() => removeJobPostingFromList(item.id)}
                job={item}
                key={index}
              />
            ))}
            <Button
              className="mt-2"
              variant="ghost"
              onClick={() => {
                navigate(routes.jobClosed)
              }}
            >
              Closed Jobs
            </Button>
            {postings.total > 10 && (
              <Pagination
                page={postings.page}
                total={postings.total}
                onChange={newPage =>
                  setPostings(prevState => ({ ...prevState, page: newPage }))
                }
              />
            )}
          </div>
        ) : (
          <>
            <div className={clsx("py-3", classes.jobsPlaceholder)}>
              <Typography color="onSurface.500" textAlign="center">
                You haven't created any Job Openings yet. Click on the Create
                New Job Opening button to start recruiting!
              </Typography>
            </div>
            <Button
              className="mt-2"
              variant="ghost"
              onClick={() => {
                navigate(routes.jobClosed)
              }}
            >
              Closed Jobs
            </Button>
          </>
        )}

        <div className="mb-12 mt-5">
          <Typography className="mb-3" variant="title2">
            Hire Teachers
          </Typography>
          <SubjectCards />
        </div>

        {teachers.length > 0 && (
          <>
            <Overview />
            <div className="grid grid-cols-12">
              <div className="col-span-12 sm:col-span-10 sm:col-start-2 md:col-span-8 md:col-start-3">
                {teachers.map((item, index) => (
                  <UserDetailsCard
                    action={getAction(item)}
                    applications={item.interviewingSchools}
                    canViewContactDetails={item.canViewContactDetails}
                    key={index}
                    user={item.user}
                    verifiedSkillEvidences={item.verifiedSkillEvidences}
                  />
                ))}

                <Divider />

                <Button
                  className={classes.viewMoreButton}
                  color="black"
                  component={Link}
                  endAdornment={<ArrowRight />}
                  to={routes.explore}
                  variant="text"
                  fullWidth
                >
                  View More Teachers
                </Button>
              </div>
            </div>
          </>
        )}

        {teacher && (
          <InviteToJobDialog
            open={inviteDialogOpen}
            teacherId={teacher.user.uuid}
            onRequestClose={() => setInviteDialogOpen(false)}
          />
        )}
      </Container>
    </>
  )
}

export default Home
