import {
  CircularProgress,
  Container,
  Pagination,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Job } from "api/resources/jobs/types"
import clsx from "clsx"
import JobPostingCard from "components/home/JobPostingCard"
import Navbar from "components/shared/Navbar"
import React, { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  jobsPlaceholder: {
    border: `1px solid ${theme.colors.surface[200]}`,
    borderRadius: "4px",
  },
}))

const Closed = () => {
  const classes = useStyles()

  const [loading, setLoading] = useState(true)
  const [postings, setPostings] = useState<{
    data: Job[]
    total: number
    page: number
  }>({
    data: [],
    total: 0,
    page: 1,
  })

  const removeJobPostingFromList = (id: number) => {
    setPostings(item => ({
      ...item,
      data: item.data.filter(v => v.id !== id),
    }))
  }

  useEffect(() => {
    const listPostings = async () => {
      setLoading(true)
      const res = await api.jobs.list({
        params: {
          page: postings.page,
          fields: [
            "date_published",
            "date_closed",
            "number_of_applicants",
            "school",
          ],
          status: 3,
        },
      })

      if (res.isSuccessful) {
        setPostings(prevState => ({
          ...prevState,
          data: res.data.data,
          total: res.data.total,
        }))
        setLoading(false)
      }
    }
    listPostings()
  }, [postings.page])

  return (
    <>
      <Navbar gutterBottom={false} />
      <Container>
        <Typography className="mb-3 mt-1" variant="title2">
          Closed Jobs
        </Typography>
        {/* eslint-disable-next-line no-nested-ternary */}
        {loading ? (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        ) : postings.data.length > 0 ? (
          <div className="mb-4">
            {postings.data.map((item, index) => (
              <JobPostingCard
                afterSubmit={() => removeJobPostingFromList(item.id)}
                job={item}
                key={index}
              />
            ))}
            {postings.total > 10 && (
              <Pagination
                page={postings.page}
                total={postings.total}
                onChange={newPage =>
                  setPostings(prevState => ({ ...prevState, page: newPage }))
                }
              />
            )}
          </div>
        ) : (
          <div className={clsx("py-3", classes.jobsPlaceholder)}>
            <Typography color="onSurface.500" textAlign="center">
              There are no closed jobs
            </Typography>
          </div>
        )}
      </Container>
    </>
  )
}

export default Closed
