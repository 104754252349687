import { Container, Typography } from "@suraasa/placebo-ui"
import { ReactComponent as SuccessCheck } from "assets/success-check-circled-outline.svg"
import Navbar from "components/shared/Navbar"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  root: {
    background: "#fff",
    border: `1px solid ${theme.colors.onSurface[200]}`,
    padding: theme.spacing(10, 4),
  },
}))

const OfferLetterSent = () => {
  const classes = useStyles()
  return (
    <>
      <Navbar />
      <Container>
        <div className={classes.root}>
          <div className="flex gap-1">
            <SuccessCheck />
            <Typography variant="title1">Offer Letter Sent!</Typography>
          </div>
          <Typography className="mt-2" variant="body">
            Offer Letter was successfully sent to the candidate. We will let you
            know!
          </Typography>
        </div>
      </Container>
    </>
  )
}

export default OfferLetterSent
