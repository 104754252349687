import {
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  SubMenu,
  Tag,
  Tooltip,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  ActiveUser,
  Job,
  JobApplicantStatus,
  JobApplicantStepStatus,
  JobOfferStatus,
  StepType,
} from "api/resources/jobs/types"
import { PaginatedResponse } from "api/types"
import { ReactComponent as VerifiedBadge } from "assets/home/verifiedBadge.svg"
import clsx from "clsx"
import AddAssessmentDialog from "components/teacher/Dialogs/AddAssessmentDialog"
import AddFeedbackDialog from "components/teacher/Dialogs/AddFeedbackDialog"
import EditAssessmentDueDate from "components/teacher/Dialogs/EditAssessmentDueDate"
import EditInterviewDialog from "components/teacher/Dialogs/EditInterviewDialog"
import EditLiveDemoDialog from "components/teacher/Dialogs/EditLiveDemoDialog"
import MarkAsCompleteDialog from "components/teacher/Dialogs/MarkAsCompleteDialog"
import RejectApplicationDialog from "components/teacher/Dialogs/RejectApplicationDialog"
import { format } from "date-fns"
import { MoreVert, QuestionMark, WarningCircle } from "iconoir-react"
import React, { useCallback, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { Link, useNavigate } from "react-router-dom"
import { JobStatus } from "utils/constants"
import { getTag } from "utils/getStepStatusTag"
import { generateHuddleURL } from "utils/helpers"
import { routes } from "utils/routes"

const useStyles = createUseStyles(theme => ({
  root: {
    [theme.breakpoints.down("md")]: {
      overflowX: "auto",
    },
  },
  container: {
    background: "white",
    borderRadius: "4px",
    border: `1px solid ${theme.colors.surface[200]}`,
    minWidth: "800px",
  },

  tableGrid: {
    display: "grid",
    gridTemplateColumns: "70px 1.25fr 1fr 1fr 1.5fr 50px",
  },

  tableItem: {
    borderTop: `1px solid ${theme.colors.surface[200]}`,
    position: "relative",
  },
  error: {
    borderLeft: `3px solid ${theme.colors.warning[400]}`,
  },

  image: {
    width: "32px",
    height: "32px",
  },

  textSmall: {
    fontSize: "12px",
    lineHeight: "15.6px",
  },

  warningIcon: {
    position: "absolute",
    left: "-40px",
    height: "100%",
    color: theme.colors.warning[500],
  },

  noApplicantsDiv: {
    background: "#f8fafc",
    border: `1px solid ${theme.colors.onSurface[200]}`,
    borderRadius: theme.spacing(0.5),
    paddingLeft: theme.spacing(9),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

type Props = {
  job: Pick<Job, "position" | "id" | "dateReopened" | "status">
  isClosed?: boolean
}

const getJobApplicationStatus = (item: ActiveUser) => {
  switch (item.status) {
    case JobApplicantStatus.JOB_OFFER_REJECTED:
      return <Tag color="critical" label="Job Offer Rejected" />
    case JobApplicantStatus.WITHDRAWN:
      return <Tag color="critical" label="Application Withdrawn" />
    case JobApplicantStatus.JOB_OFFER_ACCEPTED:
      return <Tag color="success" label="Job Offer Accepted" />
    case JobApplicantStatus.CANCELLED:
      return <Tag color="onSurface" label="Cancelled" />
    case JobApplicantStatus.REJECTED:
      return <Tag color="onSurface" label="Application Rejected" />
    default:
      break
  }
}

const getStepName = ({ currentStep: step, jobOffer }: ActiveUser) => {
  if (jobOffer) {
    switch (jobOffer.status) {
      case JobOfferStatus.SENT:
        return "Offer letter sent"
      case JobOfferStatus.ACCEPTED:
        return "Offer accepted"
      case JobOfferStatus.REJECTED:
        return "Offer rejected"

      default:
        return "-"
    }
  }

  if (!step) return "-"

  switch (step.stepType) {
    case StepType.INTERVIEW:
      return `Interview - ${step.step.name}`

    case StepType.LIVE_DEMO:
      return `Live Demo ${step.step.isVirtual ? "(Online)" : "(Offline)"} - ${
        step.step.name
      }`

    case StepType.ASSESSMENT:
      return `Assessment - ${step.step.title}`
    default:
      return "-"
  }
}

const canMarkAsComplete = (item: ActiveUser) => {
  // No point of this button if there is no step
  if (!item.currentStep) return false

  // Cannot mark assessment as complete
  if (item.currentStep.stepType === StepType.ASSESSMENT) return false

  return item.currentStep.status !== JobApplicantStepStatus.COMPLETED
}

const ActiveApplicationsTab = ({ job, isClosed }: Props) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)

  // const applicants = useArray<ActiveUser>([])
  const [applicants, setApplicants] = useState<
    PaginatedResponse<ActiveUser[]>["data"]
  >({
    data: [],
    nextPage: null,
    previousPage: null,
    total: 0,
  })

  /**
   * isCreating is used to differentiate,
   * whether the dialog was opened for add or edit
   * for interview and live demo
   */
  const [isCreating, setIsCreating] = useState(false)

  const [openEditAssessmentDateDialog, setOpenEditAssessmentDateDialog] =
    useState<ActiveUser | null>(null)
  const [openInterviewDialog, setOpenInterviewDialog] =
    useState<ActiveUser | null>(null)
  const [openLiveDemo, setOpenLiveDemoDialog] = useState<ActiveUser | null>(
    null
  )
  const [openAssessment, setOpenAssessmentDialog] = useState<ActiveUser | null>(
    null
  )
  const [openAddFeedback, setOpenAddFeedback] = useState<ActiveUser | null>(
    null
  )

  const [openRejectApplicant, setOpenRejectApplicantDialog] =
    useState<ActiveUser | null>(null)
  const [openMarkAsComplete, setOpenMarkAsComplete] =
    useState<ActiveUser | null>(null)

  const listApplicants = useCallback(async () => {
    setLoading(true)
    const res = await api.jobs.jobApplicant.listActive({
      urlParams: {
        jobId: job.id,
      },
      params: {
        page,
        status: job.status === JobStatus.CLOSED ? "previous" : null,
      },
    })
    if (res.isSuccessful) {
      setApplicants(res.data)
      setLoading(false)
    }
  }, [page, job.id, job.status])

  useEffect(() => {
    if (job.id) listApplicants()
  }, [job.id, page, listApplicants])

  const renderAddStepButton = ({ currentStep }: ActiveUser) => {
    if (currentStep === null) return true

    if (
      currentStep.stepType === StepType.INTERVIEW &&
      currentStep.step.url &&
      currentStep.status !== JobApplicantStepStatus.IN_PROGRESS
    ) {
      return true
    }

    if (
      currentStep.stepType === StepType.LIVE_DEMO &&
      currentStep.step.meetingUrl
    ) {
      return true
    }

    return currentStep.status !== JobApplicantStepStatus.COMPLETED
  }

  const AddAnotherStep = ({ item }: { item: ActiveUser }) => (
    <Menu
      menuButton={
        <Button size="sm" variant="text">
          Add another step
        </Button>
      }
    >
      <MenuItem
        onClick={() => {
          setOpenInterviewDialog(item)
          setIsCreating(true)
        }}
      >
        Interview
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenLiveDemoDialog(item)
          setIsCreating(true)
        }}
      >
        Live Demo
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenAssessmentDialog(item)
          setIsCreating(true)
        }}
      >
        Assessment
      </MenuItem>
    </Menu>
  )

  const InterviewActions = ({ item }: { item: ActiveUser }) => {
    if (item.currentStep?.stepType !== StepType.INTERVIEW) return null

    if (
      item.currentStep.step.url &&
      item.currentStep.status !== JobApplicantStepStatus.IN_PROGRESS
    )
      return (
        <Button
          component={Link}
          rel="opener"
          target="_blank"
          to={generateHuddleURL({
            meetingUrl: item.currentStep.step.url,
            interviewId: item.currentStep.step.id,
          })}
          variant="text"
        >
          Join Interview
        </Button>
      )

    switch (item.currentStep.status) {
      case JobApplicantStepStatus.COMPLETED:
        return <AddAnotherStep item={item} />
      case JobApplicantStepStatus.NOT_STARTED:
        return (
          <Button
            size="sm"
            variant="text"
            onClick={() => setOpenInterviewDialog(item)}
          >
            Reschedule Interview
          </Button>
        )
      case JobApplicantStepStatus.IN_PROGRESS:
        return (
          <>
            {item.currentStep.step.url && (
              <Button
                component={Link}
                rel="opener"
                target="_blank"
                to={generateHuddleURL({
                  meetingUrl: item.currentStep.step.url,
                  interviewId: item.currentStep.step.id,
                })}
                variant="text"
              >
                Join Interview
              </Button>
            )}
            <Button
              color="success"
              variant="text"
              onClick={() => setOpenMarkAsComplete(item)}
            >
              Mark As Complete
            </Button>
          </>
        )

      default:
        return null
    }
  }

  const LiveDemoActions = ({ item }: { item: ActiveUser }) => {
    if (item.currentStep?.stepType !== StepType.LIVE_DEMO) return null

    if (item.currentStep.step.meetingUrl)
      return (
        <Button
          component="a"
          href={item.currentStep.step.meetingUrl}
          target="_blank"
          variant="text"
        >
          Join Live Demo
        </Button>
      )

    switch (item.currentStep.status) {
      case JobApplicantStepStatus.COMPLETED:
        return <AddAnotherStep item={item} />
      case JobApplicantStepStatus.NOT_STARTED:
        return (
          <Button
            size="sm"
            variant="text"
            onClick={() => setOpenLiveDemoDialog(item)}
          >
            Reschedule Live Demo
          </Button>
        )
      case JobApplicantStepStatus.IN_PROGRESS:
        return (
          <Button
            color="success"
            variant="text"
            onClick={() => setOpenMarkAsComplete(item)}
          >
            Mark As Complete
          </Button>
        )

      default:
        return null
    }
  }

  const AssessmentActions = ({ item }: { item: ActiveUser }) => {
    switch (item.currentStep?.status) {
      case JobApplicantStepStatus.COMPLETED:
        return <AddAnotherStep item={item} />
      case JobApplicantStepStatus.NOT_STARTED:
        return (
          <Button
            size="sm"
            variant="text"
            onClick={() => setOpenEditAssessmentDateDialog(item)}
          >
            Change Deadline
          </Button>
        )
      // case JobApplicantStepStatus.IN_PROGRESS:
      //   return (
      //     <>
      //       <Button
      //         color="success"
      //         variant="text"
      //         onClick={() => setOpenMarkAsComplete(item)}
      //       >
      //         Mark As Complete
      //       </Button>
      //     </>
      //   )

      default:
        return null
    }
  }

  const actions = (item: ActiveUser) => {
    if (item.jobOffer)
      return (
        <Button
          component={Link}
          nudge="left"
          to={routes.viewOffer
            .replace(":username", item.user.username)
            .replace(":jobOfferId", String(item.jobOffer.id))}
          variant="text"
        >
          View Job Offer
        </Button>
      )

    if (item.currentStep === null) {
      return (
        <Button
          nudge="left"
          variant="text"
          onClick={() => setOpenInterviewDialog(item)}
        >
          Set up Interview
        </Button>
      )
    }

    switch (item.currentStep.stepType) {
      case StepType.ASSESSMENT:
        return <AssessmentActions item={item} />
      case StepType.INTERVIEW:
        return <InterviewActions item={item} />
      case StepType.LIVE_DEMO:
        return <LiveDemoActions item={item} />
      default:
        return null
    }
  }

  const removeApplicantFromList = () => {
    setApplicants(item => ({
      ...item,
      data: item.data.filter(v => v.id !== openRejectApplicant?.id),
    }))
  }

  if (loading)
    return (
      <div className="flex justify-center mt-5">
        <CircularProgress />
      </div>
    )

  // if (applicants.data.length === 0)
  //   return (
  //     <NoDataCard
  //       className="mt-3"
  //       message="There are no active applications. Head to the 'Applications' tab to check out teachers who are waiting to be a part of this hiring process."
  //     />
  //   )

  return (
    <>
      <div className={clsx(classes.root, "my-3")}>
        <div className={classes.container}>
          <div className={clsx(classes.tableGrid, "py-2")}>
            <div />
            <Typography variant="strongSmallBody">Name</Typography>

            <div className="flex items-center gap-0.5">
              <Typography variant="strongSmallBody">
                {isClosed ? "Status" : "Current Step"}
              </Typography>
              <Tooltip
                offsetY={10}
                title="Candidate's current stage in your hiring process"
              >
                <QuestionMark
                  color="#64748B"
                  style={{ height: "20px", width: "20px" }}
                />
              </Tooltip>
            </div>

            <Typography variant="strongSmallBody">Time</Typography>
          </div>
          {applicants.data.length > 0 ? (
            <>
              {applicants.data.map(item => {
                const tag =
                  !item.currentStep || item.jobOffer?.id
                    ? null
                    : getTag(item.currentStep)

                const interviewNotScheduled =
                  item.currentStep === null && !item.jobOffer?.id

                return (
                  <div
                    className={clsx(
                      classes.tableGrid,
                      classes.tableItem,
                      { [classes.error]: interviewNotScheduled },
                      "py-2"
                    )}
                    key={item.id}
                  >
                    <div className="pl-3">
                      <Avatar
                        name={item.user.fullName}
                        size="xs"
                        src={item.user.profile.picture ?? undefined}
                      />
                    </div>
                    <div>
                      <div className="flex items-center gap-0.5">
                        <Button
                          className="-ml-0.75"
                          component={Link}
                          to={routes.teacherTimeline
                            .replace(":jobApplicantId", String(item.id))
                            .replace(":username", item.user.username)}
                          variant="link"
                        >
                          {item.user.fullName}
                        </Button>
                        {item.user.profile.isVerified && <VerifiedBadge />}
                      </div>

                      <div className="flex items-center">
                        <Typography
                          className={classes.textSmall}
                          color="onSurface.500"
                        >
                          {item.user.email}
                        </Typography>
                        {/* <button
                    onClick={() =>
                      window.open(`mailto:${item.personal.email}`, "_blank")
                    }
                  >
                    <OpenInWindow />
                  </button> */}
                      </div>

                      {item.user.profile.phoneNumber && (
                        <Typography
                          className={classes.textSmall}
                          color="onSurface.500"
                        >
                          +{item.user.profile.phoneNumber.code}
                          {item.user.profile.phoneNumber.number}
                        </Typography>
                      )}
                    </div>

                    <div className="flex flex-col justify-center">
                      {isClosed ? (
                        getJobApplicationStatus(item)
                      ) : (
                        <>
                          <Typography className="mb-0.5" variant="smallBody">
                            {getStepName(item)}
                          </Typography>
                          {tag}
                        </>
                      )}
                    </div>

                    <div className="flex flex-col justify-center">
                      {item.currentStep?.dueDate ? (
                        <>
                          <Typography variant="smallBody">
                            <b>
                              {format(
                                new Date(item.currentStep.dueDate),
                                "d MMM yyyy"
                              )}
                            </b>
                            <br />
                            {format(
                              new Date(item.currentStep.dueDate),
                              "hh:mm aa"
                            )}
                          </Typography>
                        </>
                      ) : (
                        <Typography variant="smallBody">-</Typography>
                      )}
                    </div>

                    {isClosed !== true && (
                      <div className="flex flex-col items-end justify-center">
                        {actions(item)}
                      </div>
                    )}
                    {isClosed !== true && (
                      <div className="flex items-center justify-end mr-1.25">
                        <Menu
                          menuButton={
                            <IconButton>
                              <MoreVert />
                            </IconButton>
                          }
                          portal
                        >
                          <MenuItem
                            onClick={() => {
                              navigate(
                                `${routes.teacherProfile.replace(
                                  ":username",
                                  item.user.username
                                )}?jobApplicantId=${item.id}`
                              )
                            }}
                          >
                            View Profile
                          </MenuItem>
                          {canMarkAsComplete(item) && (
                            <MenuItem
                              onClick={() => setOpenMarkAsComplete(item)}
                            >
                              Mark As Complete
                            </MenuItem>
                          )}

                          <MenuItem
                            disabled={Boolean(item.jobOffer?.id)}
                            onClick={() => {
                              navigate(
                                `${routes.sendOffer
                                  .replace(":jobApplicantId", String(item.id))
                                  .replace(":username", item.user.username)}`
                              )
                            }}
                          >
                            Send Offer
                          </MenuItem>
                          {renderAddStepButton(item) && (
                            <SubMenu
                              disabled={Boolean(item.jobOffer?.id)}
                              label="Add Step"
                            >
                              <MenuItem
                                onClick={() => {
                                  setOpenInterviewDialog(item)
                                  setIsCreating(true)
                                }}
                              >
                                Interview
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  setOpenLiveDemoDialog(item)
                                  setIsCreating(true)
                                }}
                              >
                                Live Demo
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  setOpenAssessmentDialog(item)
                                  setIsCreating(true)
                                }}
                              >
                                Assessment
                              </MenuItem>
                            </SubMenu>
                          )}

                          <MenuItem
                            color="critical"
                            disabled={Boolean(item.jobOffer?.id)}
                            onClick={() => setOpenRejectApplicantDialog(item)}
                          >
                            Reject
                          </MenuItem>
                        </Menu>
                      </div>
                    )}

                    {interviewNotScheduled && (
                      <Tooltip
                        offsetY={-20}
                        title="Interview not scheduled yet"
                      >
                        <WarningCircle className={classes.warningIcon} />
                      </Tooltip>
                    )}
                  </div>
                )
              })}
            </>
          ) : (
            <div>
              <Typography
                className={classes.noApplicantsDiv}
                color="onSurface.500"
                variant="smallBody"
              >
                No Active Applications
              </Typography>
            </div>
          )}
        </div>
        {job.status !== JobStatus.CLOSED && (
          <div className="py-1.5 pl-1">
            <Button
              component={Link}
              to={`/job/previous/${job.id}/`}
              variant="text"
            >
              Previous Applications
            </Button>
          </div>
        )}
      </div>
      <Pagination page={page} total={applicants.total} onChange={setPage} />

      {openEditAssessmentDateDialog?.currentStep && (
        <EditAssessmentDueDate
          afterEdit={() => listApplicants()}
          data={{
            dueDate: openEditAssessmentDateDialog.currentStep.dueDate,
            id: openEditAssessmentDateDialog.currentStep.step.id,
            jobApplicantStepId: openEditAssessmentDateDialog.currentStep.id,
          }}
          open={Boolean(openEditAssessmentDateDialog)}
          toggle={() => setOpenEditAssessmentDateDialog(null)}
          isEditing
        />
      )}

      {openAssessment && (
        <AddAssessmentDialog
          afterSubmit={() => listApplicants()}
          jobApplicant={openAssessment.id}
          open={Boolean(openAssessment)}
          toggle={() => {
            setOpenAssessmentDialog(null)
            setIsCreating(false)
          }}
        />
      )}

      {openLiveDemo && (
        <EditLiveDemoDialog
          afterSubmit={() => listApplicants()}
          // @ts-expect-error Type narrowing needed
          data={isCreating ? undefined : openLiveDemo.currentStep}
          jobApplicant={openLiveDemo.id}
          open={Boolean(openLiveDemo)}
          toggle={() => {
            setOpenLiveDemoDialog(null)
            setIsCreating(false)
          }}
        />
      )}

      {openInterviewDialog && (
        <EditInterviewDialog
          afterSubmit={() => listApplicants()}
          // @ts-expect-error Type narrowing needed
          data={isCreating ? undefined : openInterviewDialog.currentStep}
          jobApplicant={openInterviewDialog.id}
          open={Boolean(openInterviewDialog)}
          toggle={() => {
            setOpenInterviewDialog(null)
            setIsCreating(false)
          }}
        />
      )}

      {openRejectApplicant && (
        <RejectApplicationDialog
          afterSubmit={removeApplicantFromList}
          jobApplicantId={openRejectApplicant.id}
          jobPosition={job.position}
          mode="activeApplication"
          open={Boolean(openRejectApplicant)}
          toggle={() => setOpenRejectApplicantDialog(null)}
          userName={openRejectApplicant.user.fullName}
        />
      )}

      {openMarkAsComplete?.currentStep && (
        <MarkAsCompleteDialog
          data={{
            id: openMarkAsComplete.currentStep.id,
            name: getStepName(openMarkAsComplete),
            afterSubmit: () => {
              listApplicants()
              setOpenAddFeedback(openMarkAsComplete)
            },
          }}
          open={Boolean(openMarkAsComplete)}
          toggle={() => setOpenMarkAsComplete(null)}
        />
      )}

      {openAddFeedback?.currentStep && (
        <AddFeedbackDialog
          id={openAddFeedback.currentStep.step.id}
          open={Boolean(openAddFeedback)}
          toggle={() => setOpenAddFeedback(null)}
          type={openAddFeedback.currentStep?.stepType}
        />
      )}
    </>
  )
}
export default ActiveApplicationsTab
