import { Divider, Tag, Typography } from "@suraasa/placebo-ui"
import { JobOffer, JobOfferStatus } from "api/resources/jobs/types"
import clsx from "clsx"
import Section from "components/Section"
import SectionContent from "components/Section/SectionContent"
import { format } from "date-fns"
import React, { PropsWithChildren } from "react"
import { createUseStyles } from "react-jss"

import PreviewFile from "./PreviewFile"

const useStyles = createUseStyles({
  root: {
    "& .PreviewFile-box": {
      width: "min(100%,284px)",
      height: 136,
    },
  },
  details: {
    maxWidth: "calc(146px + 124px + 115px)",
  },
  description: {
    whiteSpace: "pre-wrap",
  },
})

const OfferDetails = ({
  children,
  details,
}: PropsWithChildren<{ details: JobOffer }>) => {
  const classes = useStyles()

  return (
    <>
      <div className="mt-6 mb-2 flex items-center justify-between">
        <Typography color="onSurface.800" variant="title3">
          Job Offer Details
        </Typography>
        {details.status === JobOfferStatus.SENT && (
          <Tag label="Offer letter sent" />
        )}
        {details.status === JobOfferStatus.ACCEPTED && (
          <Tag color="success" label="Accepted" />
        )}
        {details.status === JobOfferStatus.REJECTED && (
          <Tag color="critical" label="Rejected" />
        )}
      </div>
      <Section className="mb-4 p-1">
        <SectionContent className={classes.root}>
          <div
            className={clsx("flex flex-wrap justify-between", classes.details)}
          >
            <div>
              <Typography className="mb-0.5" variant="strong">
                Salary (Per Annum)
              </Typography>
              <Typography variant="largeBody">
                {details.currency.symbol}
                {details.finalSalary.toLocaleString()}
              </Typography>
            </div>
            <div>
              <Typography className="mb-0.5" variant="strong">
                Date of Joining
              </Typography>
              <Typography variant="largeBody">
                {format(new Date(details.joiningDate), "dd/MM/yyyy")}
              </Typography>
            </div>
          </div>

          <Typography className="mt-4" variant="strong">
            Description
          </Typography>
          <Typography className={classes.description} variant="body">
            {details.description}
          </Typography>
          <Divider className="my-4" color="onSurface.200" weight="light" />
          {details.offerLetter && (
            <PreviewFile
              data={details.offerLetter}
              name={details.offerLetterName}
            />
          )}
          {children}
        </SectionContent>
      </Section>
    </>
  )
}

export default OfferDetails
