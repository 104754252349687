import axios from "api/axios"
import { APIResponse, ConfigWithURLParams } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { AssignmentAttachment, Submission } from "./types"
import { urls } from "./urls"

export default {
  retrieveSubmission: async ({
    urlParams,
    params,
    headers,
  }: ConfigWithURLParams): Promise<APIResponse<Submission>> => {
    try {
      const res = await axios.get(
        urls.retrieveSubmission(urlParams.submissionId),
        { params, headers }
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  getAttachment: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<AssignmentAttachment>> => {
    try {
      const res = await axios.get(urls.attachment(urlParams.attachmentId))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
