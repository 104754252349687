import { Button, Container, Typography } from "@suraasa/placebo-ui"
import React from "react"
import { createUseStyles } from "react-jss"
import { useNavigate } from "react-router-dom"
import { routes } from "utils/routes"

const useStyles = createUseStyles(theme => ({
  root: {
    background: theme.colors.success[100],
    padding: theme.spacing(2, 0),
  },
}))

const SchoolIsVerified = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <div className={classes.root}>
      <Container>
        <div className="flex-col sm:flex-row flex gap-2 justify-between items-center">
          <div>
            <Typography color="success.700" variant="strongSmallBody">
              Profile verification almost complete!
            </Typography>
            <Typography
              className="mt-0.5"
              color="success.700"
              variant="smallBody"
            >
              Your school profile has been verified from our side, one last step
              for you before you go live.
            </Typography>
          </div>
          <Button
            color="success"
            size="sm"
            variant="filled"
            onClick={() =>
              navigate(`${routes.profileVerification}?openProfile=true`)
            }
          >
            Review Changes to go Live
          </Button>
        </div>
      </Container>
    </div>
  )
}

export default SchoolIsVerified
