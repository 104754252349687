import { Typography } from "@suraasa/placebo-ui"
import { Gender, Profile } from "api/resources/teacher/types"
import Female from "assets/profileFemale.png"
import Male from "assets/profileMale.png"
import Neutral from "assets/profileNeutral.png"
import clsx from "clsx"
import Section from "components/Section"
import { TwitterVerifiedBadge } from "iconoir-react"
import { createUseStyles } from "react-jss"
import { DEFAULT_PROFILE_COVER } from "utils/constants"
import { buildUserName } from "utils/helpers"

const AVATAR_SIZE = { lg: 142, sm: 91 }

const useStyles = createUseStyles(theme => ({
  shareButton: {
    borderColor: theme.colors.onSurface[400],
    color: theme.colors.onSurface[800],
  },

  displayPictureAndFilter: {
    transform: "translateY(-60%)",
    height: AVATAR_SIZE.lg,
    width: AVATAR_SIZE.lg,
    left: theme.spacing(3),
    position: "absolute",
    [theme.breakpoints.down("xs")]: {
      transform: "translateY(calc(-60% - 40px))",
      height: AVATAR_SIZE.sm,
      width: AVATAR_SIZE.sm,
      left: theme.spacing(2),
    },
  },

  displayPicture: {
    objectFit: "cover",
    borderRadius: "50%",
    border: `3px solid ${theme.colors.common.white[500]}`,
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
  },

  verifiedBadge: {
    display: "inline-flex",
    color: theme.colors.primary[500],
    background: theme.colors.primary[50],
    padding: theme.spacing(1 / 2),
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.spacing(1 / 2),
    "& svg": {
      fill: theme.colors.primary[500],
    },
    [theme.breakpoints.down("xs")]: {
      background: "transparent",
      padding: theme.spacing(1 / 2, 0),
      "& span": {
        display: "none",
      },
    },
  },

  title: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      /** Edit profile button */
      "& button": {
        position: "absolute",
        right: theme.spacing(12 / 8),
        top: theme.spacing(12 / 8),
      },
    },
  },
  titleAndBadge: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gap: theme.spacing(1 / 2),
    },
  },

  position: {
    marginBottom: theme.spacing(1 / 2),
  },
  locations: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(6 / 8),
    [theme.breakpoints.down("xs")]: {
      gap: theme.spacing(1),
    },
    marginTop: theme.spacing(1),
  },

  cover: {
    height: 184,
    minWidth: "100%",
    backgroundColor: theme.colors.onSurface[300],
    backgroundImage: `url('${DEFAULT_PROFILE_COVER}')`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    borderRadius: theme.spacing(1, 1, 0, 0),

    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-end",
    paddingLeft: `calc(${AVATAR_SIZE.lg}px + 24px + 16px)`,
    paddingBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(3),

    [theme.breakpoints.down("sm")]: {
      borderRadius: theme.spacing(0),
    },

    [theme.breakpoints.down("xs")]: {
      height: 108,
      paddingLeft: 0,
      paddingRight: theme.spacing(2),
      /** Hide Video Portfolio button from cover */
      "& button:nth-child(1),button:nth-child(2)": {
        display: "none",
      },
    },
  },

  content: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    padding: theme.spacing(2, 3),
    paddingLeft: `calc(${AVATAR_SIZE.lg}px + 24px + 16px)`,
    minHeight: "calc(284.1px - 175px)",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      padding: theme.spacing(2),
      paddingTop: theme.spacing(5),
    },
  },
}))

function getGenderImage(gender: Gender | null): string {
  if (!gender) return Neutral

  if (Gender.MALE === gender) {
    return Male
  }
  if (Gender.FEMALE === gender) {
    return Female
  }

  return Neutral
}

const Overview = ({ data }: { data: Profile }) => {
  const classes = useStyles()

  const { user, isVerified, currentWorkingExperience, picture, gender } = data

  return (
    <>
      <Section className="sm:mt-4">
        <header>
          <div className={classes.cover} />
          <div className={classes.content}>
            <img
              alt="avatar"
              className={clsx(
                classes.displayPicture,
                classes.displayPictureAndFilter,
                "transition-[border]"
              )}
              src={picture || getGenderImage(gender)}
            />

            <div className="flex justify-between" style={{ width: "100%" }}>
              <div>
                <div className={classes.title}>
                  <div className={classes.titleAndBadge}>
                    <Typography
                      component="h1"
                      display="inline"
                      variant="title3"
                    >
                      {buildUserName(user)}
                    </Typography>
                    {isVerified && (
                      <span className={classes.verifiedBadge}>
                        <TwitterVerifiedBadge
                          color="white"
                          height="22"
                          width="22"
                        />
                        <span>Verified</span>
                      </span>
                    )}
                  </div>
                </div>

                {currentWorkingExperience && (
                  <>
                    <Typography
                      className={classes.position}
                      color="onSurface.500"
                      variant="smallBody"
                    >
                      {currentWorkingExperience.title}
                    </Typography>

                    <Typography color="onSurface.500" variant="smallBody">
                      {currentWorkingExperience.organisationName}
                    </Typography>
                  </>
                )}
              </div>
              <div>
                {/* <Button
                  onClick={() => setOpenShare(true)}
                  variant="text"
                  endAdornment={<ShareIos />}
                >
                  Share
                </Button> */}
              </div>
            </div>
          </div>
        </header>
      </Section>
    </>
  )
}

export default Overview
