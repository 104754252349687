import {
  Button,
  CircularProgress,
  theme,
  Tooltip,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  ApplicationStatus,
  InvitationStatus,
  Job,
  JobApplicant,
} from "api/resources/jobs/types"
import {
  Award,
  Certification,
  Interest,
  LanguageResponse,
  Profile as ProfileType,
  Project,
  Publication,
  Qualification,
  SkillEvidencesOverview,
  SubjectResponse,
  TestScore,
  WorkExperienceType,
  WorkLocationInterest,
} from "api/resources/teacher/types"
import InviteToJobDialog from "components/explore/InviteToJobDialog"
import AcceptApplicationDialog from "components/jobs/AcceptApplicationDialog"
import ReactHelmet from "components/shared/ReactHelmet"
import ShareSheet from "components/shared/ShareSheet"
import RejectApplicationDialog from "components/teacher/Dialogs/RejectApplicationDialog"
import Overview from "components/teacher/Overview"
import About from "components/teacher/profile/About"
import Academics from "components/teacher/profile/Academics"
import Achievements from "components/teacher/profile/Achievements"
import Interests from "components/teacher/profile/Interests"
import Languages from "components/teacher/profile/Languages"
import PersonalInfo from "components/teacher/profile/PersonalInfo"
import Subjects from "components/teacher/profile/Subjects"
import WorkExperience from "components/teacher/profile/WorkExperience"
import WorkLocationInterests from "components/teacher/profile/WorkLocationInterests"
import { Cancel, Check, ShareAndroid } from "iconoir-react"
import React, { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { getAuthInfo } from "utils/auth"
import useArray from "utils/hooks/useArray"
import toast from "utils/toast"
import ProfileContext from "views/teacher/profileContext"

import SkillProfileOverview from "./SkillProfileOverview"

const Profile = ({ skipOverview = false }) => {
  const [loading, setLoading] = useState(true)

  const { username } = useParams()
  const [searchParams] = useSearchParams()
  const jobApplicantId = searchParams.get("jobApplicantId")

  const [jobApplicant, setJobApplicant] = useState<JobApplicant>()
  const [job, setJob] = useState<Job>()

  const [profile, setProfile] = useState<ProfileType>({
    id: "",
    careerAspiration: null,
    countriesWorkedIn: [],
    country: null,
    currentWorkingExperience: null,
    dateOfBirth: null,
    gender: null,
    isVerified: false,
    lookingForJobs: false,
    nationality: null,
    phoneNumber: null,
    picture: null,
    state: null,
    user: {
      uuid: "",
      email: "",
      firstName: "",
      lastName: "",
      middleName: "",
      username: username ?? "",
    },
  })

  const workExperiences = useArray<Partial<WorkExperienceType>>([])
  const languages = useArray<Partial<LanguageResponse>>([])
  const awards = useArray<Partial<Award>>([])
  const projects = useArray<Partial<Project>>([])
  const publications = useArray<Partial<Publication>>([])
  const testScores = useArray<Partial<TestScore>>([])
  const subjects = useArray<Partial<SubjectResponse>>([])
  const qualifications = useArray<Partial<Qualification>>([])
  const certifications = useArray<Partial<Certification>>([])
  const workLocationInterest = useArray<Partial<WorkLocationInterest>>([])
  const interests = useArray<Partial<Interest>>([])
  const [skillEvidences, setSkillEvidences] = useState<SkillEvidencesOverview>({
    advancedSkillCount: 0,
    beginnerSkillCount: 0,
    intermediateSkillCount: 0,
    professional: 0,
  })

  const [inviteToJobDialogOpen, setInviteToJobDialogOpen] = useState(false)
  const [shareSheetOpen, setShareSheetOpen] = useState(false)

  const [dialogLoading, setDialogLoading] = useState(false)
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false)
  const [acceptDialogOpen, setAcceptDialogOpen] = useState(false)

  const handleAction = async (status: ApplicationStatus) => {
    setDialogLoading(true)

    const res = await api.jobs.jobApplicant.update({
      urlParams: {
        jobApplicantId,
      },
      data: {
        applicationStatus: status,
      },
    })
    if (res.isSuccessful) {
      setAcceptDialogOpen(false)

      toast.success("Application accepted")
      setJobApplicant(s => ({ ...s, ...res.data }))
    } else {
      toast.error(res.errors.message)
    }
    setDialogLoading(false)
  }

  useEffect(() => {
    const retrieveJobApplicant = async () => {
      if (!jobApplicantId) return

      const res = await api.jobs.jobApplicant.retrieve({
        urlParams: {
          jobApplicantId,
        },
        params: {
          fields: ["application_status", "invitation_status"],
        },
      })

      if (res.isSuccessful) {
        setJobApplicant(res.data)
        const jobRes = await api.jobs.retrieve({
          urlParams: { jobId: res.data.job },
        })
        if (jobRes.isSuccessful) {
          setJob(jobRes.data)
        }
      }
    }

    retrieveJobApplicant()
  }, [jobApplicantId])

  useEffect(() => {
    const getData = async () => {
      setLoading(true)

      const profileRes = await api.teacher.profile.retrieve({
        urlParams: {
          username: username ?? null,
        },
      })
      if (profileRes.isSuccessful) {
        setProfile(profileRes.data)
        // trackProfileView(
        //   `${profileRes.data.user.firstName}${
        //     profileRes.data.user.lastName
        //       ? ` ${profileRes.data.user.lastName}`
        //       : ""
        //   }`
        // )
      }

      const promises = [
        api.teacher.listAcademics({
          urlParams: { username: username ?? null },
        }),
        api.teacher.workExperiences.list({
          urlParams: { username: username ?? null },
        }),
        api.teacher.listAchievements({
          urlParams: { username: username ?? null },
        }),
        api.teacher.languages.list({
          urlParams: { username: username ?? null },
        }),
        api.teacher.interests.list({
          urlParams: { username: username ?? null },
        }),
        api.teacher.skillEvidences.overview({
          urlParams: { username: username ?? null },
        }),
      ] as const

      const [
        academicsRes,
        workExperiencesRes,
        achievementsRes,
        languagesRes,
        interestsRes,
        skillEvidencesRes,
      ] = await Promise.all(promises)

      if (workExperiencesRes.isSuccessful) {
        workExperiences.set(workExperiencesRes.data)
      }

      if (languagesRes.isSuccessful) languages.set(languagesRes.data)

      if (achievementsRes.isSuccessful) {
        awards.set(achievementsRes.data.awards)
        publications.set(achievementsRes.data.publications)
        projects.set(achievementsRes.data.projects)
        testScores.set(achievementsRes.data.testScores)
      }

      if (academicsRes.isSuccessful) {
        qualifications.set(academicsRes.data.qualifications)
        certifications.set(academicsRes.data.certifications)
      }

      if (interestsRes.isSuccessful) {
        workLocationInterest.set(interestsRes.data.preferredWorkLocations)
        subjects.set(interestsRes.data.subjects)
        interests.set(interestsRes.data.interests)
      }

      if (skillEvidencesRes.isSuccessful) {
        setSkillEvidences(skillEvidencesRes.data)
      }

      setLoading(false)
    }
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAction = () => {
    if (
      !jobApplicant ||
      jobApplicant.invitationStatus === InvitationStatus.REJECTED
    )
      return (
        <Button size="sm" onClick={() => setInviteToJobDialogOpen(true)}>
          Invite
        </Button>
      )

    if (jobApplicant?.invitationStatus) {
      if (jobApplicant.invitationStatus === InvitationStatus.PENDING)
        return (
          <div className="flex items-center gap-0.5">
            <Check color={theme.colors.success[500]} />
            <Typography color="success.500">Invited</Typography>
          </div>
        )

      if (jobApplicant.invitationStatus === InvitationStatus.ACCEPTED)
        return (
          <div className="flex items-center gap-0.5">
            <Check color={theme.colors.success[500]} />
            <Typography color="success.500">Accepted</Typography>
          </div>
        )
    }

    if (jobApplicant.applicationStatus) {
      if (jobApplicant.applicationStatus === ApplicationStatus.ACCEPTED)
        return (
          <div className="flex items-center gap-0.5">
            <Check color={theme.colors.success[500]} />
            <Typography color="success.500">Accepted</Typography>
          </div>
        )
      if (jobApplicant.applicationStatus === ApplicationStatus.REJECTED)
        return (
          <div className="flex items-center gap-0.5">
            <Cancel color={theme.colors.critical[500]} />
            <Typography color="critical.500">Rejected</Typography>
          </div>
        )
      if (jobApplicant.applicationStatus === ApplicationStatus.PENDING)
        return (
          <div>
            <Button
              className="mr-2"
              color="critical"
              variant="text"
              onClick={() => {
                setRejectDialogOpen(true)
              }}
            >
              Reject
            </Button>
            <Button
              size="sm"
              variant="outlined"
              onClick={() => {
                setAcceptDialogOpen(true)
              }}
            >
              Accept
            </Button>
          </div>
        )
    }
    return null
  }

  const fullName = [profile.user.firstName, profile.user.lastName]
    .filter(Boolean)
    .join(" ")

  return (
    <>
      <ReactHelmet
        data={{
          title: fullName ?? "Teacher Profile",
          description: "Teacher Profile",
          url: window.location.href,
          coverImage: "https://hire.suraasa.com/assets/suraasa-meta-img.jpg",
        }}
      />

      <ShareSheet
        data={{
          url: `${process.env.REACT_APP_SSO_URL}/profile/${profile.user.username}`,
          text: "",
        }}
        handleClose={() => setShareSheetOpen(false)}
        open={shareSheetOpen}
        title="Share profile"
      />

      {jobApplicantId && (
        <RejectApplicationDialog
          afterSubmit={item =>
            item ? setJobApplicant(s => ({ ...s, ...item })) : undefined
          }
          jobApplicantId={jobApplicantId}
          jobPosition={job?.position || ""}
          mode="application"
          open={rejectDialogOpen}
          toggle={() => {
            setRejectDialogOpen(false)
          }}
          userName={fullName}
        />
      )}

      {jobApplicantId && (
        <AcceptApplicationDialog
          handleClose={() => {
            setAcceptDialogOpen(false)
          }}
          loading={dialogLoading}
          open={acceptDialogOpen}
          onAccept={() => {
            handleAction(ApplicationStatus.ACCEPTED)
          }}
        >
          Accept <b>{fullName}</b>’s application for&nbsp;
          <b>{job?.position}</b>?
        </AcceptApplicationDialog>
      )}
      <ProfileContext.Provider
        value={{
          profile,
          languages: {
            data: languages.array,
          },
          achievements: {
            awards: {
              data: awards.array,
            },
            projects: {
              data: projects.array,
            },
            publications: {
              data: publications.array,
            },
            testScores: {
              data: testScores.array,
            },
          },
          workExperiences: {
            data: workExperiences.array,
          },
          academics: {
            qualifications: {
              data: qualifications.array,
            },
            certifications: {
              data: certifications.array,
            },
          },
          subjects: {
            data: subjects.array,
          },
          workLocationInterest: {
            data: workLocationInterest.array,
          },
          interests: {
            data: interests.array,
          },
          skillEvidences,
        }}
      >
        {loading ? (
          <div className="flex justify-center my-3">
            <CircularProgress />
          </div>
        ) : (
          <>
            {!skipOverview && (
              <div className="grid mb-2">
                <Overview
                  {...profile}
                  actions={
                    getAuthInfo() ? (
                      <div className="flex items-center">
                        {getAction()}
                        <Button
                          className="ml-2"
                          startAdornment={<ShareAndroid />}
                          variant="text"
                          onClick={() => setShareSheetOpen(true)}
                        >
                          Share
                        </Button>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <span>
                          <Tooltip
                            offsetY={10}
                            title="Please sign in to unlock this feature"
                          >
                            <span>
                              <Button
                                size="sm"
                                disabled
                                onClick={() => setInviteToJobDialogOpen(true)}
                              >
                                Invite
                              </Button>
                            </span>
                          </Tooltip>
                        </span>
                        <Button
                          className="ml-2"
                          startAdornment={<ShareAndroid />}
                          variant="text"
                          onClick={() => setShareSheetOpen(true)}
                        >
                          Share
                        </Button>
                      </div>
                    )
                  }
                />

                <InviteToJobDialog
                  open={inviteToJobDialogOpen}
                  teacherId={profile.user.uuid}
                  onRequestClose={() => {
                    setInviteToJobDialogOpen(false)
                  }}
                />
              </div>
            )}
            <div className="grid grid-cols-12 mb-4 sm:gap-2 md:gap-3">
              <div className="flex flex-col col-span-12 gap-2 sm:col-span-7 md:col-span-8">
                <About />
                <Academics />
                <WorkExperience />
                <Achievements />
              </div>

              <div className="flex flex-col col-span-12 gap-2 sm:col-span-5 md:col-span-4">
                <PersonalInfo />
                <SkillProfileOverview />
                <Languages />
                <Subjects />
                <WorkLocationInterests />
                <Interests />
              </div>
            </div>
          </>
        )}
      </ProfileContext.Provider>
    </>
  )
}

export default Profile
