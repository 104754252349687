import { Profile } from "api/resources/profile/types"
import { ErrorResponse } from "api/types"
import { FieldValues, UseFormSetError } from "react-hook-form"
import { NavigateFunction } from "react-router-dom"

import { getAuthInfo } from "./auth"
import { acceptedImageTypes, NotificationAction } from "./constants"
import { routes } from "./routes"
import toast from "./toast"

export const handleErrors = <T extends FieldValues>(
  setter: UseFormSetError<T>,
  { fieldErrors, message }: ErrorResponse["errors"]
) => {
  if (message) {
    toast.error(message)
    return
  }

  if (fieldErrors) {
    for (const [k, v] of Object.entries(fieldErrors)) {
      if (v) setter(k as any, { message: v })
    }
  }
}

export const buildParams = (rawParams: {
  [key: string]: string | string[]
}) => {
  const params = new URLSearchParams()

  for (const [key, value] of Object.entries(rawParams)) {
    if (!value) continue

    if (Array.isArray(value)) {
      value.forEach(v => params.append(key, v))
    } else params.append(key, value)
  }

  return params
}

export function pluralize(
  word: string,
  count: number,
  {
    endsWithVowel,
    skipCount,
    plural,
  }: {
    endsWithVowel?: boolean
    skipCount?: boolean
    plural?: string
  } = {
    endsWithVowel: false,
    skipCount: false,
    plural: "",
  }
) {
  let str = `${count} `

  if (skipCount) {
    str = ""
  }

  if (plural) {
    return `${count !== 1 ? `${str}${plural}` : `${str}${word}`}`
  }

  return `${
    count !== 1 ? `${str}${word}${endsWithVowel ? "es" : "s"}` : `${str}${word}`
  }`
}

export function toDateTimeLocal(
  iso: string,
  { omitSeconds } = { omitSeconds: false }
) {
  const date = new Date(iso)

  const YYYY = date.getFullYear()
  const MM = (date.getMonth() + 1).toString().padStart(2, "0")
  const DD = date.getDate().toString().padStart(2, "0")
  const HH = date.getHours().toString().padStart(2, "0")
  const II = date.getMinutes().toString().padStart(2, "0")
  const SS = date.getSeconds().toString().padStart(2, "0")
  if (omitSeconds) {
    return `${YYYY}-${MM}-${DD}T${HH}:${II}`
  }
  return `${YYYY}-${MM}-${DD}T${HH}:${II}:${SS}`
}
export type ValueOf<T> = T[keyof T]

export const validateImageUpload = (file: File, imageSizeinMB?: number) => {
  if (!file.type.includes("image")) {
    toast.error("Only images are allowed")
    return
  }
  if (file.size / 1024 / 1024 > (imageSizeinMB || 5)) {
    toast.error(`Only images under ${imageSizeinMB || 5} MB are allowed`)
    return
  }

  if (!acceptedImageTypes.includes(file.type)) {
    toast.error(
      "Invalid Image Type. Only jpg, jpeg, png & webp format are allowed"
    )
    return
  }

  return file
}

export const formatWorkDuration = (days: number) => {
  if (days < 365) {
    const months = Math.floor(days / 30)
    return pluralize("month", months)
  }

  const eightMonthsInYears = 8 / 12

  const years = days / 365
  const threshold = Math.trunc(years) + eightMonthsInYears

  if (years > threshold) return `${Math.trunc(years)}+ years`

  return `${pluralize("year", Math.floor(years))}`
}

export const generateHuddleURL = (config: {
  meetingUrl: string
  interviewId: number
  displayName?: string
}) => {
  const authInfo = getAuthInfo()
  if (!authInfo) return ""

  const meetingURL = new URL(config.meetingUrl)
  meetingURL.searchParams.append(
    "displayName",
    `${authInfo.user.firstName} ${authInfo.user.lastName}`
  )

  const searchParams = new URLSearchParams()

  searchParams.append("meeting-link", encodeURIComponent(meetingURL.href))
  searchParams.append("interview-id", String(config.interviewId))

  return `${routes.teacherInterview}?${searchParams.toString()}`
}

export const saveBlobAsFile = ({
  data,
  type,
  name,
}: {
  data: any
  type: string
  name: string
}) => {
  const blob = new Blob([data], { type })
  const blobData = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = blobData
  link.download = name
  link.click()
  setTimeout(() => {
    window.URL.revokeObjectURL(blobData)
  }, 100)
}

export function convertHexToRGBA(hex: string, opacity: number) {
  const tempHex: string = hex.replace("#", "")

  const r = parseInt(tempHex.substring(0, 2), 16)
  const g = parseInt(tempHex.substring(2, 4), 16)
  const b = parseInt(tempHex.substring(4, 6), 16)

  return `rgba(${r},${g},${b},${opacity / 100})`
}

export const getPlatformURL = (platform: "sso" | "learn", url: string) => {
  if (platform === "sso") {
    return `${process.env.REACT_APP_SSO_URL}/${url}`
  }

  return ""
}

export const notificationHelper = (
  navigate: NavigateFunction,
  // eslint-disable-next-line @typescript-eslint/ban-types
  action: NotificationAction | {} | null
) => {
  if (!action) return
  if (!("name" in action)) return

  switch (action.name) {
    case "APPLICATION_RECEIVED":
      if (action.data.jobId) {
        navigate(
          `${routes.jobDetails.replace(
            ":jobId",
            action.data.jobId.toString()
          )}?tab=Applicants`
        )
      }
      return
    case "JOB_OFFER_ACCEPTED":
      if (action.data.username && action.data.jobOfferId) {
        navigate(
          routes.viewOffer
            .replace(":username", action.data.username)
            .replace(":jobOfferId", action.data.jobOfferId.toString())
        )
      }
      break
    case "JOB_OFFER_REJECTED":
      if (action.data.jobPosition && action.data.jobId) {
        navigate(
          `${routes.explore}/?jobPosition=${action.data.jobPosition}&jobId=${action.data.jobId}`
        )
      }
      break
    default:
      break
  }
}

export const getShareJobContent = (
  platform: "linkedin" | "whatsapp" | "facebook" | "telegram",
  {
    profile,
    job,
  }: {
    profile: Profile
    job: { position: string; subject: string; slug: string }
  }
) => {
  const skipEmojis = platform === "facebook"
  const shareURL = `${process.env.REACT_APP_JOBS_PLATFORM_URL}/school/${profile.slug}?jobSlug=${job.slug}`

  const renderEmoji = (emoji: string) => {
    if (skipEmojis) return ""
    return `${emoji} `
  }

  const heading = `${renderEmoji("👋")}Hi, we are hiring a ${job.position} at ${
    profile.name
  }${profile.branch ? ` (${profile.branch})` : ""}.`

  const locationChunks = [
    profile.city ?? "",
    profile.state?.name ?? "",
    profile.country?.name ?? "",
  ]

  const location = locationChunks.join(", ")

  switch (platform) {
    case "whatsapp":
    case "telegram": {
      return `${heading}

${renderEmoji("📖")}Subject: ${job.subject}
${
  locationChunks.filter(Boolean).length
    ? `${renderEmoji("📍")}Location: ${location}`
    : ""
}

Apply Here: ${shareURL}

Know someone who might fit the role? Please refer them to us ${renderEmoji(
        "👍"
      )}`
    }
    case "facebook": {
      return `${heading}

${renderEmoji("📖")}Subject: ${job.subject}
${
  locationChunks.filter(Boolean).length
    ? `${renderEmoji("📍")}Location: ${location}`
    : ""
}

Apply Here: ${shareURL}

Know someone who might fit the role? Please refer them to us ${renderEmoji(
        "👍"
      )}
#hiring #teacherrecruitment #teachingjobs #suraasajobs`
    }
    case "linkedin": {
      return `${heading}

${renderEmoji("📖")}Subject: ${job.subject}
${
  locationChunks.filter(Boolean).length
    ? `${renderEmoji("📍")}Location: ${location}`
    : ""
}
      
Apply Here: ${shareURL}
      
Know someone who might fit the role? Tag them in the comments! ${renderEmoji(
        "👍"
      )}
#hiring #teacherrecruitment #teachingjobs #suraasajobs`
    }
    default:
      return ""
  }
}

export const buildUserName = (user: {
  firstName: string
  lastName: string | null
}) => [user.firstName, user.lastName].filter(Boolean).join(" ")
