import { Button, Typography } from "@suraasa/placebo-ui"
import api from "api"
import LoadingOverlay from "components/shared/LoadingOverlay"
import React, { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom"
import { getAuthInfo, saveAuthInfo } from "utils/auth"
import { USER_TYPE } from "utils/constants"
import { getPlatformURL } from "utils/helpers"
import { routes } from "utils/routes"

const useStyles = createUseStyles({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& #logo": {
      transform: "translateX(26px)",
    },
  },
})

const SSO = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const next = searchParams.get("next")
  const getPlatformToken = async (code: string) => {
    setLoading(true)
    const res = await api.users.generatePlatformToken({
      data: {
        code,
        platform: USER_TYPE,
      },
    })
    if (res.isSuccessful) {
      saveAuthInfo(res.data)
      setLoading(false)
      navigate(next ? next.replace(window.location.origin, "") : routes.home, {
        replace: true,
      })
    } else {
      setLoading(false)
      setError("Something went wrong, please try again!")
    }
  }

  const getCode = () => {
    window.location.href = getPlatformURL(
      "sso",
      `school/login?redirect-url=${window.location.href}`
    )
  }

  useEffect(() => {
    if (getAuthInfo()) {
      setLoading(false)
      navigate(next ? next.replace(window.location.origin, "") : routes.home, {
        replace: true,
      })
      return
    }

    const code = searchParams.get("code")
    if (code) {
      getPlatformToken(code)
    } else {
      getCode()
      setLoading(false)
    }
    /**
     * Should only be called on mount
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) return <LoadingOverlay showLogo />

  return error && !loading ? (
    <div className={classes.root}>
      <div className="mb-1">
        <Typography variant="title3">{error}</Typography>
      </div>
      <Button onClick={getCode}>Click here to try again</Button>
    </div>
  ) : null
}

export default SSO
