import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { ApplicationStatus, JobApplicant } from "api/resources/jobs/types"
import RemoveDialog from "components/RemoveDialog"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { ToggleValue } from "utils/hooks/useToggle"
import toast from "utils/toast"

type Props = {
  toggle: ToggleValue
  afterSubmit?: (data?: JobApplicant) => void
  mode: "application" | "invitation" | "activeApplication"
  jobPosition: string
  userName: string
  /**
   * jobApplicant is needed only
   * in the case where we need to add interview
   * Note: This can be either string or number
   * if it comes from backend --> number
   * if it comes from url params --> string
   */
  jobApplicantId: string | number
} & Pick<DialogProps, "open">

const rejectionReasons = [
  { key: "0", display: "Teacher's skills not up to the mark." },
  { key: "1", display: "Teacher is demanding more salary" },
  { key: "2", display: "Teacher does not have relevant experience" },
  { key: "3", display: "Communication skills not up to the mark" },
  { key: "4", display: "Teacher got a job somewhere else" },
  { key: "5", display: "This is a fake profile" },
  { key: "6", display: "Other Reasons" },
] as const

type Form = {
  "0": boolean
  "1": boolean
  "2": boolean
  "3": boolean
  "4": boolean
  "5": boolean
  "6": boolean
  feedback: string
}

const getConfig = (
  mode: Props["mode"],
  { userName, jobPosition }: Pick<Props, "userName" | "jobPosition">
) => {
  switch (mode) {
    case "activeApplication":
    case "application":
      return {
        buttonText: "Reject",
        title: "Reject Application",
        subtitle:
          "Please tell us the reason for rejecting this Job Application",
        body: (
          <>
            Are you sure you want to reject <b>{userName}'s</b> application for
            &nbsp;<b>{jobPosition}</b>?
          </>
        ),
      }
    case "invitation":
      return {
        buttonText: "Revoke",
        title: "Revoke Invite",
        subtitle: "Please tell us the reason for revoking this invite",

        body: (
          <>
            Are you sure you want to revoke <b>{userName}'s</b> invite for&nbsp;
            <b>{jobPosition}</b>?
          </>
        ),
      }

    default:
      return {
        buttonText: "",
        title: "",
        subtitle: "",
        body: "",
      }
  }
}

const RejectApplicationDialog = ({
  open,
  jobApplicantId,
  afterSubmit,
  toggle,
  mode,
  userName,
  jobPosition,
}: Props) => {
  const [confirmed, setConfirmed] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting },
  } = useForm<Form>()

  const otherReasons = watch("6")

  const { title, subtitle, buttonText, body } = getConfig(mode, {
    userName,
    jobPosition,
  })

  const handleInvitationReject = async () => {
    const res = await api.jobs.jobApplicant.delete({
      urlParams: {
        jobApplicantId,
      },
    })

    if (res.isSuccessful) {
      toast.success("Invite revoked")
      if (afterSubmit) {
        afterSubmit()
      }
      toggle()
    } else {
      toast.error(res.errors.message)
    }
  }

  const onSubmit = handleSubmit(async formData => {
    if (mode === "invitation") {
      handleInvitationReject()
      return
    }

    const reasons = []

    for (const [key, value] of Object.entries(formData)) {
      if (value) {
        if (key === "6") continue

        if (key === "feedback") {
          if (otherReasons)
            reasons.push({ reason: formData.feedback, isOther: true })
        } else
          reasons.push({
            reason: rejectionReasons.find(r => r.key === key)?.display,
            isOther: false,
          })
      }
    }

    const res = await api.jobs.jobApplicant[
      mode === "activeApplication" ? "rejectActiveApplicant" : "update"
    ]({
      urlParams: {
        jobApplicantId,
      },
      data: {
        applicationStatus: ApplicationStatus.REJECTED,
        rejectionReason: reasons,
      },
    })

    if (res.isSuccessful) {
      toast.success("Application rejected")
      if (afterSubmit) {
        afterSubmit(res.data)
      }
      toggle()
    } else {
      toast.error(res.errors.message)
    }
  })

  return (
    <>
      <RemoveDialog
        actionLabel={buttonText}
        handleClose={toggle}
        open={open && !confirmed}
        title={title}
        onAfterClose={() => reset({})}
        onRemove={() => {
          setConfirmed(true)
        }}
      >
        <Typography variant="smallBody">{body}</Typography>
      </RemoveDialog>

      <Dialog
        open={open && confirmed}
        width="md"
        onAfterClose={() => reset({})}
        onRequestClose={() => toggle()}
      >
        <DialogTitle
          onBack={() => {
            setConfirmed(false)
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <Typography className="mt-2 mb-2.5" variant="strong">
            {subtitle}
          </Typography>
          <form className="flex flex-col gap-2" onSubmit={onSubmit}>
            {rejectionReasons.map(({ key, display }) => (
              <Checkbox {...register(key)} key={key} label={display} />
            ))}
            {/* @ts-expect-error placebo-issue */}
            <TextField
              className="mb-1.5"
              disabled={!otherReasons}
              rows={2}
              fullWidth
              multiLine
              {...register("feedback")}
              placeholder="Type your reason here"
            />
          </form>
        </DialogContent>
        <DialogFooter
          actions={{
            primary: {
              label: buttonText,
              color: "critical",
              onClick: onSubmit,
              loading: isSubmitting,
            },
          }}
        />
      </Dialog>
    </>
  )
}

export default RejectApplicationDialog
