import axios from "api/axios"
import { APIResponse, Config, NoContentType } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { OTPToken, User } from "../profile/types"
import { AuthData, UserJobInfo } from "./types"
import { urls } from "./urls"

export default {
  generatePlatformToken: async ({
    data,
  }: Config): Promise<APIResponse<AuthData>> => {
    try {
      const res = await axios.post(urls.generatePlatformToken(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  retrieveUser: async (): Promise<APIResponse<User>> => {
    try {
      const res = await axios.get(urls.user())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  retrieveUserJobInfo: async ({
    params,
    data,
  }: Config): Promise<APIResponse<UserJobInfo[]>> => {
    try {
      const res = await axios.post(urls.userJobInfo(), data, { params })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  changePassword: async ({
    data,
  }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(urls.changePassword(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  changePhoneNumber: async ({ data }: Config): Promise<APIResponse<User>> => {
    try {
      const res = await axios.put(urls.user(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  genOTP: async ({ data }: Config): Promise<APIResponse<OTPToken>> => {
    try {
      const res = await axios.post(urls.generateOTP(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  changeEmailID: async ({ data }: Config): Promise<APIResponse<User>> => {
    try {
      const res = await axios.put(urls.changeEmailId(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
