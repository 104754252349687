// import { useNotifications } from "@suraasa/notifications"
import api from "api"
import { GlobalContext } from "components/GlobalState"
import LoadingOverlay from "components/shared/LoadingOverlay"
import { useContext, useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { getAuthInfo, saveAuthInfo, setSchoolId } from "utils/auth"
import { routes } from "utils/routes"

const Dashboard = () => {
  const {
    setCanAccessPortal,
    loading,
    setLoading,
    setIsVerified,
    setIsSalesVerified,
    setProfile,
  } = useContext(GlobalContext)

  const navigate = useNavigate()
  const authInfo = getAuthInfo()

  // const [notifications] = useNotifications<NotificationAction>({
  //   axiosInstance: axios,
  // })

  // useEffect(() => {
  //   setNotifications(notifications)
  // }, [setNotifications, notifications])

  const getProfilePicture = async () => {
    const res = await api.profile.retrieve({
      params: {
        fields: "curriculum_board",
      },
    })
    if (res.isSuccessful) {
      setProfile(res.data)

      if (authInfo) {
        saveAuthInfo(authInfo)
      }
    }
  }

  useEffect(() => {
    const getOverview = async () => {
      setLoading(true)
      const res = await api.school.listAccessibleSchools()

      if (res.isSuccessful) {
        if (res.data.length > 0) {
          const { id, isSalesVerified, isVerified } = res.data[0]
          setSchoolId(id)
          setCanAccessPortal(true)
          setIsVerified(isVerified)
          setIsSalesVerified(isSalesVerified)
          getProfilePicture()
        } else {
          navigate(routes.noSchoolLinked)
          return
        }
        // If this API fails due to invalid access token then we don't need to turn off loading. We just wait for refreshToken to execute,
        // which in turn will automatically reload the page
      } else {
        if (res.statusCode === 401) return
        navigate(routes.noSchoolLinked)
        return
      }
      setLoading(false)
    }
    if (authInfo) {
      getOverview()
    } else {
      setLoading(false)
    }
    /**
     * If we include navigate in the deps then this function will run on every route change. Which is undesirable
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) return <LoadingOverlay showLogo />

  return <Outlet />
}

export default Dashboard
