import { Button, Typography } from "@suraasa/placebo-ui"
import ItemList from "components/ItemList"
import ItemRow from "components/ItemRow"
import TruncatedText from "components/TruncatedText"
import React from "react"

import {
  SkillProfileRoutes,
  useGenerateURL,
} from "../../hooks/useSkillProfileNavigation"

type Props = {
  data: {
    submission: string
    title: string
    uuid: string
    visibility?: boolean | undefined
  }[]
  isHiddenEvidence?: boolean
  canView: boolean
}

const EvidenceList = ({ data, isHiddenEvidence, canView }: Props) => {
  const { navigate: customNavigation } = useGenerateURL()

  const handleGotoDetails = (slug: string) =>
    customNavigation(SkillProfileRoutes.EVIDENCE_DETAILS, slug)

  if (data.length === 0) {
    if (isHiddenEvidence) return null

    return (
      <Typography variant="largeBody">No skill evidences available</Typography>
    )
  }

  return (
    <>
      {isHiddenEvidence && (
        <Typography className="mb-2" color="onSurface.800" variant="title3">
          Hidden Evidences
        </Typography>
      )}
      <ItemList className="mb-2">
        {data.map((item, index) => (
          <ItemRow
            actionSlot={
              canView ? () => <Button variant="text">View</Button> : undefined
            }
            infoSlot={
              <div className="py-1">
                <TruncatedText
                  color={isHiddenEvidence ? "onSurface.500" : undefined}
                  maxLength={70}
                  variant="strong"
                  hideActionButton
                >
                  {item.title}
                </TruncatedText>
              </div>
            }
            key={index}
            onClick={
              canView
                ? () => {
                    handleGotoDetails(item.submission)
                  }
                : undefined
            }
          />
        ))}
      </ItemList>
    </>
  )
}

export default EvidenceList
