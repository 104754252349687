import axios from "api/axios"
import { APIResponse, Config, URLParams } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { Amenity, GalleryItem, ManagementMessage, Perk, Profile } from "./types"
import { urls } from "./urls"

export default {
  retrieve: async ({ params }: Config): Promise<APIResponse<Profile>> => {
    try {
      const res = await axios.get(urls.profile.retrieve(), {
        params,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  update: async ({ data }: Config): Promise<APIResponse<Profile>> => {
    try {
      const res = await axios.put(urls.profile.update(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  managementMessage: {
    update: async ({
      data,
      headers,
    }: Config): Promise<APIResponse<ManagementMessage>> => {
      try {
        const res = await axios.post(urls.managementMessage.base(), data, {
          headers,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    delete: async (): Promise<APIResponse<ManagementMessage>> => {
      try {
        const res = await axios.delete(urls.managementMessage.base())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  perks: {
    list: async (): Promise<APIResponse<Perk[]>> => {
      try {
        const res = await axios.get(urls.perks.list())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    update: async ({ data }: Config): Promise<APIResponse<{ perk: Perk }>> => {
      try {
        const res = await axios.post(urls.perks.update(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    delete: async ({
      urlParams,
    }: URLParams<"id">): Promise<APIResponse<any>> => {
      try {
        const res = await axios.delete(urls.perks.delete(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  amenities: {
    list: async (): Promise<APIResponse<Amenity[]>> => {
      try {
        const res = await axios.get(urls.amenities.list())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    update: async ({
      data,
    }: Config): Promise<APIResponse<{ amenity: Amenity }>> => {
      try {
        const res = await axios.post(urls.amenities.update(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    delete: async ({
      urlParams,
    }: URLParams<"id">): Promise<APIResponse<any>> => {
      try {
        const res = await axios.delete(urls.amenities.delete(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  gallery: {
    list: async (): Promise<APIResponse<GalleryItem[]>> => {
      try {
        const res = await axios.get(urls.gallery.list())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    delete: async ({
      urlParams,
    }: URLParams<"id">): Promise<APIResponse<any>> => {
      try {
        const res = await axios.delete(urls.gallery.delete(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    update: async ({
      data,
      headers,
    }: Config): Promise<APIResponse<GalleryItem[]>> => {
      try {
        const res = await axios.post(urls.gallery.update(), data, {
          headers,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
}
