import {
  Avatar,
  Button,
  CircularProgress,
  Pagination,
  Tag,
  TagColors,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Job, JobOfferStatus, JobOfferType } from "api/resources/jobs/types"
import { ReactComponent as VerifiedBadge } from "assets/home/verifiedBadge.svg"
import clsx from "clsx"
import { format } from "date-fns"
import React, { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"
import { routes } from "utils/routes"

import NoDataCard from "./NoDataCard"

const useStyles = createUseStyles(theme => ({
  container: {
    overflowX: "auto",
  },

  tableContainer: {
    background: "white",
    borderRadius: "4px",
    border: `1px solid ${theme.colors.surface[200]}`,
    minWidth: "800px",
  },

  tableGrid: {
    display: "grid",
    gridTemplateColumns: "70px 1.5fr 1fr 1fr 1fr",
  },

  tableItem: {
    borderTop: `1px solid ${theme.colors.surface[200]}`,
    position: "relative",
  },

  image: {
    width: "32px",
    height: "32px",
  },

  textSmall: {
    fontSize: "12px",
    lineHeight: "15.6px",
  },
}))

const getTag = (
  status: JobOfferStatus
): {
  color: TagColors
  label: string
} => {
  switch (status) {
    case JobOfferStatus.ACCEPTED:
      return { color: "success", label: "Accepted" }
    case JobOfferStatus.REJECTED:
      return { color: "critical", label: "Rejected" }
    case JobOfferStatus.SENT:
      return { color: "secondary", label: "Pending" }
    default:
      return { color: "secondary", label: "" }
  }
}

type Props = {
  job: Pick<Job, "position" | "id">
}

const JobOffersTab = ({ job }: Props) => {
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [jobOffers, setJobOffers] = useState<JobOfferType[]>([])

  const classes = useStyles()

  useEffect(() => {
    const fetchData = async () => {
      const jobOfferRes = await api.jobs.jobOffer.list({
        params: {
          size: 10,
          page,
          job: job.id,
          fields: ["user", "date_created", "is_viewed"],
        },
      })

      if (jobOfferRes.isSuccessful) {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { data, total } = jobOfferRes.data
        setTotal(total)
        setJobOffers(data)
      }
      setLoading(false)
    }
    if (job.id) fetchData()
  }, [job.id, page])

  return loading ? (
    <div className="flex items-center justify-center mt-5">
      <CircularProgress />
    </div>
  ) : (
    <div>
      <div className={clsx(classes.container, "my-3")}>
        {jobOffers.length > 0 ? (
          <div className={classes.tableContainer}>
            <div className={clsx(classes.tableGrid, "py-2")}>
              <div />
              <Typography variant="strongSmallBody">Name</Typography>

              <Typography variant="strongSmallBody">Status</Typography>
              <Typography variant="strongSmallBody">Offer Sent Date</Typography>
            </div>

            {jobOffers.map((item, index) => {
              const tag = getTag(item.status)
              return (
                <div
                  className={clsx(classes.tableGrid, classes.tableItem, "py-2")}
                  key={index}
                >
                  <div className="pl-3">
                    <Avatar
                      name={item.user.fullName}
                      size="xs"
                      src={item.user.profile.picture ?? undefined}
                    />
                  </div>

                  <div>
                    <div className="flex items-center gap-0.5">
                      <Button
                        className="-ml-0.75"
                        component={Link}
                        to={routes.teacherTimeline
                          .replace(":jobApplicantId", String(item.id))
                          .replace(":username", item.user.username)}
                        variant="link"
                      >
                        {item.user.fullName}
                      </Button>
                      {item.user.profile.isVerified && <VerifiedBadge />}
                    </div>

                    <div className="flex items-center">
                      <Typography
                        className={classes.textSmall}
                        color="onSurface.500"
                      >
                        {item.user.email}
                      </Typography>
                    </div>

                    {item.user.profile.phoneNumber && (
                      <Typography
                        className={classes.textSmall}
                        color="onSurface.500"
                      >
                        +{item.user.profile.phoneNumber.code}
                        {item.user.profile.phoneNumber.number}
                      </Typography>
                    )}
                  </div>

                  <div className="flex flex-col justify-center">
                    <Tag color={tag.color} label={tag.label} />
                  </div>

                  <div className="flex flex-col justify-center">
                    <Typography variant="smallBody">
                      {format(new Date(item.dateCreated), "do MMM yyyy")}
                    </Typography>
                  </div>

                  <div className="flex flex-col items-end justify-center mr-2">
                    <Button
                      component={Link}
                      to={routes.viewOffer
                        .replace(":username", item.user.username)
                        .replace(":jobOfferId", item.id.toString())}
                      variant="text"
                    >
                      View Offer Letter
                    </Button>
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <NoDataCard message="You haven't sent any job offers yet." />
        )}
      </div>
      {total > 10 && (
        <Pagination page={page} total={total} onChange={setPage} />
      )}
    </div>
  )
}
export default JobOffersTab
