import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  TextField,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  AssessmentDetailsStep,
  AssessmentDetailsType,
} from "api/resources/jobs/types"
import { add } from "date-fns"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { handleErrors, toDateTimeLocal } from "utils/helpers"
import { ToggleValue } from "utils/hooks/useToggle"

type Props = {
  toggle: ToggleValue
  afterSubmit?: (dueDate: string | null) => void
  afterEdit?: (data: AssessmentDetailsStep) => void
  isEditing: boolean
  data: {
    dueDate?: AssessmentDetailsType["dueDate"]
    id: AssessmentDetailsType["id"]
    jobApplicantStepId?: AssessmentDetailsStep["id"]
  }
} & Pick<DialogProps, "open">

const EditAssessmentDueDate = ({
  isEditing,
  open,
  toggle,
  data,
  afterSubmit,
  afterEdit,
}: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<Pick<AssessmentDetailsType, "dueDate">>()

  const isEditable = Boolean(data?.dueDate)

  const onSubmit = handleSubmit(async formData => {
    if (!formData.dueDate) return

    const dueDate = new Date(formData.dueDate).toISOString()

    if (new Date(dueDate) < add(new Date(), { minutes: 1 })) {
      setError("dueDate", {
        message: "Due date must be in the future",
      })
      return
    }

    if (isEditing && data.jobApplicantStepId) {
      const res = await api.jobs.jobApplicantStep.update({
        urlParams: {
          jobApplicantStepId: data.jobApplicantStepId,
        },
        data: {
          dueDate,
        },
      })
      if (res.isSuccessful) {
        toggle()
        if (afterEdit) afterEdit(res.data)
      } else {
        handleErrors(setError, res.errors)
      }
    } else if (afterSubmit) afterSubmit(dueDate)
  })

  useEffect(() => {
    if (data?.dueDate) reset({ dueDate: toDateTimeLocal(data.dueDate) })
  }, [data, reset])

  return (
    <Dialog
      open={open}
      shouldCloseOnOverlayClick={false}
      width="sm"
      onRequestClose={() => toggle()}
    >
      <DialogTitle>{isEditable ? "Change" : "Set"} Deadline</DialogTitle>
      <DialogContent>
        <TextField
          error={Boolean(errors.dueDate)}
          helperText={errors.dueDate?.message}
          {...register("dueDate", {
            required: { value: true, message: "Required" },
          })}
          label="Deadline"
          min={toDateTimeLocal(new Date().toISOString(), {
            omitSeconds: true,
          })}
          type="datetime-local"
          fullWidth
        />
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: isEditable ? "Update" : "Assign Assessment",
            onClick: onSubmit,
          },
        }}
      />
    </Dialog>
  )
}

export default EditAssessmentDueDate
