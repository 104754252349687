import { Button, Typography } from "@suraasa/placebo-ui"
import {
  AssessmentDetailsStep,
  JobApplicantStepStatus,
} from "api/resources/jobs/types"
import TimelineDivider from "components/Timeline/TimelineDivider"
import { format } from "date-fns"
import { ArrowRight, Clock } from "iconoir-react"
import React, { useContext } from "react"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"
import useToggle from "utils/hooks/useToggle"
import { routes } from "utils/routes"
import TeacherTimelineContext from "views/teacher/context"

import EditAssessmentDueDate from "../Dialogs/EditAssessmentDueDate"
import ListItem from "../ListItem"

const useStyles = createUseStyles(theme => ({
  list: {
    [theme.breakpoints.down("xs")]: {
      "& svg": {
        display: "none",
      },
    },
  },
}))

type Props = {
  tag?: React.ReactElement
} & AssessmentDetailsStep

const AssessmentItem = ({
  id,
  status,
  tag,
  dueDate,
  step: { id: assessmentId, title, dateCreated },
}: Props) => {
  const [assessmentDueDateDialog, toggleAssessmentDueDateDialog] =
    useToggle(false)

  const { isRejected, timeline } = useContext(TeacherTimelineContext)
  const classes = useStyles()

  const getAction = () => {
    if (status === JobApplicantStepStatus.COMPLETED) {
      return (
        <div className="flex flex-col -mb-1.75 flex-1">
          <TimelineDivider color="onSurface.200" weight="light" />
          <Button
            className="ml-auto my-0.75"
            color="black"
            component={Link}
            endAdornment={<ArrowRight />}
            to={routes.assessmentResult.replace(
              ":jobApplicantStepId",
              String(id)
            )}
            variant="text"
          >
            View Result
          </Button>
        </div>
      )
    }

    if (status === JobApplicantStepStatus.NOT_STARTED) {
      return (
        <div className="flex flex-wrap justify-end gap-1 items-center">
          <Button
            component={Link}
            disabled={isRejected}
            to={routes.assessmentDetails.replace(
              ":assessmentId",
              String(assessmentId)
            )}
            variant="text"
          >
            Edit Assessment
          </Button>
          <Button
            disabled={isRejected}
            size="sm"
            onClick={() => toggleAssessmentDueDateDialog()}
          >
            Change Deadline
          </Button>
        </div>
      )
    }
  }

  return (
    <>
      <EditAssessmentDueDate
        afterEdit={data => timeline.update(id, data)}
        data={{ dueDate, id: assessmentId, jobApplicantStepId: id }}
        open={assessmentDueDateDialog}
        toggle={toggleAssessmentDueDateDialog}
        isEditing
      />

      <div style={{ width: "100%" }}>
        <div className="flex gap-1 sm:gap-1.5 flex-wrap">
          <Typography color="onSurface.800" variant="strong">
            {title}
          </Typography>
          {tag}
          {dateCreated && (
            <Typography
              className="sm:ml-auto flex-shrink-0"
              color="onSurface.500"
              variant="smallBody"
            >
              Posted on {format(new Date(dateCreated), "do MMM yy")}
            </Typography>
          )}
        </div>
        <Typography color="onSurface.400" variant="smallBody">
          Assessment
        </Typography>
        {dueDate && (
          <ListItem className={classes.list} icon={<Clock />}>
            <div className="flex flex-col sm:block">
              <Typography
                color="onSurface.400"
                display="inline"
                variant="smallBody"
              >
                Due on{" "}
              </Typography>
              <Typography display="inline" variant="strongSmallBody">
                {format(new Date(dueDate), "dd MMMM yyyy, h:mm a")}
              </Typography>
            </div>
          </ListItem>
        )}
        <div className="mt-3">{getAction()}</div>
      </div>
    </>
  )
}
export default AssessmentItem
