import {
  Button,
  CircularProgress,
  Container,
  Divider,
  theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import { Assessment, BackendQuestion } from "api/resources/assessments/types"
import ReadOnlyQuestionCard from "components/assessments/ReadOnlyQuestionCard"
import Navbar from "components/shared/Navbar"
import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { routes } from "utils/routes"

const Result = () => {
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const { assessmentId } = useParams()

  const [loading, setLoading] = useState(true)

  const isXs = useMediaQuery(theme.breakpoints.only("xs"))

  const [assessment, setAssessment] = useState<Assessment | null>(null)
  const [questions, setQuestions] = useState<BackendQuestion[]>([])

  useEffect(() => {
    const retrieveAssessment = async () => {
      if (!assessmentId) return

      setLoading(true)
      const res = await api.assessments.retrieve({
        urlParams: { assessmentId },
      })
      if (res.isSuccessful) {
        const { title, duration, id, questions: apiQuestions } = res.data
        setAssessment({
          id,
          title,
          duration,
          isActive: res.data.isActive,
        })
        setQuestions(apiQuestions)
        setLoading(false)
      }
    }
    retrieveAssessment()
  }, [assessmentId])

  if (!assessment || loading) {
    return (
      <>
        <Navbar />
        <Container>
          <div className="flex items-center justify-center gap-2">
            <CircularProgress />
            <Typography variant="title2">Loading Assessment</Typography>
          </div>
        </Container>
      </>
    )
  }

  return (
    <>
      <Navbar />
      <Container className="mb-3">
        <div className="flex flex-col gap-1 sm:gap-0 sm:flex-row sm:items-center justify-between">
          <Typography variant="title3">{assessment.title}</Typography>
          <div className="flex items-center gap-3.5">
            <Typography>
              {Math.floor(assessment.duration / 60)} Minutes
            </Typography>
            <Button
              component={Link}
              size={isXs ? "sm" : "md"}
              to={routes.assessmentEdit.replace(
                ":assessmentId",
                String(assessment.id)
              )}
              variant="filled"
            >
              Edit Assessment
            </Button>
          </div>
        </div>

        <div className="flex items-center py-3.5">
          <Typography variant="strong">Questions</Typography>
          <div className="pl-2 flex-grow">
            <Divider weight="light" />
          </div>
        </div>
      </Container>

      <Container className="mb-3" fullWidth={xsDown}>
        {questions.map((question, i) => (
          <ReadOnlyQuestionCard
            key={question.id}
            question={question}
            questionNumber={i + 1}
          />
        ))}
      </Container>
    </>
  )
}

export default Result
