import { Button, Container, IconButton, Typography } from "@suraasa/placebo-ui"
import api from "api"
import { Job } from "api/resources/jobs/types"
import clsx from "clsx"
import { GlobalContext } from "components/GlobalState"
import JobOpeningDetailsDialog from "components/jobs/JobOpeningDetailsDialog"
import PreviousApplicationTab from "components/jobs/PreviousApplicationTab"
import ShareJobButton from "components/jobs/ShareJobButton"
import BackButton from "components/shared/BackButton"
import Navbar from "components/shared/Navbar"
import { OpenInWindow, ShareAndroid } from "iconoir-react"
import React, { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { Link, useParams } from "react-router-dom"
import { JobStatus } from "utils/constants"
import useToggle from "utils/hooks/useToggle"

const useStyles = createUseStyles(theme => ({
  root: {
    background: "white",
    borderBottom: `1px solid ${theme.colors.surface[200]}`,
  },
  inviteButton: {
    borderRadius: "4px 0px 0px 4px",
    marginRight: "2px",
  },
  shareButton: {
    borderRadius: "0px 4px 4px 0px",
  },
}))

const Previous = () => {
  const classes = useStyles()

  const { jobId } = useParams()
  const { profile } = useContext(GlobalContext)

  const [jobOpeningDetailsDialog, toggleJobOpeningDetailsDialog] =
    useToggle(false)
  const [job, setJob] = useState<Job | null>(null)

  useEffect(() => {
    const retrieveJob = async () => {
      if (!jobId) return

      const res = await api.jobs.retrieve({
        urlParams: {
          jobId,
        },
      })

      if (res.isSuccessful) {
        setJob(res.data)
      }
    }

    retrieveJob()
  }, [jobId])

  if (!jobId || !job) return null

  return (
    <>
      <JobOpeningDetailsDialog
        data={job}
        open={jobOpeningDetailsDialog}
        toggle={toggleJobOpeningDetailsDialog}
      />
      <Navbar gutterBottom={false} hideBackButton />

      <div className={clsx(classes.root, "pb-1.5")}>
        <Container>
          <BackButton className="my-2" nudge="left" />
          <div>
            <div className="flex justify-between mt-2 mb-4 items-center">
              <div>
                <div className="flex gap-1">
                  <Typography variant="title2">{job.position}</Typography>
                  <IconButton
                    color="black"
                    onClick={() => toggleJobOpeningDetailsDialog()}
                  >
                    <OpenInWindow />
                  </IconButton>
                </div>
                <Typography color="onSurface.500" variant="smallBody">
                  {job.subject.name}
                </Typography>
              </div>
              {job && job.status === JobStatus.CLOSED ? (
                <Button>Reopen Job</Button>
              ) : (
                <div className="flex items-center">
                  <Button
                    className={classes.inviteButton}
                    component={Link}
                    to={`/explore/?jobId=${job.id}&jobPosition=${job.position}`}
                  >
                    Invite Teachers
                  </Button>
                  <ShareJobButton
                    activator={handleOpen => (
                      <IconButton
                        className={classes.shareButton}
                        size="md"
                        variant="filled"
                        onClick={(e: any) => {
                          e.stopPropagation()
                          handleOpen()
                        }}
                      >
                        <ShareAndroid />
                      </IconButton>
                    )}
                    jobSlug={job.slug}
                    position={job.position}
                    schoolSlug={profile?.slug || ""}
                    subject={job.subject.name}
                  />
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
      <Container className="mb-3">
        <Typography className="mt-2.5" variant="title3">
          Previous Applications
        </Typography>
        <PreviousApplicationTab job={job} />
      </Container>
    </>
  )
}
export default Previous
