import axios from "api/axios"
import {
  APIResponse,
  Config,
  ConfigWithURLParams,
  NoContentType,
  PaginatedAPIResponse,
} from "api/types"
import {
  formatErrorResponse,
  formatPaginatedResponse,
  formatSuccessResponse,
} from "api/utils"

import {
  ActiveUser,
  AppliedUser,
  ContactDetails,
  InvitedUser,
  Job,
  JobApplicant,
  JobOffer,
  JobOfferType,
  Overview,
} from "./types"
import { urls } from "./urls"

export default {
  list: async ({ params }: Config): Promise<PaginatedAPIResponse<Job[]>> => {
    try {
      const res = await axios.get(urls.jobs.base(), { params })
      return formatPaginatedResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  retrieve: async ({
    urlParams: { jobId },
  }: ConfigWithURLParams<"jobId">): Promise<APIResponse<Job>> => {
    try {
      const res = await axios.get(urls.jobs.detail(jobId))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  update: async ({
    urlParams: { jobId },
    data,
  }: ConfigWithURLParams<"jobId">): Promise<APIResponse<Job>> => {
    try {
      const res = await axios.put(urls.jobs.detail(jobId), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  create: async ({ data }: Config): Promise<APIResponse<Job>> => {
    try {
      const res = await axios.post(urls.jobs.base(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  overview: async (): Promise<APIResponse<Overview>> => {
    try {
      const res = await axios.get(urls.jobs.overview())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  jobApplicant: {
    create: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<JobApplicant>> => {
      try {
        const res = await axios.post(
          urls.jobApplicant.create(urlParams.jobId),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieve: async ({
      urlParams,
      params,
    }: ConfigWithURLParams): Promise<APIResponse<JobApplicant>> => {
      try {
        const res = await axios.get(
          urls.jobApplicant.update(urlParams.jobApplicantId),
          { params }
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    update: async ({
      urlParams: { jobApplicantId },
      data,
    }: ConfigWithURLParams): Promise<APIResponse<JobApplicant>> => {
      try {
        const res = await axios.put(
          urls.jobApplicant.update(jobApplicantId),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    delete: async ({
      urlParams: { jobApplicantId },
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(urls.jobApplicant.update(jobApplicantId))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    rejectActiveApplicant: async ({
      urlParams: { jobApplicantId },
      data,
    }: ConfigWithURLParams): Promise<APIResponse<JobApplicant>> => {
      try {
        const res = await axios.patch(
          urls.jobApplicant.update(jobApplicantId),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    listInvited: async ({
      urlParams,
      params,
    }: ConfigWithURLParams): Promise<PaginatedAPIResponse<InvitedUser[]>> => {
      try {
        const res = await axios.get(
          urls.jobApplicant.listInvited(urlParams.jobId),
          { params }
        )
        return formatPaginatedResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    listApplied: async ({
      urlParams,
      params,
    }: ConfigWithURLParams): Promise<PaginatedAPIResponse<AppliedUser[]>> => {
      try {
        const res = await axios.get(
          urls.jobApplicant.listApplied(urlParams.jobId),
          { params }
        )
        return formatPaginatedResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    listActive: async ({
      urlParams,
      params,
    }: ConfigWithURLParams): Promise<PaginatedAPIResponse<ActiveUser[]>> => {
      try {
        const res = await axios.get(
          urls.jobApplicant.listActive(urlParams.jobId),
          { params }
        )
        return formatPaginatedResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieveContactDetails: async ({
      urlParams: { userId },
      data,
    }: ConfigWithURLParams): Promise<APIResponse<ContactDetails>> => {
      try {
        const res = await axios.get(
          urls.jobApplicant.retrieveContactDetails(userId),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  jobApplicantStep: {
    create: async ({
      urlParams: { jobApplicantId },
      data,
    }: ConfigWithURLParams): Promise<APIResponse<any>> => {
      try {
        const res = await axios.post(
          urls.jobApplicantStep.create(jobApplicantId),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    update: async ({
      urlParams: { jobApplicantStepId },
      data,
    }: ConfigWithURLParams): Promise<APIResponse<any>> => {
      try {
        const res = await axios.put(
          urls.jobApplicantStep.update(jobApplicantStepId),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    delete: async ({
      urlParams: { jobApplicantStepId },
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(
          urls.jobApplicantStep.update(jobApplicantStepId)
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  jobOffer: {
    list: async ({
      params,
    }: Config): Promise<PaginatedAPIResponse<JobOfferType[]>> => {
      try {
        const res = await axios.get(urls.jobOffer.list(), {
          params: {
            fields: ["date_created", "user"],
            ...params,
          },
        })
        return formatPaginatedResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    create: async ({ data, headers }: Config): Promise<APIResponse<any>> => {
      try {
        const res = await axios.post(urls.jobOffer.create(), data, { headers })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    retrieve: async ({
      params,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<JobOffer>> => {
      try {
        const res = await axios.get(
          urls.jobOffer.retrieve(urlParams.jobOfferId),
          params
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
}
