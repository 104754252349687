/* eslint-disable react/destructuring-assignment */
import { Step, StepType } from "api/resources/jobs/types"
import AssessmentItem from "components/teacher/Timeline/AssessmentItem"
import InterviewItem from "components/teacher/Timeline/InterviewItem"
import LiveDemoItem from "components/teacher/Timeline/LiveDemoItem"
import TimelineContent from "components/Timeline/TimelineContent"
import React from "react"
import { getIcon, getTag } from "utils/getStepStatusTag"

const TimelineItem = (props: Step) => (
  <TimelineContent icon={getIcon(props)}>
    {props.stepType === StepType.ASSESSMENT && (
      <AssessmentItem tag={getTag(props)} {...props} />
    )}
    {props.stepType === StepType.INTERVIEW && (
      <InterviewItem tag={getTag(props)} {...props} />
    )}
    {props.stepType === StepType.LIVE_DEMO && (
      <LiveDemoItem tag={getTag(props)} {...props} />
    )}
  </TimelineContent>
)

export default TimelineItem
