import "./styles/global.css"

import * as Sentry from "@sentry/react"
import ErrorScreen from "components/ErrorScreen"
import React from "react"
import { createRoot } from "react-dom/client"
import { getAuthInfo } from "utils/auth"

import App from "./App"

if (!process.env.REACT_APP_SSO_URL) {
  throw new Error("Please add REACT_APP_SSO_URL in your environment variables.")
}

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
  })
  const authInfo = getAuthInfo()
  if (authInfo)
    Sentry.setUser({ id: authInfo.user.uuid, email: authInfo.user.email })
}

const container = document.getElementById("root")
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!) // createRoot(container!) if you use TypeScript
root.render(
  <Sentry.ErrorBoundary
    fallback={
      <ErrorScreen error="An unexpected error occurred. Please try reloading the page." />
    }
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Sentry.ErrorBoundary>
)
