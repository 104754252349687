import {
  Button,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import { Country, Curriculum, State } from "api/resources/global/types"
import { Profile } from "api/resources/profile/types"
import clsx from "clsx"
import { GlobalContext } from "components/GlobalState"
import WorkingDays from "components/profile/WorkingDays"
import Section from "components/Section"
import { Camera } from "iconoir-react"
import omit from "lodash/omit"
import snakeCase from "lodash/snakeCase"
import React, { useContext, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import { getAuthInfo } from "utils/auth"
import { teachingModeChoices } from "utils/constants"
import countryCodes from "utils/countryCodes"
import { handleErrors, validateImageUpload } from "utils/helpers"
import useResources from "utils/hooks/useResources"
import toast from "utils/toast"

import EditHeadings from "./EditHeadings"
import FloatingSaveBanner from "./FloatingSaveBanner"
import MobileBottomMenu from "./MobileBottomMenu"
import MobileMenu from "./MobileMenu"
import ViewProfilePicture from "./ViewProfilePicture"

const AVATAR_SIZE = 142

const DEFAULT_LOGO = "/assets/schoolLogo.svg"
const DEFAULT_COVER = "/assets/defaultCover.png"

const useStyles = createUseStyles({
  title: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      /** Edit profile button */
      "& button": {
        position: "absolute",
        right: theme.spacing(12 / 8),
        top: theme.spacing(12 / 8),
      },
    },
  },

  position: {
    marginBottom: theme.spacing(1 / 2),
  },

  coverImage: ({ coverImage }: Pick<Profile, "coverImage">) => ({
    height: 184,
    minWidth: "100%",
    backgroundColor: theme.colors.onSurface[300],
    backgroundImage: `linear-gradient(rgb(15, 23, 42,0.6), rgb(15, 23, 42,0.6)),url('${
      coverImage ?? DEFAULT_COVER
    }')`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    borderRadius: theme.spacing(1, 1, 0, 0),

    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("xs")]: {
      height: 108,
      borderRadius: theme.spacing(0),
    },
  }),
  displayPictureContainer: {
    position: "absolute",
    transform: "translateY(-60%)",
    left: theme.spacing(3),
    height: AVATAR_SIZE,
    width: AVATAR_SIZE,

    [theme.breakpoints.down("xs")]: {
      transform: "translateY(calc(-60% - 40px))",
      height: 91,
      width: 91,
      left: theme.spacing(2),
    },
  },
  displayPictureActions: {
    position: "absolute",
    zIndex: "1",
    top: "50%",
    left: "50%",
    transform: " translate(-50%,-50%)",
  },
  displayPicture: {
    height: AVATAR_SIZE,
    width: AVATAR_SIZE,
    objectFit: "cover",
    borderRadius: "50%",
    border: `3px solid ${theme.colors.common.white[500]}`,
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
    [theme.breakpoints.down("xs")]: {
      height: 91,
      width: 91,
    },
  },

  content: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    padding: theme.spacing(2, 3),
    paddingLeft: `calc(${AVATAR_SIZE}px + 24px + 16px)`,
    minHeight: "calc(284.1px - 175px)",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      padding: theme.spacing(2),
      paddingTop: theme.spacing(5),
    },
  },
  imgContainer: {
    borderRadius: "50%",
    overflow: "hidden",
    height: theme.spacing(12),
    width: theme.spacing(12),
    display: "flex",
    alignItems: "center",
    position: "relative",
    justifyContent: "center",
  },

  displayPictureMobile: {
    width: "100%",
    objectFit: "cover",
    height: "100%",
  },
  imgOverlay: {
    position: "absolute",
    height: "100%",
    borderRadius: "100px",
    width: "100%",
    backgroundColor: theme.colors.onSurface[800],
    opacity: 0.5,
    top: 0,
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(12),
      width: theme.spacing(12),
      top: "auto",
    },
  },

  cameraIcon: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mobileDropDown: {
    maxWidth: "469px",
  },
  countryCode: {
    maxWidth: "120px",
  },
  phoneNumberField: {
    maxWidth: "300px",
  },
  timePicker: {
    maxWidth: "159px",
  },
})

type Option = {
  label: string
  value: string
}

/**
 * if a value doesn't exist then it is added
 * but however if the value is there, remove that value from the array.
 */
function knock(arr: number[], val: number) {
  const value = Number(val)
  if (!arr) {
    return [value]
  }
  const array = [...arr]
  const index = arr.indexOf(value)
  if (index > -1) {
    array.splice(index, 1)
  } else {
    array.push(value)
  }
  return array
}

function formatDate(date: string | null) {
  if (date) {
    const tempDate = date.split(":")
    if (tempDate.length > 2) {
      return `${tempDate[0]}:${tempDate[1]}`
    }
  }
  return date
}

type Form = Pick<
  Profile,
  | "name"
  | "logo"
  | "coverImage"
  | "address"
  | "website"
  | "city"
  | "pincode"
  | "branch"
  | "email"
  | "workingDays"
  | "dayStartTime"
  | "dayEndTime"
  | "teachingMode"
  | "dateEstablished"
> & {
  countryCode: string | null
  curriculumId: string[]
  phoneNumber: number | null
  countryId: string | null
  stateId: string | null
}

interface Props {
  onMobileBack?: () => void
  data: Profile
  onUpdate: (data: Partial<Profile>) => void
}

const PublicDetails = ({
  onMobileBack,
  data: { logo, coverImage, ...data },
  onUpdate,
}: Props) => {
  const {
    register,
    control,
    setValue,
    reset,
    setError,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<Form>({
    defaultValues: {
      curriculumId: [],
    },
  })

  const { setProfile } = useContext(GlobalContext)

  const classes = useStyles({ coverImage })
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const { countries } = useResources(["countries"])
  /**
   * undefined --> options are being fetched(loading...)
   * null --> default || api call failed || options don't exist
   */
  const [states, setStates] = useState<State[] | null | undefined>(null)

  const [curriculaOptions, setCurriculaOptions] = useState<
    Option[] | null | undefined
  >(null)

  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const [viewProfilePicture, setViewProfilePicture] = useState(false)

  const logoInputRef = React.useRef<HTMLInputElement>(null)
  const coverImageInputRef = React.useRef<HTMLInputElement>(null)

  const fetchCurricula = async () => {
    setCurriculaOptions(undefined)

    const res = await api.global.curricula.list({ params: { page: -1 } })
    if (res.isSuccessful) {
      const format = ({ name, uuid }: Curriculum) => ({
        label: name,
        value: uuid,
      })

      const isCustom = (curriculum: Curriculum) =>
        res.data.find(({ uuid }) => uuid === curriculum.uuid) === undefined

      if (data.curriculumBoard) {
        res.data.push(...data.curriculumBoard.filter(isCustom))
      }

      setCurriculaOptions(res.data.map(format))
    }
  }

  const fetchStates = async (countryId: Country["uuid"]) => {
    setStates(undefined)
    setValue("stateId", null)
    const res = await api.global.listStates({
      urlParams: {
        countryId,
      },
      params: {
        page: -1,
      },
    })
    if (res.isSuccessful) {
      setStates(res.data)
    } else {
      setStates(null)
    }
  }

  const onSubmit = handleSubmit(async formData => {
    setSubmitLoading(true)

    const res = await api.school.update({
      data: {
        ...formData,
        branch: formData.branch || null,
        workingDays:
          formData.workingDays && formData.workingDays.length > 0
            ? formData.workingDays
            : null,
        phoneNumber: `${formData.countryCode}${formData.phoneNumber}`,
        dateEstablished: formData.dateEstablished
          ? `${formData.dateEstablished}-01-01`
          : null,
      },
    })
    if (res.isSuccessful) {
      onUpdate(res.data)
      toast.success("Details updated successfully")
      reset(formData)
    } else {
      handleErrors(setError, res.errors)
    }
    setSubmitLoading(false)
  })

  const uploadPicture = async (
    e: React.ChangeEvent<HTMLInputElement>,
    key: keyof Pick<Profile, "coverImage" | "logo">
  ) => {
    const fileUploaded = (e.target.files as FileList)[0]
    if (
      fileUploaded &&
      validateImageUpload(fileUploaded, key === "coverImage" ? 5 : 1)
    ) {
      const files = new FormData()
      files.append(snakeCase(key), fileUploaded)

      const res = await api.school.update({
        data: files,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      if (res.isSuccessful) {
        onUpdate({ [key]: res.data[key] })

        if (key === "logo") {
          setProfile({ logo: res.data.logo })
        }
        setLoading(false)
      } else {
        setLoading(false)
        toast.error(res.errors.message)
      }
    }
    setOpenMobileMenu(false)
    setViewProfilePicture(false)
  }

  const handlePictureRemove = async (
    key: keyof Pick<Profile, "coverImage" | "logo">
  ) => {
    const res = await api.school.update({
      data: { [key]: null },
    })
    if (res.isSuccessful) {
      onUpdate({ [key]: null })
      setLoading(false)
    } else {
      setLoading(false)
      toast.error(res.errors.message)
    }
    setOpenMobileMenu(false)
    setViewProfilePicture(false)
  }

  useEffect(() => {
    if (data.country?.uuid) {
      fetchStates(data.country?.uuid)
    }
    reset({
      dateEstablished: data.dateEstablished
        ? String(new Date(data.dateEstablished).getFullYear())
        : null,
      curriculumId: (data?.curriculumBoard ?? []).map(item => item.uuid),
      countryId: data.country?.uuid ?? null,
      stateId: data.state?.uuid ?? null,
      // @ts-expect-error phone
      phoneNumber: data.phoneNumber ? data.phoneNumber.number : null,
      countryCode: data.phoneNumber?.countryCode
        ? `+${data.phoneNumber.countryCode}`
        : null,
      dayStartTime: formatDate(data.dayStartTime),
      dayEndTime: formatDate(data.dayEndTime),

      ...omit(data, [
        "description",
        "dayStartTime",
        "dayEndTime",
        "country",
        "state",
        "curriculumBoards",
        "phoneNumber",
        "dateEstablished",
        "id",
        "slug",
        "isVerified",
      ]),
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchCurricula()
    // need to run on first render only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <input
        accept=".jpg, .jpeg, .png ,.webp"
        ref={logoInputRef}
        style={{ display: "none" }}
        type="file"
        onChange={e => uploadPicture(e, "logo")}
        onClick={event => {
          // @ts-expect-error HTML input file selection event not firing upon selecting the same file
          event.target.value = null
        }}
      />
      <input
        accept=".jpg, .jpeg, .png ,.webp"
        ref={coverImageInputRef}
        style={{ display: "none" }}
        type="file"
        onChange={e => uploadPicture(e, "coverImage")}
        onClick={event => {
          // @ts-expect-error HTML input file selection event not firing upon selecting the same file
          event.target.value = null
        }}
      />

      {viewProfilePicture && (
        <ViewProfilePicture
          image={logo || DEFAULT_LOGO}
          onBack={() => setViewProfilePicture(false)}
          onClickChange={() => logoInputRef.current?.click()}
        />
      )}

      <MobileMenu
        loading={loading}
        title="Personal Details"
        onBack={openMobileMenu ? () => setOpenMobileMenu(false) : onMobileBack}
        onSave={openMobileMenu ? undefined : () => onSubmit()}
      />

      <MobileBottomMenu
        close={() => setOpenMobileMenu(false)}
        open={openMobileMenu}
      >
        <div className="flex flex-col gap-y-2.5 px-2 py-2.25">
          <Button
            color="black"
            variant="text"
            onClick={() => {
              setOpenMobileMenu(false)
              setViewProfilePicture(true)
            }}
          >
            View Profile Picture
          </Button>
          <Button
            color="black"
            variant="text"
            onClick={() => logoInputRef.current?.click()}
          >
            Upload Photo
          </Button>
          <Button
            color="critical"
            disabled={!logo}
            variant="text"
            onClick={() => handlePictureRemove("logo")}
          >
            Remove
          </Button>
        </div>
      </MobileBottomMenu>

      <div className="hidden sm:block">
        <Section>
          <header>
            <div className={classes.coverImage}>
              <Menu
                menuButton={
                  <IconButton color="white" size="sm">
                    <Camera height="36px" width="36px" />
                  </IconButton>
                }
              >
                <MenuItem onClick={() => coverImageInputRef.current?.click()}>
                  Upload New Image
                </MenuItem>
                <MenuItem
                  color="critical"
                  disabled={!coverImage}
                  onClick={() => handlePictureRemove("coverImage")}
                >
                  Remove
                </MenuItem>
              </Menu>
            </div>
            <div className={classes.content}>
              <div className={classes.displayPictureContainer}>
                <img
                  alt="profile-pic"
                  className={classes.displayPicture}
                  src={logo || DEFAULT_LOGO}
                />
                <div className={classes.imgOverlay} />
                <div className={classes.displayPictureActions}>
                  <Menu
                    menuButton={
                      <IconButton color="white" size="sm">
                        <Camera height="36px" width="36px" />
                      </IconButton>
                    }
                  >
                    <MenuItem onClick={() => logoInputRef.current?.click()}>
                      Upload New Image
                    </MenuItem>
                    <MenuItem
                      color="critical"
                      disabled={!logo}
                      onClick={() => handlePictureRemove("logo")}
                    >
                      Remove
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </div>
          </header>
        </Section>
      </div>

      <div className="sm:hidden">
        <div className={classes.imgContainer}>
          <img
            alt="profile-pic"
            className={classes.displayPictureMobile}
            src={logo || DEFAULT_LOGO}
          />
          <div className={classes.imgOverlay} />
          <div className={classes.cameraIcon}>
            <IconButton
              color="white"
              size="sm"
              onClick={() => setOpenMobileMenu(true)}
            >
              <Camera height="36px" width="36px" />
            </IconButton>
          </div>
        </div>
      </div>

      <form className="flex flex-col gap-3 sm:pb-12" onSubmit={onSubmit}>
        <FloatingSaveBanner
          loading={submitLoading}
          open={isDirty}
          onSave={onSubmit}
        />

        <EditHeadings text="Basic Details" />
        <TextField
          error={Boolean(errors.name)}
          helperText={errors.name?.message}
          label="School name"
          placeholder="Enter School Name"
          fullWidth
          {...register("name", {
            required: { value: true, message: "Required" },
          })}
        />

        <TextField
          error={Boolean(errors.branch)}
          helperText={errors.branch?.message}
          label="Branch name"
          placeholder="Enter Branch Name"
          fullWidth
          {...register("branch")}
        />

        {/* @ts-expect-error placebo-issue */}
        <TextField
          error={Boolean(errors.address)}
          helperText={errors.address?.message}
          label="School address"
          placeholder="Enter Full School Address Here"
          rows={3}
          fullWidth
          {...register("address", {
            required: { value: true, message: "Required" },
          })}
        />

        {/* Location */}
        <div
          className={clsx("flex gap-3", {
            "flex-col": isXs,
          })}
        >
          <Controller
            control={control}
            name="countryId"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                error={Boolean(errors.countryId)}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ uuid }) => uuid}
                helperText={errors.countryId?.message}
                label="country"
                options={countries}
                value={value ? countries.find(c => c.uuid === value) : null}
                fullWidth
                isClearable
                mountOnBody
                onBlur={onBlur}
                onChange={val => {
                  if (val) {
                    fetchStates(val.uuid)
                  } else {
                    setStates(null)
                  }
                  onChange(val ? val.uuid : "")
                }}
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />

          <Controller
            control={control}
            name="stateId"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                error={Boolean(errors.stateId)}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ uuid }) => uuid}
                helperText={errors.stateId?.message}
                isLoading={states === undefined}
                label="State/Region/Province"
                options={states ?? []}
                placeholder="Ex: Dubai"
                value={
                  value ? states && states.find(c => c.uuid === value) : null
                }
                fullWidth
                isClearable
                mountOnBody
                onBlur={onBlur}
                onChange={val => onChange(val ? val.uuid : "")}
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
        </div>

        <div
          className={clsx("flex gap-3", {
            "flex-col": isXs,
          })}
        >
          <TextField
            error={Boolean(errors.city)}
            helperText={errors.city?.message}
            label="City"
            placeholder="Enter City"
            fullWidth
            {...register("city", {
              required: { value: true, message: "Required" },
            })}
          />
          <TextField
            error={Boolean(errors.pincode)}
            helperText={errors.pincode?.message}
            label="pincode"
            placeholder="Enter Postal Code"
            fullWidth
            {...register("pincode", {
              required: { value: true, message: "Required" },
            })}
          />
        </div>

        <TextField
          error={Boolean(errors.website)}
          helperText={errors.website?.message}
          label="School Website"
          placeholder="www.school.com"
          fullWidth
          {...register("website", {
            required: { value: true, message: "Required" },
          })}
        />

        <EditHeadings text="Public Contact Details" />
        <div className="flex flex-col gap-1 md:flex-row md:gap-1.75 md:items-start">
          <Controller
            control={control}
            name="countryCode"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                className={classes.countryCode}
                error={Boolean(errors.countryCode)}
                getOptionLabel={({ dialCode }) => dialCode}
                getOptionValue={({ dialCode }) => dialCode}
                helperText={errors.countryCode?.message}
                label="Code"
                options={countryCodes}
                placeholder="+91"
                value={
                  value
                    ? countryCodes.find(item => item.dialCode === value)
                    : null
                }
                fullWidth
                onBlur={onBlur}
                onChange={val => {
                  onChange(val?.dialCode)
                }}
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />

          <TextField
            className={classes.phoneNumberField}
            error={Boolean(errors.phoneNumber)}
            helperText={errors.phoneNumber?.message}
            label="Phone Number"
            placeholder="9845XX XXXXX"
            type="tel"
            fullWidth
            {...register("phoneNumber", {
              required: { value: true, message: "Required" },
              maxLength: {
                value: 12,
                message: "Length cannot be greater than 12",
              },
              minLength: {
                value: 8,
                message: "Length cannot be less than 8",
              },
            })}
          />
        </div>

        <TextField
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
          label="School email"
          placeholder="Enter your school mail"
          type="email"
          fullWidth
          {...register("email", {
            required: { value: true, message: "Required" },
          })}
        />

        <EditHeadings text="Other Details" />
        <Controller
          control={control}
          name="curriculumId"
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              error={Boolean(errors.curriculumId)}
              helperText={errors.curriculumId?.message}
              isLoading={curriculaOptions === undefined}
              label="Curriculum Board"
              name="curriculumId"
              options={curriculaOptions ?? []}
              placeholder="Select Curriculum Board"
              value={(curriculaOptions ?? []).filter(item =>
                (value ?? []).includes(item.value)
              )}
              createable
              isClearable
              isMulti
              onBlur={onBlur}
              onChange={async (options, actionMeta) => {
                onChange(options.map(item => item.value))

                if (actionMeta.action === "create-option" && options) {
                  const newOption = options[options.length - 1]

                  const res = await api.global.curricula.create({
                    data: {
                      new: [
                        {
                          curriculum: newOption.label,
                          userUuid: getAuthInfo()?.user.uuid,
                        },
                      ],
                    },
                  })

                  if (res.isSuccessful) {
                    const savedOption = res.data[0]

                    setCurriculaOptions(prevState => [
                      ...(prevState ?? []),
                      {
                        value: savedOption.uuid,
                        label: savedOption.name,
                      },
                    ])

                    const previousSelectedOptionIds = options
                      .filter(item => item.value !== savedOption.name)
                      .map(item => item.value)

                    setValue("curriculumId", [
                      ...previousSelectedOptionIds,
                      savedOption.uuid,
                    ])
                  }
                }

                if (actionMeta.action === "clear") {
                  setValue("curriculumId", [""])
                }
              }}
            />
          )}
          rules={{
            required: { value: true, message: "Required" },
          }}
        />
        <div>
          <InputLabel label="Working days" />
          <Controller
            control={control}
            name="workingDays"
            render={({ field: { onChange, value } }) => (
              <WorkingDays
                error={Boolean(errors.workingDays)}
                helperText={
                  Array.isArray(errors.workingDays)
                    ? errors.workingDays[0].message
                    : errors.workingDays?.message
                }
                workingDays={value || []}
                onClick={e => {
                  const v = Number(e.currentTarget.value)
                  onChange(knock(value || [], v))
                }}
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
        </div>

        <div>
          <div className="flex gap-2">
            <TextField
              className={classes.timePicker}
              error={Boolean(errors.dayStartTime)}
              helperText={errors.dayStartTime?.message}
              label="Start time"
              type="time"
              {...register("dayStartTime", {
                required: { value: true, message: "Required" },
              })}
            />

            <TextField
              className={classes.timePicker}
              error={Boolean(errors.dayEndTime)}
              helperText={errors.dayEndTime?.message}
              label="End time"
              type="time"
              {...register("dayEndTime", {
                required: { value: true, message: "Required" },
              })}
            />
          </div>
          <Typography
            className="mt-1"
            color="onSurface.500"
            variant="smallBody"
          >
            Working Hours of School
          </Typography>
        </div>
        <Controller
          control={control}
          name="teachingMode"
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              error={Boolean(errors.teachingMode)}
              helperText={errors.teachingMode?.message}
              label="Mode of learning"
              options={teachingModeChoices}
              placeholder="Learning Mode"
              value={value && teachingModeChoices.find(c => c.value === value)}
              mountOnBody
              onBlur={onBlur}
              onChange={newValue => onChange(newValue?.value)}
            />
          )}
          rules={{
            required: { value: true, message: "Required" },
          }}
        />
        <TextField
          error={Boolean(errors.dateEstablished)}
          helperText={errors.dateEstablished?.message}
          label="school establishment year"
          type="number"
          {...register("dateEstablished", {
            maxLength: {
              value: 4,
              message: "Length cannot be greater than 4",
            },
            minLength: {
              value: 4,
              message: "Length cannot be less than 4",
            },
          })}
        />
      </form>
    </>
  )
}

export default PublicDetails
