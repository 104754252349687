import axios from "api/axios"
import { APIResponse, Config, ConfigWithURLParams } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { Country, Currency, Curriculum, State, Subject } from "./types"
import { urls } from "./urls"

export default {
  listCurrencies: async ({
    params,
  }: Config): Promise<APIResponse<Currency[]>> => {
    try {
      const res = await axios.get(urls.currencies.list(), {
        params,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  subjects: {
    // This is bulk-create
    create: async ({ data }: Config): Promise<APIResponse<Subject[]>> => {
      try {
        const res = await axios.post(urls.subjects.create(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    list: async ({ params }: Config): Promise<APIResponse<Subject[]>> => {
      try {
        const res = await axios.get(urls.subjects.list(), {
          params,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  listCountries: async ({
    params,
  }: Config): Promise<APIResponse<Country[]>> => {
    try {
      const res = await axios.get(urls.countries.list(), {
        params,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  listStates: async ({
    params,
    urlParams,
  }: ConfigWithURLParams<"countryId">): Promise<APIResponse<State[]>> => {
    try {
      const res = await axios.get(urls.states.list(urlParams.countryId), {
        params,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  curricula: {
    create: async ({ data }: Config): Promise<APIResponse<Curriculum[]>> => {
      try {
        const res = await axios.post(urls.curricula.create(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    list: async ({ params }: Config): Promise<APIResponse<Curriculum[]>> => {
      try {
        const res = await axios.get(urls.curricula.list(), {
          params,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
}
