import {
  CircularProgress,
  Container,
  Divider,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Solutions } from "api/resources/assessments/types"
import QuestionResultCard from "components/assessments/QuestionResultCard"
import Navbar from "components/shared/Navbar"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

const Result = () => {
  const { jobApplicantStepId } = useParams()

  const [loading, setLoading] = useState(false)
  const [solutions, setSolutions] = useState<Solutions | null>(null)

  useEffect(() => {
    setLoading(true)

    const listSolutions = async () => {
      if (!jobApplicantStepId) return

      const res = await api.assessments.listSolutions({
        urlParams: {
          jobApplicantStepId,
        },
      })
      if (res.isSuccessful) setSolutions(res.data)

      setLoading(false)
    }

    listSolutions()
  }, [jobApplicantStepId])

  return (
    <>
      <Navbar />
      <Container>
        {loading || !solutions ? (
          <div className="flex items-center justify-center mt-20 gap-2">
            <CircularProgress />
            <Typography variant="title2">Loading Result</Typography>
          </div>
        ) : (
          <>
            <div className="flex justify-between gap-4.5">
              <div>
                <Typography variant="title3">{solutions.title}</Typography>
                <Typography className="mt-0.5" variant="body">
                  {solutions.user.fullName}
                </Typography>
              </div>
              <Typography variant="strong">
                Score: {solutions.score.toFixed(1)}%
              </Typography>
            </div>

            <div className="flex items-center py-3.5">
              <Typography variant="strong">Questions</Typography>
              <div className="pl-2 flex-grow">
                <Divider weight="light" />
              </div>
            </div>

            {solutions.assessmentPerformances.map((question, index) => (
              <QuestionResultCard
                key={index}
                question={question}
                questionNumber={index + 1}
              />
            ))}
          </>
        )}
      </Container>
    </>
  )
}

export default Result
