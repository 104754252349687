import { Button, Container, Typography } from "@suraasa/placebo-ui"
import api from "api"
import { ReactComponent as Illustration } from "assets/illustrations/broke-connection.svg"
import clsx from "clsx"
import { LogOut } from "iconoir-react"
import React, { useEffect } from "react"
import { createUseStyles } from "react-jss"
import { Link, useNavigate } from "react-router-dom"
import { clearAuthInfo } from "utils/auth"
import { getPlatformURL } from "utils/helpers"
import { routes } from "utils/routes"

const useStyles = createUseStyles({
  root: {
    height: "100%",
  },
})

const NoSchoolLinked = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const getOverview = async () => {
      const res = await api.school.listAccessibleSchools()

      if (res.isSuccessful) {
        if (res.data.length > 0) {
          navigate(routes.home)
        }
      }
    }
    getOverview()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const classes = useStyles()

  return (
    <>
      <div className={clsx("flex justify-evenly items-center", classes.root)}>
        <Container>
          <div className={clsx("flex justify-evenly items-center")}>
            <div className="flex flex-col gap-2">
              <Typography color="onSurface.800" variant="largeTitle">
                Hmmm
              </Typography>
              <Typography color="onSurface.500" variant="largeBody">
                Looks like your account is not linked to any school.
              </Typography>
              <Typography color="onSurface.800" variant="largeBody">
                If you think this is a mistake then please reach out to us.
                We'll be ready to assist you.
              </Typography>
              <div className="flex gap-2 flex mt-3">
                <Button component={Link} to={routes.help}>
                  Contact us
                </Button>

                <Button
                  color="critical"
                  startAdornment={<LogOut />}
                  variant="text"
                  onClick={() => {
                    clearAuthInfo()
                    const url = new URL(getPlatformURL("sso", "school/logout"))
                    url.searchParams.append(
                      "origin",
                      `${window.location.origin}`
                    )
                    window.location.href = url.href
                  }}
                >
                  Logout
                </Button>
              </div>
            </div>

            <Illustration className="hidden sm:block" />
          </div>
        </Container>
      </div>
    </>
  )
}

export default NoSchoolLinked
