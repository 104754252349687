import { NotificationList } from "@suraasa/notifications"
import { Profile } from "api/resources/profile/types"
import React, { createContext, useState } from "react"
import { defaultProfile, NotificationAction } from "utils/constants"

export type Context = {
  canAccessPortal: boolean
  loading: boolean
  isVerified: boolean
  isSalesVerified: boolean
  setIsVerified: (arg0: boolean) => void
  setIsSalesVerified: (arg0: boolean) => void
  setCanAccessPortal: (arg0: boolean) => void
  setLoading: (arg0: boolean) => void
  notifications: NotificationList<NotificationAction>
  setNotifications: React.Dispatch<
    React.SetStateAction<NotificationList<NotificationAction>>
  >
  profile: Profile
  setProfile: (profile: Partial<Profile>) => void
}

export const GlobalContext = createContext<Context>({
  canAccessPortal: false,
  isVerified: false,
  isSalesVerified: false,
  setIsVerified: () => {},
  setIsSalesVerified: () => {},
  loading: true,
  setCanAccessPortal: () => {},
  setLoading: () => {},
  notifications: { data: null, unreadCount: 0, totalCount: 0 },
  setNotifications: () => {},
  setProfile: () => {},
  profile: defaultProfile,
})

const GlobalState = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = useState(true)
  const [profile, setProfile] = useState<Context["profile"]>(defaultProfile)
  const [isVerified, setIsVerified] = useState(false)
  const [isSalesVerified, setIsSalesVerified] = useState(false)
  const [canAccessPortal, setCanAccessPortal] = useState(false)
  const [notifications, setNotifications] = useState<
    NotificationList<NotificationAction>
  >({ data: null, unreadCount: 0, totalCount: 0 })
  // This prevents the app from loading if the access token is expired.
  // const [accessTokenIsValid, setAccessTokenIsValid] = useState(false)

  return (
    <GlobalContext.Provider
      value={{
        canAccessPortal,
        setCanAccessPortal,
        isSalesVerified,
        setIsSalesVerified,
        loading,
        setLoading,
        isVerified,
        setIsVerified,
        notifications,
        setNotifications,
        profile,
        setProfile: (newState: Partial<Profile>) => {
          setProfile(prevState => ({ ...prevState, ...newState }))
        },
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalState
