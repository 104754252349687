import {
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  Select,
} from "@suraasa/placebo-ui"
import api from "api"
import { Perk } from "api/resources/profile/types"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import { PERK_CHAR_LIMIT } from "utils/constants"
import { handleErrors } from "utils/helpers"
import useResources from "utils/hooks/useResources"
import toast from "utils/toast"

const useStyles = createUseStyles(theme => ({
  dialogContent: {
    padding: theme.spacing(2.5, 3, 5.5, 3),
    display: "flex",
    gap: 24,
    flexDirection: "column",
  },
}))

type Props = {
  onAdd: (data: Perk) => void
  handleBack?: () => void
}

const formatPerks = (perks: Perk[]) =>
  perks.map(item => ({ value: item.id, label: item.name }))

const AddDialog = ({
  handleBack,
  onAdd,
}: Props & Pick<DialogProps, "open" | "onRequestClose">) => {
  const classes = useStyles()

  const { perks } = useResources(["perks"])

  const [perksOptions, setPerksOptions] = useState(formatPerks(perks))

  useEffect(() => {
    setPerksOptions(formatPerks(perks))
  }, [perks])

  const [loading, setLoading] = useState(false)

  const {
    handleSubmit,
    reset,
    setValue,
    setError,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      perk: "",
    },
  })

  useEffect(() => {
    reset({})
  }, [reset])

  const onSubmit = handleSubmit(async FormData => {
    const perkAdded = FormData.perk
    setLoading(true)

    if (perkAdded) {
      const res = await api.profile.perks.update({
        data:
          typeof perkAdded === "number"
            ? { perkId: perkAdded }
            : { perkName: perkAdded },
      })

      if (res.isSuccessful) {
        onAdd(res.data.perk)
        if (handleBack) handleBack()
      } else {
        handleErrors(setError, res.errors)
      }
    }
    setLoading(false)
  })

  return (
    <form onSubmit={onSubmit}>
      <DialogTitle onBack={handleBack}> Add New Perk</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div>
          <Controller
            control={control}
            name="perk"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                error={Boolean(errors.perk)}
                helperText={errors.perk?.message}
                inputLabelProps={{ required: true }}
                label="Perk"
                options={perksOptions}
                value={value ? perksOptions.find(c => c.value === value) : null}
                createable
                fullWidth
                isClearable
                mountOnBody
                onBlur={onBlur}
                onChange={(option, actionMeta) => {
                  onChange(option?.value)

                  if (actionMeta.action === "create-option" && option) {
                    if (option.value.length <= PERK_CHAR_LIMIT) {
                      setPerksOptions(i => [option, ...i])
                      setValue("perk", String(option.value))
                    } else {
                      toast.error(`Max characters allowed: ${PERK_CHAR_LIMIT}`)
                      setValue(
                        "perk",
                        String(option.value.slice(0, PERK_CHAR_LIMIT))
                      )
                    }
                  }

                  if (actionMeta.action === "clear") {
                    setValue("perk", "")
                    setPerksOptions(formatPerks(perks))
                  }
                }}
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
        </div>
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: "Save",
            variant: "filled",
            color: "primary",
            type: "submit",
            loading,
          },
          secondary: {
            label: "Close",
          },
        }}
      />
    </form>
  )
}

export default AddDialog
