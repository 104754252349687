import {
  CircularProgress,
  Container,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import { Profile, User } from "api/resources/profile/types"
import clsx from "clsx"
import { GlobalContext } from "components/GlobalState"
import AccountDetails from "components/profile/editProfile/AccountDetails"
// import AccountDetails from "components/profile/editProfile/AccountDetails"
import ProfileNavigation from "components/profile/editProfile/ProfileNavigation"
import PublicDetails from "components/profile/editProfile/PublicDetails"
import Navbar from "components/shared/Navbar"
import { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { createSearchParams, useSearchParams } from "react-router-dom"
import { ProfileNavTabs } from "utils/constants"

const useStyles = createUseStyles(({ colors, breakpoints }) => ({
  sideBar: {
    background: colors.common.white[500],
    height: "min-content",
    [breakpoints.down("xs")]: {
      border: "none",
      background: colors.common.white[500],
    },
  },
  loading: {
    width: "100%",
  },
}))

function EditProfile() {
  const { isVerified, isSalesVerified } = useContext(GlobalContext)

  const preventProfileAccess = !(isVerified || isSalesVerified)
  const classes = useStyles()

  const [searchParams, setSearchParams] = useSearchParams()
  const selectedTab = searchParams.get("tab")

  const isXs = useMediaQuery(theme.breakpoints.only("xs"))

  const [showMobileNavigation, setShowMobileNavigation] = useState(true)
  const [loading, setLoading] = useState<boolean>(false)

  /**
   * Preventing user from accessing public details page until their profile is verified
   */
  const [selectedMenu, setSelectedMenu] = useState<ProfileNavTabs>(
    preventProfileAccess
      ? ProfileNavTabs.accountDetails
      : ProfileNavTabs[selectedTab as keyof typeof ProfileNavTabs] ||
          ProfileNavTabs.publicDetails
  )

  /**
   * Preventing user from accessing public details page until their profile is verified
   */
  useEffect(() => {
    if (selectedTab === "publicDetails" && preventProfileAccess) {
      searchParams.set("tab", "accountDetails")
      setSearchParams(createSearchParams(searchParams), { replace: true })
      setSelectedMenu(ProfileNavTabs.accountDetails)
    }
  }, [selectedTab, preventProfileAccess, searchParams, setSearchParams])

  const [publicDetails, setPublicDetails] = useState<Profile>({
    id: 1,
    curriculumBoard: [],
    name: "",
    website: "",
    email: "",
    branch: null,
    phoneNumber: null,
    address: "",
    description: "",
    logo: null,
    city: null,
    pincode: null,
    coverImage: null,
    galleryCount: 0,
    dateEstablished: null,
    workingDays: null,
    dayStartTime: "00:00",
    dayEndTime: "00:00",
    teachingMode: null,
    isVerified: true,
    slug: "",
    country: null,
    state: null,
  })

  const [accountDetails, setAccountDetails] = useState<User>({
    product: 2,
    email: "",
    firstName: "",
    middleName: "",
    lastName: "",
    fullName: "",
    phoneNumber: null,
  })

  useEffect(() => {
    async function getData() {
      setLoading(true)

      const promises = [
        api.school.retrieve({
          params: {
            fields: "curriculum_board",
          },
        }),
        api.users.retrieveUser(),
      ] as const

      const [publicDetailsRes, accountDetailsRes] = await Promise.all(promises)

      if (publicDetailsRes.isSuccessful) {
        setPublicDetails(publicDetailsRes.data)
      }

      if (accountDetailsRes.isSuccessful) {
        setAccountDetails(accountDetailsRes.data)
      }

      setLoading(false)
    }
    getData()
  }, [])

  return (
    <>
      {!isXs && <Navbar />}

      <Container fullWidth={isXs}>
        <div className={clsx("grid grid-cols-12 gap-3 py-2")}>
          <div
            className={clsx(
              classes.sideBar,
              "col-span-full sm:col-span-4 xl:col-span-3 pt-4 sm:pt-0 sm:flex flex-col",
              { "hidden sm:flex": !showMobileNavigation }
            )}
          >
            <ProfileNavigation
              disableProfile={preventProfileAccess}
              selectedItem={selectedMenu}
              setSelectedItem={setSelectedMenu}
              onClick={() => setShowMobileNavigation(false)}
            />
          </div>
          <div
            className={clsx(
              "col-span-12 sm:col-span-8 xl:col-span-9 pt-8 sm:pt-0 px-2 flex flex-col gap-3",
              { "hidden sm:flex": showMobileNavigation }
            )}
          >
            {loading ? (
              <div
                className={clsx(
                  classes.loading,
                  "flex items-center justify-center"
                )}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                {selectedMenu === ProfileNavTabs.publicDetails && (
                  <PublicDetails
                    data={publicDetails}
                    onMobileBack={() => setShowMobileNavigation(true)}
                    onUpdate={newState =>
                      setPublicDetails(prevState => ({
                        ...prevState,
                        ...newState,
                      }))
                    }
                  />
                )}

                {selectedMenu === ProfileNavTabs.accountDetails && (
                  <AccountDetails
                    data={accountDetails}
                    updateUserPreferences={newState =>
                      setAccountDetails(prevState => ({
                        ...prevState,
                        ...newState,
                      }))
                    }
                    onMobileBack={() => setShowMobileNavigation(true)}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </Container>
    </>
  )
}

export default EditProfile
