import { IconButton } from "@suraasa/placebo-ui"
import { ReactComponent as FacebookIcon } from "assets/icons/facebook-icon.svg"
import { ReactComponent as LinkedinIcon } from "assets/icons/linkedin-icon.svg"
import { ReactComponent as TelegramIcon } from "assets/icons/telegram-icon.svg"
import { ReactComponent as WhatsappIcon } from "assets/icons/whatsapp-icon.svg"
import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"

import { isMobileOrTablet, popupWindow } from "./helpers"

const useStyles = createUseStyles(theme => ({
  socialIcon: {
    borderRadius: "50%",
    border: `1px solid ${theme.colors.onSurface[300]}`,
    marginRight: theme.spacing(2),
    height: theme.spacing(5),
    width: theme.spacing(5),
    padding: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1.5),
      height: theme.spacing(7),
      width: theme.spacing(7),
    },
  },
}))

export type Props = {
  data: {
    url: string
    text: string
    platformSpecificText?: {
      linkedin?: string
      whatsapp?: string
      telegram?: string
      facebook?: string
    }
  }
}

const SocialIcons = ({
  className,
  data: { url, text, platformSpecificText },
}: Props & { className?: string }) => {
  const classes = useStyles()

  const LINKEDIN_URL = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    url
  )}`

  const FACEBOOK_URL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    url
  )}&quote=${encodeURIComponent(platformSpecificText?.facebook || text)}`

  const WHATSAPP_URL = `https://${
    isMobileOrTablet() ? "api" : "web"
  }.whatsapp.com/send?text=${encodeURIComponent(
    platformSpecificText?.whatsapp || text
  )}`

  const TELEGRAM_URL = `https://telegram.me/share/url?url=${encodeURIComponent(
    platformSpecificText?.telegram || text
  )}`

  return (
    <div className={clsx("flex mb-3 sm:mb-6", className)}>
      <IconButton
        className={clsx(classes.socialIcon, "SocialIcons-linkedin")}
        onClick={() => popupWindow(LINKEDIN_URL)}
      >
        <LinkedinIcon />
      </IconButton>

      <IconButton
        className={clsx(classes.socialIcon, "SocialIcons-facebook")}
        onClick={() => popupWindow(FACEBOOK_URL)}
      >
        <FacebookIcon />
      </IconButton>
      <IconButton
        className={clsx(classes.socialIcon, "SocialIcons-whatsapp")}
        onClick={() => popupWindow(WHATSAPP_URL, 650, 650)}
      >
        <WhatsappIcon />
      </IconButton>

      <IconButton
        className={clsx(classes.socialIcon, "SocialIcons-telegram")}
        onClick={() => popupWindow(TELEGRAM_URL)}
      >
        <TelegramIcon />
      </IconButton>
    </div>
  )
}

export default SocialIcons
