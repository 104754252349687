import {
  CircularProgress,
  Container,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  Amenity,
  Gallery as GalleryType,
  Perk,
  Profile as ProfileType,
} from "api/resources/profile/types"
import { GlobalContext } from "components/GlobalState"
import Cover from "components/profile/Cover"
import Gallery from "components/profile/Gallery"
import JobOpenings from "components/profile/JobOpenings"
import Overview from "components/profile/Overview"
import PerksAndAmenities from "components/profile/PerksAndAmenities"
import Navbar from "components/shared/Navbar"
import React, { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { useNavigate } from "react-router-dom"
import { getAuthInfo } from "utils/auth"
import { defaultProfile } from "utils/constants"
import useArray from "utils/hooks/useArray"
import { routes } from "utils/routes"

import ProfileContext from "./context"

const useStyles = createUseStyles({
  container: () => ({
    position: "relative",
    paddingBottom: theme.spacing(2),
  }),
})

const Profile = ({
  hideNavbar = false,
  bypassVerificationCheck = false,
}: {
  hideNavbar?: boolean
  bypassVerificationCheck?: boolean
}) => {
  const { isVerified } = useContext(GlobalContext)
  const navigate = useNavigate()

  const [profile, setProfile] = useState<ProfileType>(defaultProfile)
  const [loading, setLoading] = useState(true)
  const gallery = useArray<GalleryType>([])
  const amenities = useArray<Amenity>([])
  const perks = useArray<Perk>([])

  const classes = useStyles()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))

  useEffect(() => {
    async function getProfile() {
      setLoading(true)

      const res = await api.profile.retrieve({
        params: {
          fields: [
            "gallery",
            "curriculum_board",
            "amenities",
            "perks",
            "management_message",
            "gallery_count",
          ],
          photos: 20,
        },
      })

      if (res.isSuccessful) {
        setProfile(res.data)

        if (res.data.gallery) {
          gallery.set(res.data.gallery)
        }
        if (res.data.amenities) {
          amenities.set(res.data.amenities)
        }
        if (res.data.perks) {
          perks.set(res.data.perks)
        }
        setLoading(false)
      }
    }

    if (!isVerified && !bypassVerificationCheck) {
      // Don't let user open profile if it is not verified by anyone
      navigate(routes.profileVerification, { replace: true })
    } else {
      getProfile()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ProfileContext.Provider
      value={{
        isPublic: !getAuthInfo(),
        profile,
        updateProfile: newState => {
          setProfile(prevState => ({
            ...prevState,
            ...newState,
          }))
        },
        gallery: {
          data: gallery.array,
          update: gallery.updateByKey,
          remove: gallery.removeByKey,
          add: gallery.push,
          set: gallery.set,
        },
        perks: {
          data: perks.array,
          update: perks.updateByKey,
          remove: perks.removeByKey,
          add: perks.push,
          set: perks.set,
        },
        amenities: {
          data: amenities.array,
          update: amenities.updateByKey,
          remove: amenities.removeByKey,
          add: amenities.push,
          set: amenities.set,
        },
      }}
    >
      <div>
        {!hideNavbar && <Navbar gutterBottom={false} />}

        {loading ? (
          <div className="flex justify-center my-20">
            <CircularProgress />
          </div>
        ) : (
          <>
            <Cover isXs={isXs} />
            {isXs ? (
              <div className={classes.container}>
                <Overview />
                <Gallery />
                <JobOpenings />
                <PerksAndAmenities />
              </div>
            ) : (
              <Container className={classes.container}>
                <Overview />
                <Gallery />
                <JobOpenings />
                <PerksAndAmenities />
              </Container>
            )}
          </>
        )}
      </div>
    </ProfileContext.Provider>
  )
}

export default Profile
