import { Button, Menu, MenuItem, Typography } from "@suraasa/placebo-ui"
import { ReactComponent as AddStep } from "assets/stepper/add-step.svg"
import { ReactComponent as DisabledAddStep } from "assets/stepper/disabled-add-step.svg"
import { ReactComponent as NotCompleted } from "assets/stepper/not-completed.svg"
import clsx from "clsx"
import TimelineItem from "components/teacher/Timeline/TimelineItem"
import TimelineComponent from "components/Timeline"
import StepContent from "components/Timeline/TimelineContent"
import React, { useContext } from "react"
import { createUseStyles } from "react-jss"
import { useParams } from "react-router-dom"
import useToggle from "utils/hooks/useToggle"
import TeacherTimelineContext from "views/teacher/context"

import AddAssessmentDialog from "../Dialogs/AddAssessmentDialog"
import EditInterviewDialog from "../Dialogs/EditInterviewDialog"
import EditLiveDemoDialog from "../Dialogs/EditLiveDemoDialog"

const useStyles = createUseStyles({
  addStep: {
    border: "none",
    marginTop: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
})

const Timeline = () => {
  const classes = useStyles()

  const { timeline, actionsAllowed } = useContext(TeacherTimelineContext)
  const { jobApplicantId } = useParams()

  const [openInterviewDialog, toggleOpenInterviewDialog] = useToggle(false)
  const [openLiveDemo, toggleOpenLiveDemo] = useToggle(false)
  const [openAssessment, toggleOpenAssessment] = useToggle(false)

  const SetupInterview = () => (
    <StepContent icon={<NotCompleted />}>
      <div className="flex flex-col" style={{ width: "100%" }}>
        <Typography
          className="flex-grow"
          color="onSurface.800"
          variant="strong"
        >
          Interview
        </Typography>
        <Typography color="onSurface.400" variant="smallBody">
          Interview
        </Typography>
        <Button
          className="mt-2 self-end"
          disabled={!actionsAllowed}
          onClick={() => toggleOpenInterviewDialog()}
        >
          Setup Interview
        </Button>
      </div>
    </StepContent>
  )

  const emptyTimeline = timeline.data.length === 0
  return (
    <>
      {openAssessment && jobApplicantId && (
        <AddAssessmentDialog
          afterSubmit={data => timeline.add(data)}
          jobApplicant={jobApplicantId}
          open={openAssessment}
          toggle={toggleOpenAssessment}
        />
      )}

      <EditLiveDemoDialog
        afterSubmit={data => timeline.add(data)}
        jobApplicant={jobApplicantId}
        open={openLiveDemo}
        toggle={toggleOpenLiveDemo}
      />

      <EditInterviewDialog
        afterSubmit={data => timeline.add(data)}
        jobApplicant={jobApplicantId}
        open={openInterviewDialog}
        toggle={toggleOpenInterviewDialog}
      />

      <TimelineComponent steps={timeline.data}>
        <StepContent
          className={clsx(classes.addStep)}
          icon={actionsAllowed ? <AddStep /> : <DisabledAddStep />}
        >
          <Menu
            menuButton={
              <Button disabled={!actionsAllowed} variant="text">
                Add Step
              </Button>
            }
          >
            <MenuItem onClick={() => toggleOpenInterviewDialog()}>
              Interview
            </MenuItem>
            <MenuItem onClick={() => toggleOpenLiveDemo()}>Live Demo</MenuItem>
            <MenuItem onClick={() => toggleOpenAssessment()}>
              Assessment
            </MenuItem>
          </Menu>
        </StepContent>

        {emptyTimeline && <SetupInterview />}
        {timeline.data.map(step => (
          <TimelineItem {...step} key={step.id} />
        ))}
      </TimelineComponent>
    </>
  )
}

export default Timeline
