import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getNebulaURL = getServiceURL(ServicePrefix.nebula)

export const urls = validateUrls({
  profile: {
    retrieve: () => getNebulaURL(`/v1/schools/profile/`),
    update: () => getNebulaURL("/v1/schools/"),
  },
  managementMessage: {
    base: () => getNebulaURL("/v1/schools/management-messages/"),
  },

  perks: {
    list: () => getNebulaURL("/v1/schools/perks/"),
    update: () => getNebulaURL("/v1/schools/perks/"),
    delete: perkId => getNebulaURL(`/v1/schools/perks/${perkId}/`),
  },
  amenities: {
    list: () => getNebulaURL("/v1/schools/amenities/"),
    update: () => getNebulaURL("/v1/schools/amenities/"),
    delete: amenityId => getNebulaURL(`/v1/schools/amenities/${amenityId}/`),
  },

  gallery: {
    list: () => getNebulaURL("/v1/schools/gallery/"),
    update: () => getNebulaURL(`/v1/schools/gallery/`),
    delete: id => getNebulaURL(`/v1/schools/gallery/${id}/`),
  },
})
