import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
  videoContainer: {
    width: "100%",
    maxHeight: "60vh",
  },
})

const ViewPortfolio = ({ videoURL }: { videoURL: string | null }) => {
  const classes = useStyles()

  return (
    <div>
      <video className={classes.videoContainer} controls>
        <track kind="captions" label="english_captions" />
        <source src={videoURL ?? ""} type="video/mp4" />
      </video>
    </div>
  )
}

export default ViewPortfolio
