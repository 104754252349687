import {
  Button,
  Checkbox,
  IconButton,
  TextField,
  theme,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Assessment, AssessmentQuestion } from "api/resources/assessments/types"
import clsx from "clsx"
import { Cancel, Plus, Trash } from "iconoir-react"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import {
  getCorrectOptions,
  getNewOption,
  getValidOptions,
} from "views/assessments/helpers"

const useStyles = createUseStyles({
  questionCard: {
    background: "white",
    boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      borderRadius: 0,
    },
  },

  selectedCard: {
    boxShadow: `1px 1px 5px rgba(0, 0, 0, 0.1),-4.5px 0 0 -1px ${theme.colors.primary[500]}`,
  },
  errorCard: {
    boxShadow: `1px 1px 5px rgba(0, 0, 0, 0.1),-4.5px 0 0 -1px ${theme.colors.critical[500]}`,
  },
  optionLabel: {
    cursor: "default",
    border: "none",
    fontSize: theme.spacing(2),
    background: "none",
    width: "100%",
  },
  optionLabelInteractions: {
    cursor: "auto",
    "&:hover": {
      boxShadow: `0px 1px 0 ${theme.colors.onSurface[400]}`,
    },
    "&:focus": {
      outline: "none",
      boxShadow: `0px 1px 0 ${theme.colors.onSurface[400]}`,
    },
  },
  optionContainer: {
    borderRadius: theme.spacing(0.5),
    width: "100%",
  },
  correctOption: {
    backgroundColor: theme.colors.success[50],
  },
})

type Props = {
  assessment: {
    id?: Assessment["id"]
    isActive: Assessment["isActive"]
    create: () => Promise<Assessment | undefined>
    created: boolean
  }
  setIsSaving: (value: boolean) => void
  onUpdate: (value: AssessmentQuestion) => void
  onRemove: () => void
  question: AssessmentQuestion
  questionNumber: number
  xsDown: boolean
}

function CreateQuestionCard({
  assessment,
  onRemove,
  onUpdate,
  questionNumber,
  question,
  setIsSaving,
  xsDown,
}: Props) {
  const classes = useStyles()
  const [isFocused, setIsFocused] = useState(false)

  const node = useRef<HTMLDivElement | null>(null)

  const { control, register, handleSubmit, watch, setValue } =
    useForm<AssessmentQuestion>({
      defaultValues: {
        options: question.options,
        text: question.text,
      },
    })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
  })

  const updatedFields = watch()

  const onSubmit = handleSubmit(async formData => {
    setIsSaving(true)

    const validOptions = getValidOptions(formData.options)
    setValue(
      "options",
      validOptions.length > 0 ? validOptions : [getNewOption()]
    )

    let assessmentId
    if (!assessment.created) {
      const res = await assessment.create()
      if (!res) throw new Error("Failed to create assessment")
      assessmentId = res.id
    } else {
      assessmentId = assessment.id
    }

    if (assessment.isActive) {
      onUpdate(formData)
      setIsSaving(false)
      return
    }

    const apiData = {
      assessment: assessmentId,
      text: formData.text,
      options: validOptions.map(({ text }) => text),
      correctAnswer: getCorrectOptions(validOptions),
    }

    // Update
    if (question.isCreated) {
      // Not awaiting intentionally
      api.assessments.questions.update({
        data: apiData,
        urlParams: {
          questionId: question.id,
        },
      })
      onUpdate(formData)
      setIsSaving(false)

      return
    }

    // Create
    // Not awaiting intentionally
    api.assessments.questions
      .create({
        data: apiData,
      })
      .then(res => {
        if (res.isSuccessful) {
          onUpdate({
            ...formData,
            id: res.data.id,
            isCreated: true,
          })
        }
      })

    onUpdate(formData)
    setIsSaving(false)
  })

  const handleClick = useCallback(
    (e: any) => {
      if (node.current) {
        if (node.current.contains(e.target)) {
          setIsFocused(true)
          return
        }
        if (isFocused) {
          onSubmit()
        }
        return setIsFocused(false)
      }
    },
    [isFocused, onSubmit]
  )

  const handleAdd = () => {
    if (fields.length >= 10) return

    append(getNewOption(), {
      focusName: `options.${fields.length}.text`,
    })
  }

  useEffect(() => {
    document.addEventListener("mouseup", handleClick)
    return () => {
      document.removeEventListener("mouseup", handleClick)
    }
  }, [handleClick])

  return (
    <div
      className={clsx(classes.questionCard, "px-2 py-3 mt-2", {
        [classes.selectedCard]: isFocused,
        [classes.errorCard]: question.errors.length > 0,
      })}
      ref={node}
    >
      <Typography className="mb-1" variant="strongSmallBody">
        Question {questionNumber}
      </Typography>
      {/* @ts-expect-error placebo-issue */}
      <TextField
        className="mb-2"
        placeholder="Type Your Question Here"
        rows={xsDown ? 4 : 2}
        fullWidth
        multiLine
        {...register("text")}
      />

      {isFocused && (
        <Typography className="mb-1.25" color="onSurface.500">
          Add options and mark correct answer(s)
        </Typography>
      )}

      <form className="flex flex-col gap-1" onSubmit={onSubmit}>
        {fields.map((field, index) => (
          <div className="flex items-center" key={field.id}>
            <div
              className={clsx(
                classes.optionContainer,
                "flex items-center pt-0.5 px-0.5",
                {
                  [classes.correctOption]:
                    updatedFields.options[index].isCorrect,
                }
              )}
            >
              <Controller
                control={control}
                name={`options.${index}.isCorrect`}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Checkbox
                    checked={value}
                    className="pb-0.25" // notify when input is touched
                    color="success" // send value to hook form
                    defaultChecked={undefined}
                    ref={ref}
                    onBlur={onBlur}
                    onChange={onChange}
                  />
                )}
              />

              <input
                {...register(`options.${index}.text`)}
                className={clsx(classes.optionLabel, "ml-1.25 pb-0.5", {
                  [classes.optionLabelInteractions]: isFocused,
                })}
                placeholder="Option"
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    e.preventDefault()
                    handleAdd()
                  }
                }}
              />
            </div>

            {isFocused && (
              <IconButton
                className="ml-4.5"
                color="critical"
                disabled={fields.length < 2}
                onClick={() => remove(index)}
              >
                <Cancel />
              </IconButton>
            )}
          </div>
        ))}
      </form>

      {isFocused && (
        <div className="flex items-center justify-between mt-2">
          <Button
            disabled={fields.length >= 10}
            startAdornment={<Plus />}
            variant="text"
            onClick={handleAdd}
          >
            Add Option
          </Button>
          <Button
            color="critical"
            startAdornment={<Trash />}
            variant="text"
            onClick={() => {
              onRemove()
              setIsFocused(false)
            }}
          >
            Remove Question
          </Button>
        </div>
      )}

      {question.errors.length > 0 && (
        <div className="mt-2">
          {question.errors.map((error, i) => (
            <Typography color="critical.500" key={i} variant="smallBody">
              {error}
            </Typography>
          ))}
        </div>
      )}
    </div>
  )
}

export default CreateQuestionCard
