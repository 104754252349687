import {
  Checkbox,
  CircularProgress,
  Select,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Currency, Subject } from "api/resources/global/types"
import { Job } from "api/resources/jobs/types"
import clsx from "clsx"
import React, { useEffect } from "react"
import {
  Control,
  Controller,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form"
import { createUseStyles } from "react-jss"
import { useParams } from "react-router-dom"
import { getAuthInfo } from "utils/auth"

export type CreateJobType = {
  position: string
  subject?: Subject
  currency?: Currency
  salaryOffered: number
  maximumSalaryOffered: number | null
  description: string
  makeSalaryRange?: boolean
}
const useStyles = createUseStyles({
  hide: {
    display: "none",
  },
})

type Props = {
  errors: any
  control: Control<CreateJobType, Record<string, unknown>>
  register: UseFormRegister<CreateJobType>
  setValue: UseFormSetValue<CreateJobType>
  currencies: Currency[]
  currencySymbol?: string
  job?: Job
  loading: boolean
  makeSalaryRange: boolean | undefined
  subjects: Subject[]
  setSubjectOptions: React.Dispatch<React.SetStateAction<Subject[]>>
}

const CreateJob = ({
  control,
  errors,
  register,
  setValue,
  currencySymbol,
  loading,
  subjects,
  currencies,
  makeSalaryRange,
  setSubjectOptions,
}: Props) => {
  const { jobId } = useParams()
  const classes = useStyles()

  subjects = subjects.map(s => ({ ...s, label: s.name, value: s.uuid }))

  async function createSubject(subject: string) {
    return api.global.subjects.create({
      data: {
        new: [
          {
            subject,
            userUuid: getAuthInfo()?.user.uuid,
          },
        ],
      },
    })
  }

  useEffect(() => {
    if (!makeSalaryRange) {
      setValue("maximumSalaryOffered", null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makeSalaryRange])

  return (
    <>
      {loading ? (
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div className="grid grid-cols-8 gap-3 mb-3">
            <TextField
              autoComplete="off"
              className="col-span-12 md:col-span-4"
              disabled={Boolean(jobId)}
              error={Boolean(errors.position)}
              helperText={errors.position?.message}
              label="Position"
              placeholder="Ex: Head of Department"
              fullWidth
              {...register("position", {
                required: { value: true, message: "Required" },
              })}
            />

            <Controller
              control={control}
              name="subject"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  className="col-span-12 md:col-span-3"
                  error={Boolean(errors.subject)}
                  helperText={errors.subject?.message}
                  isDisabled={Boolean(jobId)}
                  label="Subject Expertise"
                  options={subjects}
                  placeholder="Ex: Physics"
                  value={
                    value ? subjects.find(c => c.uuid === value.uuid) : null
                  }
                  createable
                  fullWidth
                  onBlur={onBlur}
                  onChange={async (option, actionMeta) => {
                    onChange(option)
                    if (actionMeta.action === "create-option") {
                      if (option && "value" in option) {
                        // @ts-expect-error newly created options do have a `value` property
                        const res = await createSubject(option.value)
                        if (res.isSuccessful) {
                          const subject = res.data[0]
                          setSubjectOptions(i => [subject, ...i])
                          setValue("subject", subject)
                        }
                      }
                    }

                    if (actionMeta.action === "clear") {
                      setValue("subject", undefined)

                      setSubjectOptions(subjects.map(item => item))
                    }
                  }}
                />
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />
          </div>
          <Typography variant="preTitle">Salary Offered (Annual)</Typography>
          <div className="grid grid-cols-12 gap-1 mt-1 mb-3">
            <Controller
              control={control}
              name="currency"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  className="col-span-12 md:col-span-2"
                  error={Boolean(errors.currency)}
                  getOptionLabel={item => `${item.code} (${item.symbol})`}
                  getOptionValue={({ uuid }) => uuid}
                  helperText={errors.currency?.message}
                  isDisabled={Boolean(jobId)}
                  options={currencies}
                  placeholder="INR (₹)"
                  value={
                    value
                      ? currencies.find(({ uuid }) => uuid === value.uuid)
                      : null
                  }
                  fullWidth
                  onBlur={onBlur}
                  onChange={val => {
                    if (val) {
                      onChange(val)
                    }
                  }}
                />
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />

            <TextField
              autoComplete="off"
              className={clsx("col-span-12 md:col-span-4")}
              endIcon={
                <Typography
                  className="mr-2"
                  color="onSurface.500"
                  variant="smallBody"
                >
                  /year
                </Typography>
              }
              error={Boolean(errors.salaryOffered)}
              helperText={errors.salaryOffered?.message}
              placeholder="Ex: 10,00,000"
              startAdornment={currencySymbol}
              type="number"
              fullWidth
              {...register("salaryOffered", {
                required: { value: true, message: "Required" },
              })}
            />

            <div
              className={clsx(
                "col-span-12 md:col-span-4 flex flex-col md:flex-row items-center gap-1 ",
                {
                  [classes.hide]: !makeSalaryRange,
                }
              )}
            >
              <Typography className="" variant="smallBody">
                to
              </Typography>
              <TextField
                autoComplete="off"
                className={clsx("col-span-12 md:col-span-4")}
                endIcon={
                  <Typography
                    className="mr-2"
                    color="onSurface.500"
                    variant="smallBody"
                  >
                    /year
                  </Typography>
                }
                error={Boolean(errors.maximumSalaryOffered)}
                helperText={errors.maximumSalaryOffered?.message}
                placeholder="Ex: 10,00,000"
                startAdornment={currencySymbol}
                type="number"
                fullWidth
                {...register("maximumSalaryOffered", {
                  required: {
                    value: Boolean(makeSalaryRange),
                    message: "Required",
                  },
                })}
              />
            </div>
          </div>
          <Checkbox
            className="mb-3.5"
            label="Make this salary a range"
            {...register("makeSalaryRange")}
          />
          {/* @ts-expect-error placebo-issue */}
          <TextField
            className="col-span-12"
            label="Job Description"
            placeholder="Add any other details about the job that you would like candidates to see"
            rows={5}
            fullWidth
            multiLine
            {...register("description")}
          />
        </div>
      )}
    </>
  )
}

export default CreateJob
