import {
  Accordion,
  AccordionDetails,
  AccordionHeader,
  Avatar,
  Button,
  CircularProgress,
  Pagination,
  Tag,
  TagColors,
  Theme,
  Tooltip,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { InvitationStatus, InvitedUser, Job } from "api/resources/jobs/types"
import { PaginatedResponse } from "api/types"
import { ReactComponent as VerifiedBadge } from "assets/home/verifiedBadge.svg"
import clsx from "clsx"
import { GlobalContext } from "components/GlobalState"
import RejectApplicationDialog from "components/teacher/Dialogs/RejectApplicationDialog"
import { differenceInSeconds, format } from "date-fns"
import { WarningCircle } from "iconoir-react"
import React, { useContext, useEffect, useState } from "react"
import { createUseStyles, useTheme } from "react-jss"
import { Link } from "react-router-dom"
import { formatWorkDuration, pluralize } from "utils/helpers"
import { routes } from "utils/routes"
import toast from "utils/toast"

import NoDataCard from "./NoDataCard"

const useStyles = createUseStyles(theme => ({
  root: {
    overflowX: "auto",
  },

  container: {
    background: "white",
    borderRadius: "4px",
    border: `1px solid ${theme.colors.surface[200]}`,
    minWidth: "800px",
  },

  tableHeaderGrid: {
    display: "grid",
    gridTemplateColumns: "94px 1.25fr 1fr 1fr 0.7fr 1fr",
  },
  tableContentGrid: {
    display: "grid",
    gridTemplateColumns: "48px 1.25fr 1fr 1fr 0.7fr 1fr",
  },

  tableItem: {
    position: "relative",
  },

  accordionHeader: {
    padding: 0,
    borderTop: `1px solid ${theme.colors.surface[200]}`,

    "& > .AccordionHeader-icon": {
      padding: theme.spacing(0, 1.5),
    },
    "& > .AccordionHeader-content": {
      flexGrow: 1,
    },
  },

  accordionDetails: {
    padding: theme.spacing(3, 0),
    borderTop: `1px solid ${theme.colors.surface[200]}`,
  },

  image: {
    width: "32px",
    height: "32px",
  },

  textSmall: {
    fontSize: "12px",
    lineHeight: "15.6px",
  },
}))

type Props = {
  job: Pick<Job, "position" | "id">
  isClosed?: boolean
}

const getTag = (
  isExpired: boolean,
  status: InvitationStatus | null
): { color: TagColors; text: string } => {
  if (isExpired) {
    return { color: "onSurface", text: "Expired" }
  }
  switch (status) {
    case InvitationStatus.NOT_SENT:
      return { color: "critical", text: "Not sent" }
    case InvitationStatus.PENDING:
      return { color: "warning", text: "Pending" }
    case InvitationStatus.ACCEPTED:
      return { color: "primary", text: "Accepted" }
    case InvitationStatus.REJECTED:
      return { color: "critical", text: "Not Interested" }
    case InvitationStatus.REVOKED:
      return { color: "success", text: "Revoked" }
    case InvitationStatus.CANCELLED:
      return { color: "onSurface", text: "Expired" }
    default:
      return { color: "primary", text: "" }
  }
}

const isExpired = (user: InvitedUser) =>
  differenceInSeconds(new Date(user.invitationExpiresAt), new Date()) <= 0

const InvitedTab = ({ job, isClosed }: Props) => {
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)

  const theme = useTheme<Theme>()

  const { isVerified } = useContext(GlobalContext)

  const [applicants, setApplicants] = useState<
    PaginatedResponse<InvitedUser[]>["data"]
  >({
    data: [],
    nextPage: null,
    previousPage: null,
    total: 0,
  })

  const [inviteToReject, setInviteToReject] = useState<InvitedUser | null>(null)

  const listApplicants = async () => {
    const res = await api.jobs.jobApplicant.listInvited({
      urlParams: {
        jobId: job.id,
      },
      params: {
        page,
      },
    })
    if (res.isSuccessful) {
      setApplicants(res.data)
      setLoading(false)
    }
  }
  const reInvite = async (item: InvitedUser | null) => {
    const res = await api.jobs.jobApplicant.create({
      urlParams: {
        jobId: job.id,
      },
      data: {
        user: item?.user.uuid,
      },
    })

    if (res.isSuccessful) {
      listApplicants()
      toast.success("Candidate Reinvited!")
    } else {
      toast.error(res.errors.message)
    }
  }

  const removeApplicantFromList = () => {
    setApplicants(item => ({
      ...item,
      data: item.data.filter(v => v.id !== inviteToReject?.id),
    }))
  }
  const classes = useStyles()

  useEffect(() => {
    if (job.id) listApplicants()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job.id, page])

  if (loading)
    return (
      <div className="flex justify-center mt-5">
        <CircularProgress />
      </div>
    )

  if (applicants.data.length === 0)
    return (
      <NoDataCard
        className="mt-3"
        message="You have not invited any teachers. Head to the 'Explore Teachers' page to check out teachers profiles and invite them to this job."
      />
    )

  const getInvitationAction = (user: InvitedUser) => {
    if (
      user.invitationStatus === InvitationStatus.CANCELLED ||
      isExpired(user)
    ) {
      return (
        <Button
          variant="text"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            reInvite(user)
          }}
        >
          Reinvite
        </Button>
      )
    }
    if (user.invitationStatus === InvitationStatus.PENDING) {
      return (
        <Button
          color="critical"
          variant="text"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            setInviteToReject(user)
          }}
        >
          Revoke
        </Button>
      )
    }

    return null
  }

  return (
    <>
      <div className={clsx(classes.root, "my-3")}>
        <div className={classes.container}>
          <div className={clsx(classes.tableHeaderGrid, "py-2")}>
            <div />
            <Typography variant="strongSmallBody">Name</Typography>
            <Typography variant="strongSmallBody">Status</Typography>
            <Typography variant="strongSmallBody">Invite Date</Typography>
          </div>

          {applicants.data.map((item, index) => {
            const tag = getTag(isExpired(item), item.invitationStatus)

            return (
              <Accordion key={index}>
                <AccordionHeader
                  className={classes.accordionHeader}
                  iconPosition="start"
                >
                  <div
                    className={clsx(
                      classes.tableContentGrid,
                      classes.tableItem,
                      "py-2"
                    )}
                  >
                    <div className="flex items-center mr-2 justify-end">
                      <Avatar
                        name={item.user.fullName}
                        size="xs"
                        src={item.user.profile.picture ?? undefined}
                      />
                    </div>

                    <div className="flex items-center">
                      <div className="flex items-center gap-0.5">
                        <Button
                          component={Link}
                          to={`${routes.teacherProfile.replace(
                            ":username",
                            item.user.username
                          )}?jobApplicantId=${item.id}`}
                          variant="link"
                        >
                          <Typography
                            color="interactive.600"
                            display="inline"
                            variant="smallBody"
                          >
                            {item.user.fullName}
                          </Typography>
                        </Button>
                        {item.user.profile.isVerified && <VerifiedBadge />}
                      </div>
                    </div>

                    <div className="flex items-center">
                      <Tag color={tag.color} label={tag.text} />
                      {!isVerified &&
                        item.invitationStatus === InvitationStatus.NOT_SENT && (
                          <Tooltip title="This invitation will be sent when your profile is verified">
                            <WarningCircle
                              className="ml-1"
                              color={theme.colors.critical[500]}
                            />
                          </Tooltip>
                        )}
                    </div>

                    <div className="flex flex-col justify-center">
                      {item.dateApplied ? (
                        <>
                          <Typography
                            textAlign="left"
                            variant="strongSmallBody"
                          >
                            {format(new Date(item.dateApplied), "d MMM yyyy")}
                          </Typography>
                          <Typography textAlign="left" variant="smallBody">
                            {format(new Date(item.dateApplied), "hh:mm aa")}
                          </Typography>
                        </>
                      ) : (
                        <Typography textAlign="left" variant="smallBody">
                          -
                        </Typography>
                      )}
                    </div>
                    {!isClosed && (
                      <>
                        <div className="flex flex-col items-end justify-center">
                          {Boolean(item.applications) && (
                            <Typography
                              color="onSurface.500"
                              variant="smallBody"
                            >
                              Interviewing with <b>{item.applications}</b> other{" "}
                              {pluralize("school", item.applications, {
                                skipCount: true,
                              })}
                            </Typography>
                          )}
                        </div>

                        <div className="flex items-center justify-end mr-2">
                          {getInvitationAction(item)}
                        </div>
                      </>
                    )}
                  </div>
                </AccordionHeader>

                <AccordionDetails className={classes.accordionDetails}>
                  <div className="ml-5 flex gap-6">
                    <div>
                      {item.user.workExperiences.salaryDrawn ? (
                        <Typography variant="strongSmallBody">
                          {item.user.workExperiences.currency?.symbol}
                          {item.user.workExperiences.salaryDrawn?.toLocaleString()}
                        </Typography>
                      ) : (
                        <Typography variant="strongSmallBody">N/A</Typography>
                      )}

                      <Typography color="onSurface.500" variant="smallBody">
                        Last Drawn Salary
                      </Typography>
                    </div>

                    <div>
                      {item.user.workExperiences.daysOfExperience ? (
                        <Typography variant="strongSmallBody">
                          {formatWorkDuration(
                            item.user.workExperiences.daysOfExperience
                          )}
                        </Typography>
                      ) : (
                        <Typography variant="strongSmallBody">N/A</Typography>
                      )}

                      <Typography color="onSurface.500" variant="smallBody">
                        Experience
                      </Typography>
                    </div>

                    <div>
                      {item.user.workExperiences.noOfJobs ? (
                        <Typography variant="strongSmallBody">
                          {item.user.workExperiences.noOfJobs}
                        </Typography>
                      ) : (
                        <Typography variant="strongSmallBody">N/A</Typography>
                      )}

                      <Typography color="onSurface.500" variant="smallBody">
                        Number of Jobs
                      </Typography>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </div>
      </div>

      <Pagination
        page={page}
        total={applicants.total}
        onChange={pg => setPage(pg)}
      />

      {inviteToReject && (
        <RejectApplicationDialog
          afterSubmit={removeApplicantFromList}
          jobApplicantId={inviteToReject.id}
          jobPosition={job.position}
          mode="invitation"
          open={Boolean(inviteToReject)}
          toggle={() => setInviteToReject(null)}
          userName={inviteToReject.user.fullName}
        />
      )}
    </>
  )
}
export default InvitedTab
