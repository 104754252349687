import {
  Button,
  CircularProgress,
  Container,
  Pagination,
  Tag,
  theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import { Assessment } from "api/resources/assessments/types"
import { PaginatedResponse } from "api/types"
import clsx from "clsx"
import NoDataCard from "components/jobs/NoDataCard"
import RemoveDialog from "components/RemoveDialog"
import Navbar from "components/shared/Navbar"
import React, { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"
import { pluralize } from "utils/helpers"
import { routes } from "utils/routes"
import toast from "utils/toast"

const useStyles = createUseStyles({
  actionContainer: {
    background: "white",
    borderRadius: theme.spacing(0.5),
  },
  dataContainer: {
    width: "100%",
  },
  assessmentName: {
    width: "180px",
  },
})

function HiringTools() {
  const classes = useStyles()

  const isXs = useMediaQuery(theme.breakpoints.only("xs"))

  const [loading, setLoading] = useState(true)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const [page, setPage] = useState(1)
  const [deleteConfirmationId, setDeleteConfirmationId] = useState<
    number | null
  >(null)

  const [assessments, setAssessments] = useState<
    PaginatedResponse<Assessment[]>["data"]
  >({
    data: [],
    nextPage: null,
    previousPage: null,
    total: 0,
  })
  useEffect(() => {
    const listAssessments = async () => {
      setLoading(true)
      const res = await api.assessments.list({
        params: {
          page,
        },
      })
      if (res.isSuccessful) {
        setAssessments(res.data)
      }
      setLoading(false)
    }

    listAssessments()
  }, [page])

  const deleteAssessment = async () => {
    if (!deleteConfirmationId) return null

    setDeleteLoading(true)

    const res = await api.assessments.delete({
      urlParams: {
        assessmentId: deleteConfirmationId,
      },
    })
    if (res.isSuccessful) {
      toast.success("Assessment deleted")
      setDeleteConfirmationId(null)
      setAssessments(prevState => ({
        ...prevState,
        data: prevState.data.filter(({ id }) => id !== deleteConfirmationId),
      }))
    }
    setDeleteLoading(false)
  }

  return (
    <>
      <Navbar />
      <Container className="mb-3">
        <div className="flex items-center gap-2 sm:gap-0 justify-between mb-4.5 flex-wrap">
          <Typography variant="title2">Assessments</Typography>
          <Button
            component={Link}
            size={isXs ? "sm" : "md"}
            to={routes.assessmentCreate}
            variant="filled"
          >
            Create New Assessment
          </Button>
        </div>
        {/* eslint-disable-next-line no-nested-ternary */}
        {loading ? (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        ) : assessments.data.length > 0 ? (
          assessments.data.map((item, i) => (
            <div
              className={clsx(
                "flex items-center flex-wrap gap-0.5 px-2 py-1 sm:flex-nowrap md:flex-row sm:justify-between sm:py-2.5 sm:px-3 mt-1 sm:gap-3",
                classes.actionContainer
              )}
              key={i}
            >
              <div
                className={clsx(
                  "flex flex-col sm:flex-row sm:gap-3 sm:items-center",
                  classes.dataContainer
                )}
              >
                <Typography className={classes.assessmentName} variant="strong">
                  {item.title}
                </Typography>
                <div className="flex items-center gap-1 sm:gap-3">
                  <Typography variant="body">
                    {Math.floor(item.duration / 60)} Minutes
                  </Typography>
                  <Typography variant="body">
                    {item.totalNumberOfQuestions
                      ? pluralize("Question", item.totalNumberOfQuestions)
                      : "-"}
                  </Typography>
                  {!item.isActive && <Tag color="warning" label="Draft" />}
                </div>
              </div>

              <div className="flex items-center flex-wrap md:flex-nowrap">
                <Button
                  className="-ml-1 sm:ml-0"
                  component={Link}
                  to={routes.assessmentDetails.replace(
                    ":assessmentId",
                    String(item.id)
                  )}
                  variant="text"
                >
                  View Assessment
                </Button>
                <Button
                  component={Link}
                  to={routes.assessmentEdit.replace(
                    ":assessmentId",
                    String(item.id)
                  )}
                  variant="text"
                >
                  Edit
                </Button>
                <Button
                  color="critical"
                  disabled={item.isActive}
                  variant="text"
                  // startAdornment={<Trash />}
                  onClick={() => setDeleteConfirmationId(item.id)}
                >
                  Delete
                </Button>
              </div>
            </div>
          ))
        ) : (
          <NoDataCard message="You have not created any assessments." />
        )}

        {assessments.total > 10 && (
          <Pagination
            page={page}
            total={assessments.total}
            onChange={setPage}
          />
        )}
      </Container>

      <RemoveDialog
        handleClose={() => setDeleteConfirmationId(null)}
        loading={deleteLoading}
        open={Boolean(deleteConfirmationId)}
        title="Delete assessment?"
        onRemove={() => deleteAssessment()}
      >
        Are you sure you want to delete this assessment?
      </RemoveDialog>
    </>
  )
}

export default HiringTools
