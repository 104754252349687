import { theme } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React, { PropsWithChildren } from "react"
import { PropsWithClassName } from "types"

type Props = {
  icon: React.ReactElement
}

const ListItem = ({
  icon: Icon,
  children,
  className,
}: PropsWithClassName<PropsWithChildren<Props>>) => (
  <div className={clsx("flex gap-0.5 flex-wrap mt-1 items-center", className)}>
    {React.cloneElement(Icon, {
      color: theme.colors.onSurface[400],
      height: "16.67",
      width: "16.67",
    })}
    <div>{children}</div>
  </div>
)

export default ListItem
