export const SEND_OFFER_PARAM = "send-offer"

export const routes = {
  login: "/",
  noSchoolLinked: "/no-school-linked",
  signup: "/signup",
  logout: "/logout",
  resetPassword: "/reset-password/:uuid/:token/:timestamp",
  forgotPassword: "/forgot-password",
  home: "/home",
  explore: "/explore",
  profile: "/profile",
  editProfile: "/profile/edit",
  profileVerification: "/profile-verification",
  help: "/help",
  notifications: "/home/notifications",

  jobCreate: "/job/create",
  jobEdit: "/job/edit/:jobId",
  jobDetails: "/job/:jobId",
  jobClosed: "/job/closed",
  previousJobApplicants: "/job/previous/:jobId",

  assessmentCreate: "/assessment/create",
  assessmentEdit: "/assessment/edit/:assessmentId",
  assessmentDetails: "/assessment/:assessmentId",
  assessmentResult: "/assessment/:jobApplicantStepId/result",
  assessments: "/hiring-tools/assessments",

  teacherInterview: "/teacher/interview",
  teacherTimeline: "/teacher/:username/:jobApplicantId/timeline",
  teacherProfile: "/teacher/profile/:username",

  sendOffer: `/teacher/:username/:jobApplicantId/timeline?${SEND_OFFER_PARAM}=true`,
  viewOffer: `/teacher/:username/offer/:jobOfferId`,
  offerLetterSent: "/teacher/offer-letter-sent",

  publicSkillProfile: {
    root: "/teacher/skill-profile/:username/",
    overview: "/teacher/skill-profile/:username/overview",
    evidences: "/teacher/skill-profile/:username/evidences/:slug",
    evidenceDetails:
      "/teacher/skill-profile/:username/evidence-details/:submissionId",
    verificationProcess:
      "/teacher/skill-profile/:username/verification-process",
  },

  pageNotFound: "/*",
} as const
