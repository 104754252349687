import { IconButton, Tag, Typography } from "@suraasa/placebo-ui"
import { Job } from "api/resources/jobs/types"
import clsx from "clsx"
import { Edit } from "iconoir-react"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  root: {
    width: "100%",
    textAlign: "left",
    padding: theme.spacing(1.5, 2),
    borderRadius: "4px",

    "& .jobPosition": {
      color: theme.colors.onSurface[800],
    },
    "& .jobSubjectName": {
      color: theme.colors.onSurface[500],
    },

    "& > #edit-btn": {
      transition: "opacity 100ms ease",
      opacity: "0",
      visibility: "hidden",
    },

    "&:hover": {
      background: theme.colors.surface[100],

      "& > #edit-btn": {
        opacity: "1",
        visibility: "visible",
      },
    },

    "&:active": {
      background: theme.colors.surface[100],
      outline: `1px solid ${theme.colors.surface[300]}`,
    },

    "&:focus-visible": {
      background: theme.colors.surface[100],
      outline: `1px solid ${theme.colors.surface[300]}`,
    },
  },
  disabled: {
    pointerEvents: "none",
  },
  selected: {
    "& .jobPosition": {
      color: `${theme.colors.primary[700]} !important`,
    },
    "& .jobSubjectName": {
      color: `${theme.colors.primary[600]} !important`,
    },
    background: theme.colors.primary[50],
  },
}))

type Props = {
  className?: string
  selected?: boolean
  job: Pick<Job, "id" | "position" | "subject" | "jobApplicant">
  onEdit: (id: Job["id"]) => void
  onSelect: (id: Job["id"]) => void
}

const JobItem = ({ job, onEdit, onSelect, className, selected }: Props) => {
  const classes = useStyles()

  const isDisabled = Boolean(job.jobApplicant)

  const handleSelect = (id: Job["id"]) => {
    if (isDisabled) return

    onSelect(id)
  }

  return (
    <div
      aria-pressed="false"
      className={clsx(
        classes.root,
        {
          [classes.disabled]: isDisabled,
          [classes.selected]: selected,
        },
        "flex justify-between",
        className
      )}
      role="button"
      tabIndex={isDisabled ? -1 : 0}
      onClick={() => handleSelect(job.id)}
      onKeyDown={e => {
        if (e.key === "Enter") {
          handleSelect(job.id)
        }
      }}
    >
      <div>
        <div className="flex items-center gap-1">
          <Typography
            className="jobPosition"
            color={isDisabled ? "onSurface.400" : "onSurface.800"}
            variant="strong"
          >
            {job.position}
          </Typography>
          {isDisabled && <Tag color="success" label="Invited" />}
        </div>
        <Typography
          className="jobSubjectName"
          color={isDisabled ? "onSurface.400" : "onSurface.800"}
          variant="smallBody"
        >
          {job.subject.name}
        </Typography>
      </div>
      {!isDisabled && (
        <IconButton
          id="edit-btn"
          onClick={(e: any) => {
            e.stopPropagation()

            // handle edit
            onEdit(job.id)
          }}
        >
          <Edit />
        </IconButton>
      )}
    </div>
  )
}
export default JobItem
