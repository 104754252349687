import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  TextField,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"
import toast from "utils/toast"

function ChangePasswordDialog({
  open,
  close,
}: {
  open: boolean
  close: () => void
}) {
  const [loading, setLoading] = useState(false)

  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<{
    oldPassword: string
    newPassword: string
    confirmPassword: string
  }>()

  const onSubmit = handleSubmit(async data => {
    setLoading(true)
    const res = await api.users.changePassword({
      data,
    })
    if (res.isSuccessful) {
      setLoading(false)
      toast.success("Password changed successfully")
      close()
    } else {
      handleErrors(setError, res.errors)
      setLoading(false)
    }
  })

  return (
    <Dialog
      fullScreen={smDown}
      open={open}
      width={518}
      onAfterClose={reset}
      onRequestClose={close}
    >
      <DialogTitle onBack={smDown ? close : undefined}>
        Change Password
      </DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <div className="flex flex-col gap-3 pt-1.75 pb-5">
            <div className="flex items-end flex-col">
              <TextField
                error={Boolean(errors.oldPassword)}
                helperText={errors.oldPassword?.message}
                label="Old Password"
                type="password"
                fullWidth
                {...register("oldPassword", {
                  required: { value: true, message: "Required" },
                })}
              />
              {/* <Button
                className="mt-1.5"
                color="primary"
                component={Link}
                to={routes.forgotPassword}
                variant="link"
              >
                Forgot Password?
              </Button> */}
            </div>

            <TextField
              error={Boolean(errors.newPassword)}
              helperText={errors.newPassword?.message}
              label="New Password"
              type="password"
              fullWidth
              {...register("newPassword", {
                required: { value: true, message: "Required" },
              })}
            />
            <TextField
              error={Boolean(errors.confirmPassword)}
              helperText={errors.confirmPassword?.message}
              label="Confirm New Password"
              type="password"
              fullWidth
              {...register("confirmPassword", {
                required: { value: true, message: "Required" },
              })}
            />
          </div>
        </form>
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: "Change Password",
            onClick: onSubmit,
            loading,
          },
          secondary: {
            label: "Close",
            onClick: close,
          },
        }}
      />
    </Dialog>
  )
}

export default ChangePasswordDialog
