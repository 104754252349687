import {
  Button,
  CircularProgress,
  Pagination,
  theme,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  ApplicationStatus,
  AppliedUser,
  Job,
  JobApplicant,
} from "api/resources/jobs/types"
import { PaginatedResponse } from "api/types"
import clsx from "clsx"
import Filters, { getDefaultFilters } from "components/explore/Filters"
import UserDetailsCard from "components/home/UserDetailsCard"
import AcceptApplicationDialog from "components/jobs/AcceptApplicationDialog"
import RejectApplicationDialog from "components/teacher/Dialogs/RejectApplicationDialog"
import { Cancel, Check } from "iconoir-react"
import React, { useCallback, useMemo, useState } from "react"
import { createUseStyles } from "react-jss"
import useResources from "utils/hooks/useResources"
import toast from "utils/toast"

import NoDataCard from "./NoDataCard"

const useStyles = createUseStyles({
  filter: {
    width: "306px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
})

type Props = {
  job: Pick<Job, "position" | "id">
  isClosed?: boolean
}

const AppliedTab = ({ job, isClosed }: Props) => {
  const classes = useStyles()

  const { subjects, curricula } = useResources(["subjects", "curricula"])

  const [page, setPage] = useState(1)
  const [listLoading, setListLoading] = useState(true)
  const [showMobileFilters, setShowMobileFilters] = useState(false)
  const [dialogLoading, setDialogLoading] = useState(false)
  const [applicants, setApplicants] = useState<
    PaginatedResponse<AppliedUser[]>["data"]
  >({
    data: [],
    nextPage: null,
    previousPage: null,
    total: 0,
  })

  const updateApplicantStatus = (item: JobApplicant) => {
    setApplicants(a => ({
      ...a,
      data: a.data.map(v =>
        v.id === item.id
          ? { ...v, applicationStatus: item.applicationStatus }
          : v
      ),
    }))
  }

  const [appliedUser, setAppliedUser] = useState<AppliedUser | null>(null)

  const [rejectDialogOpen, setRejectDialogOpen] = useState(false)
  const [acceptDialogOpen, setAcceptDialogOpen] = useState(false)

  const handleAction = async (status: ApplicationStatus) => {
    if (!appliedUser) return

    setDialogLoading(true)

    const res = await api.jobs.jobApplicant.update({
      urlParams: {
        jobApplicantId: appliedUser.id,
      },
      data: {
        applicationStatus: status,
      },
    })
    if (res.isSuccessful) {
      setAcceptDialogOpen(false)
      setAppliedUser(null)
      toast.success("Application accepted")
      updateApplicantStatus(res.data)
    } else {
      toast.error(res.errors.message)
    }
    setDialogLoading(false)
  }

  const fetchApplicants = useCallback(
    async (filters: URLSearchParams = new URLSearchParams()) => {
      if (!job.id) return

      setListLoading(true)
      const res = await api.jobs.jobApplicant.listApplied({
        urlParams: {
          jobId: job.id,
        },
        params: filters,
      })
      if (res.isSuccessful) {
        setApplicants(res.data)
        setListLoading(false)
      }
    },
    [job.id]
  )

  const getAction = (item: AppliedUser) => {
    if (item.applicationStatus) {
      if (item.applicationStatus === ApplicationStatus.ACCEPTED)
        return (
          <div className="flex items-center gap-0.5">
            <Check color={theme.colors.success[500]} />
            <Typography color="success.500">Accepted</Typography>
          </div>
        )
      if (item.applicationStatus === ApplicationStatus.REJECTED)
        return (
          <div className="flex items-center gap-0.5">
            <Cancel color={theme.colors.critical[500]} />
            <Typography color="critical.500">Rejected</Typography>
          </div>
        )
    }
    return (
      <div>
        <Button
          className="mr-2"
          color="critical"
          variant="text"
          onClick={() => {
            setAppliedUser(item)
            setRejectDialogOpen(true)
          }}
        >
          Reject
        </Button>
        <Button
          size="sm"
          variant="outlined"
          onClick={() => {
            setAppliedUser(item)
            setAcceptDialogOpen(true)
          }}
        >
          Accept
        </Button>
      </div>
    )
  }

  const otherFilters = React.useMemo(
    () => ({ tab: "Applicants", page: page.toString() }),
    [page]
  )

  const filterChoices = useMemo(
    () =>
      getDefaultFilters().map(filter => {
        if (filter.type === "multi-select") {
          if (filter.id === "subjects[]") {
            filter.options = subjects.map(({ name, uuid }) => ({
              label: name,
              value: uuid,
            }))
          }
        }
        if (filter.type === "multi") {
          if (filter.id === "curriculum[]") {
            filter.options = curricula.map(({ name, uuid }) => ({
              label: name,
              value: uuid,
            }))
          }
        }
        return filter
      }),
    [subjects, curricula]
  )

  return (
    <div className="flex flex-col gap-3 mt-3 sm:flex-row">
      <div className="flex justify-end flex-grow mb-1 sm:hidden">
        <Button
          variant="text"
          onClick={() => setShowMobileFilters(prevState => !prevState)}
        >
          Filters
        </Button>
      </div>
      <div
        className={clsx({
          "hidden sm:block": !showMobileFilters,
        })}
      >
        <Filters
          className={classes.filter}
          disabled={listLoading}
          filters={filterChoices}
          otherFilters={otherFilters}
          onChange={fetchApplicants}
        />
      </div>

      {listLoading ? (
        <div className="flex justify-center flex-grow mt-2">
          <CircularProgress />
        </div>
      ) : (
        <div className="flex-grow">
          {applicants.data.length === 0 ? (
            <NoDataCard message="There are no applicants. Tip: Having a detailed school profile greatly increases the chances of getting more applications." />
          ) : (
            <>
              {applicants.data.map((item, index) => {
                const action = getAction(item)
                return (
                  <UserDetailsCard
                    action={isClosed ? undefined : action}
                    applications={item.applications}
                    jobApplicantId={item.id}
                    key={index}
                    user={item.user}
                    canViewContactDetails
                  />
                )
              })}
            </>
          )}
          <Pagination page={page} total={applicants.total} onChange={setPage} />
        </div>
      )}

      {appliedUser && (
        <RejectApplicationDialog
          afterSubmit={item => (item ? updateApplicantStatus(item) : undefined)}
          jobApplicantId={appliedUser.id}
          jobPosition={job.position}
          mode="application"
          open={rejectDialogOpen}
          toggle={() => {
            setRejectDialogOpen(false)
            setAppliedUser(null)
          }}
          userName={appliedUser.user.fullName}
        />
      )}

      {appliedUser && (
        <AcceptApplicationDialog
          handleClose={() => {
            setAcceptDialogOpen(false)
            setAppliedUser(null)
          }}
          loading={dialogLoading}
          open={acceptDialogOpen}
          onAccept={() => {
            handleAction(ApplicationStatus.ACCEPTED)
          }}
        >
          Accept <b>{appliedUser.user.fullName}</b>’s application for&nbsp;
          <b>{job.position}</b>?
        </AcceptApplicationDialog>
      )}
    </div>
  )
}

export default AppliedTab
