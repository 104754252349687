import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getSolisURL = getServiceURL(ServicePrefix.solis)
const getNebulaURL = getServiceURL(ServicePrefix.nebula)

export const urls = validateUrls({
  generatePlatformToken: () => getSolisURL("/v1/auth/user-tokens/"),
  refreshToken: () => getSolisURL(`/v1/auth/refresh-token/`),

  user: () => getSolisURL("/v1/users/"),
  userJobInfo: () => getNebulaURL("/v1/users/"),
  changePassword: () => getSolisURL("/v1/auth/change-password/"),

  generateOTP: () => getSolisURL("/v1/school-users/otp/"),
  changeEmailId: () => getSolisURL("/v1/school-users/otp/verify/"),
})
