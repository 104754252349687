import { Container, theme, useMediaQuery } from "@suraasa/placebo-ui"
import Navbar from "components/shared/Navbar"
import TeacherProfile from "components/teacher/profile"
import React from "react"

const Profile = () => {
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <>
      <Navbar />
      <Container fullWidth={smDown}>
        <TeacherProfile />
      </Container>
    </>
  )
}

export default Profile
