import { AssessmentQuestion } from "api/resources/assessments/types"
import { nanoid } from "nanoid"

export const getNewOption = (): AssessmentQuestion["options"][0] => ({
  text: "",
  isCorrect: false,
  id: nanoid(),
})

export const getNewQuestion = (): AssessmentQuestion => ({
  options: [getNewOption()],
  text: "",
  id: nanoid(),
  isCreated: false,
  correctAnswer: [],
  isActive: null,
  questionType: 1,
  errors: [],
})

export const validateQuestion = (
  question: AssessmentQuestion
): { isValid: boolean; errors: string[] } => {
  const errors = []

  const correctAnswer = question.options.filter(({ isCorrect }) => isCorrect)
  // At least two options
  if (question.options.length < 2)
    errors.push("Please add at least two options")

  // At least one correct option
  if (correctAnswer.length === 0)
    errors.push("Please select at least one correct option")

  // At least one correct option
  if (question.options.some(({ text }) => text.length === 255))
    errors.push("Options cannot have more than 256 characters in them.")

  // All options cannot be correct
  if (correctAnswer.length === question.options.length)
    errors.push("All options cannot be correct")

  // Question text empty
  if (question.text === "") errors.push("Please add question text")

  return { isValid: errors.length === 0, errors }
}

export const getCorrectOptions = (options: AssessmentQuestion["options"]) => {
  const correct: number[] = []

  options.forEach(({ isCorrect }, i) => {
    if (isCorrect) {
      correct.push(i)
    }
  })

  return correct
}

export const getValidOptions = (options: AssessmentQuestion["options"]) =>
  options.filter(({ text }) => text !== "")
