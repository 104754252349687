import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Job, JobApplicant } from "api/resources/jobs/types"
import clsx from "clsx"
import { Plus } from "iconoir-react"
import React, { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { Link, useNavigate } from "react-router-dom"
import { routes } from "utils/routes"
import toast from "utils/toast"

import JobItem from "./JobItem"

const useStyles = createUseStyles(theme => ({
  dialog: {
    width: "360px",
  },
  dialogContent: {
    maxHeight: "360px",
    overflowY: "auto",
    paddingBottom: theme.spacing(6),
  },

  listing: {
    width: "100%",
    textAlign: "left",
    padding: theme.spacing(1.5, 2),
    borderRadius: "4px",
    "&:hover": {
      background: theme.colors.surface[100],
    },

    "&:active": {
      background: theme.colors.surface[100],
      outline: `1px solid ${theme.colors.surface[300]}`,
    },

    "&:focus-visible": {
      background: theme.colors.surface[100],
      outline: `1px solid ${theme.colors.surface[300]}`,
    },
  },
  selected: {
    background: theme.colors.primary[50],
  },
}))

type Props = {
  open: boolean
  onRequestClose: () => void
  teacherId: string
  onSuccess?: (arg0: JobApplicant) => void
}

const InviteToJobDialog = ({
  open,
  onSuccess,
  onRequestClose,
  teacherId,
}: Props) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const [jobs, setJobs] = useState<Pick<Job, "id" | "position" | "subject">[]>(
    []
  )

  const [selectedJobId, setSelectedJobId] = useState<Job["id"]>()
  const [loading, setLoading] = useState(true)
  const [buttonLoading, setButtonLoading] = useState(false)

  useEffect(() => {
    const listJobs = async () => {
      setLoading(true)

      const res = await api.jobs.list({
        params: {
          page: "all",
          fields: "job_applicant",
          user_uuid: teacherId,
        },
      })

      if (res.isSuccessful) {
        setJobs(res.data.data)
        setLoading(false)
      }
    }

    listJobs()
    return () => {
      setSelectedJobId(undefined)
    }
  }, [teacherId])

  const sendInvite = async () => {
    setButtonLoading(true)

    const res = await api.jobs.jobApplicant.create({
      urlParams: {
        jobId: selectedJobId ?? null,
      },
      data: {
        user: teacherId,
      },
    })

    if (res.isSuccessful) {
      toast.success("Candidate invited!")

      setJobs(jobsData =>
        jobsData.map(i =>
          i.id === selectedJobId ? { ...i, jobApplicant: res.data } : i
        )
      )

      if (onSuccess) {
        onSuccess(res.data)
      }

      onRequestClose()
      setSelectedJobId(undefined)
    } else {
      toast.error(res.errors.message)
    }
    setButtonLoading(false)
  }

  const handleSelect = (id: Job["id"]) => {
    setSelectedJobId(id)
  }

  const handleEdit = (id: Job["id"]) => {
    // edit listing
    navigate(routes.jobEdit.replace(":jobId", String(id)))
  }

  const createNewJobOpening = () => {
    // handle redirect
    navigate(routes.jobCreate)
  }

  const renderContent = () => {
    if (loading) {
      return (
        <div className="flex justify-center mt-5">
          <CircularProgress />
        </div>
      )
    }

    return jobs.length > 0 ? (
      <>
        {jobs.map((listing, index) => (
          <JobItem
            job={listing}
            key={index}
            selected={selectedJobId === listing.id}
            onEdit={handleEdit}
            onSelect={handleSelect}
          />
        ))}

        <Button
          className="mt-1.5"
          component={Link}
          startAdornment={<Plus />}
          to={routes.jobCreate}
          variant="text"
        >
          Create New Job Opening
        </Button>
      </>
    ) : (
      <Typography color="onSurface.500" variant="smallBody">
        You need to have a job opening to invite teachers.
      </Typography>
    )
  }

  return (
    <Dialog
      open={open}
      width={jobs.length > 0 ? 380 : "max-content"}
      onRequestClose={onRequestClose}
    >
      <DialogTitle>Invite to Job Opening</DialogTitle>

      <DialogContent className={clsx(classes.dialogContent)}>
        {renderContent()}
      </DialogContent>

      <DialogFooter
        actions={{
          primary: {
            label: jobs.length > 0 ? "Invite" : "Create New Job Opening",
            onClick: jobs.length > 0 ? sendInvite : createNewJobOpening,
            loading: buttonLoading,
            disabled: loading || (!selectedJobId && jobs.length > 0),
          },
          secondary: {
            label: "Cancel",
            type: "button",
          },
        }}
      />
    </Dialog>
  )
}

export default InviteToJobDialog
