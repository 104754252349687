import { useEffect } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { routes } from "utils/routes"

export enum SkillProfileRoutes {
  OVERVIEW = "overview",
  EVIDENCES_LIST = "evidences",
  EVIDENCE_DETAILS = "evidence-details",
  EVIDENCE_SETTING = "evidence-setting",
  VERIFICATION_PROCESS = "verification-process",
}

type UseGenerateURL = {
  navigate: (path: SkillProfileRoutes, slug?: string) => void
  isOverview: boolean
  isEvidence: boolean
  isEvidenceDetails: boolean
  isVerificationProcess: boolean
}

export const useGenerateURL = (): UseGenerateURL => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { username } = useParams<{ username?: string }>()

  const pathnames = pathname.split("/")

  const routeIndex = pathnames.findIndex(
    item =>
      item === SkillProfileRoutes.OVERVIEW ||
      item === SkillProfileRoutes.EVIDENCES_LIST ||
      item === SkillProfileRoutes.EVIDENCE_DETAILS ||
      item === SkillProfileRoutes.EVIDENCE_SETTING ||
      item === SkillProfileRoutes.VERIFICATION_PROCESS
  )

  useEffect(() => {
    if (!username) return
    if (routeIndex < 0) {
      navigate(
        routes.publicSkillProfile.overview.replace(":username", username)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeIndex, username])

  const isOverview = pathnames[routeIndex] === SkillProfileRoutes.OVERVIEW

  const isEvidence = pathnames[routeIndex] === SkillProfileRoutes.EVIDENCES_LIST
  const isEvidenceDetails =
    pathnames[routeIndex] === SkillProfileRoutes.EVIDENCE_DETAILS
  const isVerificationProcess =
    pathnames[routeIndex] === SkillProfileRoutes.VERIFICATION_PROCESS

  const customNavigation = (path: SkillProfileRoutes, slug?: string) => {
    let newURL = ""
    if (!username) return

    switch (path) {
      case SkillProfileRoutes.OVERVIEW:
        newURL = routes.publicSkillProfile.overview.replace(
          ":username",
          username
        )
        break
      case SkillProfileRoutes.EVIDENCES_LIST:
        if (slug) {
          newURL = routes.publicSkillProfile.evidences
            .replaceAll(":username", username)
            .replaceAll(":slug", slug)
        }
        break
      case SkillProfileRoutes.EVIDENCE_DETAILS:
        if (slug) {
          newURL = routes.publicSkillProfile.evidenceDetails
            .replaceAll(":username", username)
            .replaceAll(":submissionId", slug)
        }
        break
      case SkillProfileRoutes.VERIFICATION_PROCESS:
        newURL = routes.publicSkillProfile.verificationProcess.replace(
          ":username",
          username
        )

        break
      default:
        throw new Error("Shouldn't reach here")
    }

    navigate(newURL)
  }

  return {
    navigate: customNavigation,
    isOverview,
    isEvidence,
    isEvidenceDetails,
    isVerificationProcess,
  }
}
