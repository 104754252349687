import {
  Button,
  Divider,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Currency } from "api/resources/global/types"
import clsx from "clsx"
import Section from "components/Section"
import SectionContent from "components/Section/SectionContent"
import FileUpload from "components/teacher/FileUpload"
import { format } from "date-fns"
import { Download } from "iconoir-react"
import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import { useNavigate, useParams } from "react-router"
import { handleErrors } from "utils/helpers"
import { routes } from "utils/routes"
import toast from "utils/toast"

const DOC_FORMATS = [".doc", ".docx", ".pdf"]
const BYTES_IN_1_MB = 1000 * 1000
const MAX_DOC_SIZE = 5

const useStyles = createUseStyles({
  root: {
    "& .box__input": {
      width: "min(385px,100%) !important",
    },
    "& .PreviewFile-box": {
      width: "min(385px,100%)",
      height: 200,
    },
  },
  optionLabel: {
    cursor: "pointer",
  },
  restrictedWidth: {
    maxWidth: "250px",
  },
})

type Form = {
  currencyId: string
  finalSalary: number
  joiningDate: string
  description: string
  offerLetter: FileList | null
}

const SendOffer = () => {
  const navigate = useNavigate()
  const { jobApplicantId } = useParams()
  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<Form>({})

  const classes = useStyles()

  const fileInputRef = React.useRef<HTMLDivElement>(null)

  const onSubmit = handleSubmit(async formData => {
    if (!formData.offerLetter) {
      fileInputRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
      toast.error("Please upload an offer letter")
      return
    }

    // Create new formData object as we need to attach a file
    // which cannot be directly uploaded without a FormData object
    const form = new FormData()

    form.append("currency_id", formData.currencyId)
    form.append("final_salary", formData.finalSalary.toString())
    form.append("joining_date", new Date(formData.joiningDate).toISOString())
    form.append("description", formData.description)

    if (jobApplicantId) form.append("job_applicant", jobApplicantId)
    form.append("offer_letter", formData.offerLetter[0])

    const res = await api.jobs.jobOffer.create({
      data: form,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })

    if (res.isSuccessful) {
      navigate(routes.offerLetterSent, { replace: true })
    } else {
      if (res.errors.fieldErrors?.offerLetter) {
        toast.error(res.errors.fieldErrors.offerLetter)
        delete res.errors.fieldErrors.offerLetter
      }
      handleErrors(setError, res.errors)
    }
  })

  const [currencyData, setCurrencyData] = useState<Currency[]>([])

  useEffect(() => {
    const getData = async () => {
      const res = await api.global.listCurrencies({ params: { page: -1 } })
      if (res.isSuccessful) {
        setCurrencyData(res.data)
      }
    }
    getData()
  }, [])

  return (
    <>
      <Typography className="mb-2" variant="title3">
        Job Offer Details
      </Typography>

      <Section className="mb-10">
        <SectionContent>
          <div className="flex flex-wrap gap-1.5 justify-between pb-1 items-center">
            <InputLabel label="Upload Offer Letter" required />
            <Button
              component="a"
              href="/assets/JobOfferLetter.docx"
              startAdornment={<Download />}
              target="_blank"
              variant="link"
            >
              Download Offer Letter Template
            </Button>
          </div>
          <div className={classes.root}>
            <Controller
              control={control}
              name="offerLetter"
              render={({ field: { onChange, value } }) => (
                <FileUpload
                  allowedExtensions={DOC_FORMATS}
                  instructions={[
                    `Should not exceed ${MAX_DOC_SIZE} MB`,
                    `Accepted Formats - ${DOC_FORMATS.join(
                      ", "
                    ).toUpperCase()}`,
                  ]}
                  maxSize={MAX_DOC_SIZE * BYTES_IN_1_MB}
                  ref={fileInputRef}
                  value={value}
                  onChange={e => onChange(e)}
                />
              )}
            />
          </div>
          <Divider className="my-4" />
          <form className="flex flex-col gap-3" onSubmit={onSubmit}>
            <div className="flex flex-wrap gap-2 md:flex-nowrap">
              <div>
                <InputLabel label="Salary (per annum)" required />
                <div className="flex flex-col">
                  <div className="grid grid-cols-7 gap-1">
                    <div className="col-span-3">
                      <Controller
                        control={control}
                        name="currencyId"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Select
                            error={Boolean(errors.currencyId)}
                            getOptionLabel={option =>
                              `${option.code} (${option.symbol})`
                            }
                            getOptionValue={option => option.uuid}
                            helperText={errors.currencyId?.message}
                            options={currencyData}
                            placeholder="INR (₹)"
                            value={
                              value
                                ? currencyData.find(i => i.uuid === value)
                                : null
                            }
                            fullWidth
                            isSearchable
                            onBlur={onBlur}
                            onChange={val =>
                              onChange(val?.uuid ? val.uuid : null)
                            }
                          />
                        )}
                        rules={{
                          required: { value: true, message: "Required" },
                        }}
                      />
                    </div>
                    <TextField
                      className={clsx("col-span-4", classes.restrictedWidth)}
                      {...register("finalSalary", {
                        required: { value: true, message: "Required" },
                      })}
                      error={Boolean(errors.finalSalary)}
                      helperText={errors.finalSalary?.message}
                      placeholder="Final Salary Offered"
                      type="number"
                      fullWidth
                    />
                  </div>
                </div>
              </div>

              <TextField
                className={classes.restrictedWidth}
                label="date of joining"
                {...register("joiningDate", {
                  required: { value: true, message: "Required" },
                })}
                error={Boolean(errors.joiningDate)}
                helperText={errors.joiningDate?.message}
                inputLabelProps={{ required: true }}
                min={format(new Date(), "yyyy-MM-dd")}
                type="date"
                fullWidth
              />
            </div>
            {/* @ts-expect-error placebo-issue */}
            <TextField
              charLimit={1000}
              error={Boolean(errors.description)}
              helperText={errors.description?.message}
              label="description"
              placeholder="Details about the job offer"
              rows={4}
              fullWidth
              multiLine
              {...register("description", {
                maxLength: {
                  value: 1000,
                  message: "Description should not exceed 1000 characters",
                },
              })}
            />
            <Button disabled={isSubmitting} type="submit">
              Send Offer
            </Button>
          </form>
        </SectionContent>
      </Section>
    </>
  )
}

export default SendOffer
