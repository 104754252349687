import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  Typography,
} from "@suraasa/placebo-ui"

type Props = {
  open: boolean
  toggle: () => void
  onSubmit: () => void
  position: string
  subjectName: string
}
const CloseJobConfirmationDialog = ({
  open,
  toggle,
  onSubmit,
  position,
  subjectName,
}: Props) => (
  <Dialog open={open} width="sm" onRequestClose={toggle}>
    <DialogTitle>Close Job</DialogTitle>
    <DialogContent>
      <div className=" pb-5">
        <Typography className="mb-1" variant="smallBody">
          Are you sure you want to close{" "}
          <strong>
            {position}, {subjectName}?
          </strong>
        </Typography>
        <Typography className="mb-1" variant="smallBody">
          Closing this job will automatically perform these actions:
        </Typography>
        <Typography className="px-2.5" variant="smallBody">
          <ul>
            <li>
              All the active applications will be cancelled and will be shifted
              to previous applications.
            </li>
            <li>All your invites sent will be expired.</li>
            <li>
              We’ll inform all the teachers who were part of it or applied or
              were invited for this job about its closure.
            </li>
          </ul>
        </Typography>
      </div>
    </DialogContent>
    <DialogFooter
      actions={{
        primary: {
          label: "Close Job",
          onClick: onSubmit,
          color: "critical",
        },
      }}
    />
  </Dialog>
)

export default CloseJobConfirmationDialog
