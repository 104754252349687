import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  TextField,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"

type FormData = {
  description: string
}

type Props = {
  onUpdate: (newData: string) => void
  handleClose: () => void
  data: FormData
} & Pick<DialogProps, "open">

const EditDescriptionDialog = ({
  data: { description },
  open,
  handleClose,
  onUpdate,
}: Props) => {
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      description,
    },
  })

  useEffect(() => {
    reset({ description })
  }, [description, reset])

  const onSubmit = handleSubmit(async formData => {
    setLoading(true)

    const res = await api.profile.update({
      data: { description: formData.description },
    })
    if (res.isSuccessful) {
      onUpdate(res.data.description)
      handleClose()
    } else handleErrors(setError, res.errors)

    setLoading(false)
  })

  return (
    <Dialog
      fullScreen={isXs}
      open={open}
      width={636}
      onAfterClose={reset}
      onRequestClose={handleClose}
    >
      <form onSubmit={onSubmit}>
        <DialogTitle>About</DialogTitle>
        <DialogContent>
          {/* @ts-expect-error placebo-issue */}
          <TextField
            charLimit={500}
            error={Boolean(errors.description)}
            helperText={errors.description?.message}
            maxRows={20}
            rows={8}
            autoFocus
            fullWidth
            multiLine
            {...register("description", {
              required: { value: true, message: "Required" },
              maxLength: {
                value: 500,
                message: "Try to keep it short",
              },
            })}
          />
        </DialogContent>
        <DialogFooter
          actions={{
            primary: {
              label: "Save",
              type: "submit",
              loading,
            },
          }}
        />
      </form>
    </Dialog>
  )
}

export default EditDescriptionDialog
