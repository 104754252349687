import { Typography } from "@suraasa/placebo-ui"
import api from "api"
import { Overview as OverviewType } from "api/resources/jobs/types"
import clsx from "clsx"
import React, { useEffect, useState } from "react"

const Overview = () => {
  const [overview, setOverview] = useState<OverviewType | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      const res = await api.jobs.overview()
      if (res.isSuccessful) {
        setOverview(res.data)
      } else {
        setOverview(null)
      }
    }

    fetchData()
  }, [])

  return overview?.subjects ? (
    <Typography className={clsx("mb-4")} textAlign="center" variant="title2">
      Teachers from {overview?.subjects}+ Subjects
    </Typography>
  ) : null
}

export default Overview
