import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getNebulaURL = getServiceURL(ServicePrefix.nebula)

export const urls = validateUrls({
  school: {
    retrieve: () => getNebulaURL(`/v1/schools/profile/`),
    list: () => getNebulaURL("/v1/schools/"),
    listAccessibleSchools: () => getNebulaURL("/v1/schools/access/"),
    verify: () => getNebulaURL("/v1/schools/verify/"),
  },
})
