import { CssBaseline, theme } from "@suraasa/placebo-ui"
import CheckAccess from "components/auth/CheckAccess"
import Dashboard from "components/auth/Dashboard"
import NoSchoolLinked from "components/auth/NoSchoolLinked"
import ErrorPage from "components/ErrorPage"
import GlobalState from "components/GlobalState"
import Help from "components/help/Help"
import OfferLetterSent from "components/teacher/OfferLetterSent"
import { ThemeProvider } from "react-jss"
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom"
import { getAuthInfo } from "utils/auth"
import { routes } from "utils/routes"
import ToastProvider from "utils/toast/ToastProvider"
// import AllNotifications from "views/AllNotifications"
import AssessmentForm from "views/assessments/Create"
import AssessmentDetails from "views/assessments/Details"
import HiringTools from "views/assessments/HiringTools"
import AssessmentResult from "views/assessments/Result"
import SSO from "views/auth/SSO"
import Explore from "views/Explore"
import Home from "views/Home"
import JobClosed from "views/jobs/Closed"
import JobCreate from "views/jobs/CreateTab"
import JobDetails from "views/jobs/Details"
import PreviousJobApplicants from "views/jobs/Previous"
import Profile from "views/Profile"
import EditProfile from "views/Profile/Edit"
import ProfileVerification from "views/Profile/ProfileVerificationStatus"
import SkillProfile from "views/Profile/SkillProfile"
import Interview from "views/teacher/Interview"
import TeacherProfile from "views/teacher/Profile"
import TeacherTimeline from "views/teacher/TeacherTimeline"
import ViewOffer from "views/teacher/ViewOffer"

const PrivateRoute = () => {
  const auth = getAuthInfo()
  return auth ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: routes.login,
        search: `?next=${window.location.pathname}`,
      }}
      replace
    />
  )
}

const AuthRoutes = () => {
  const auth = getAuthInfo()
  return auth ? <Navigate to={routes.home} /> : <Outlet />
}

function App() {
  // if (process.env.REACT_APP_MAINTENANCE === "true") {
  //   return (
  //     <ThemeProvider theme={theme}>
  //       <CssBaseline />
  //       <Maintenance />
  //     </ThemeProvider>
  //   )
  // }

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <ToastProvider domRoot={document.body}>
          <CssBaseline />

          <GlobalState>
            <Routes>
              {/* Auth */}
              <Route element={<AuthRoutes />}>
                <Route element={<SSO />} path={routes.login} />
              </Route>

              <Route element={<Help />} path={routes.help} />

              <Route
                element={<NoSchoolLinked />}
                path={routes.noSchoolLinked}
              />

              <Route element={<Dashboard />} path="/">
                <Route element={<Explore />} path={routes.explore} />
                <Route
                  element={<TeacherProfile />}
                  path={routes.teacherProfile}
                />

                <Route
                  element={<SkillProfile />}
                  path={routes.publicSkillProfile.root}
                >
                  <Route
                    element={<SkillProfile />}
                    path={routes.publicSkillProfile.overview}
                  />
                  <Route
                    element={<SkillProfile />}
                    path={routes.publicSkillProfile.evidences}
                  />
                  <Route
                    element={<SkillProfile />}
                    path={routes.publicSkillProfile.evidenceDetails}
                  />
                  <Route
                    element={<SkillProfile />}
                    path={routes.publicSkillProfile.verificationProcess}
                  />
                </Route>

                <Route element={<PrivateRoute />} path="/">
                  <Route element={<CheckAccess />} path="/">
                    {/* Home */}
                    <Route element={<Home />} path={routes.home} />

                    {/* <Route
                      element={<AllNotifications />}
                      path={routes.notifications}
                    /> */}

                    {/* Profile */}
                    <Route
                      element={<ProfileVerification />}
                      path={routes.profileVerification}
                    />

                    <Route element={<Profile />} path={routes.profile} />
                    <Route
                      element={<EditProfile />}
                      path={routes.editProfile}
                    />

                    {/* Jobs */}
                    <Route element={<JobCreate />} path={routes.jobCreate} />
                    <Route element={<JobCreate />} path={routes.jobEdit} />
                    <Route element={<JobDetails />} path={routes.jobDetails} />
                    <Route
                      element={<PreviousJobApplicants />}
                      path={routes.previousJobApplicants}
                    />
                    <Route element={<JobClosed />} path={routes.jobClosed} />

                    {/* Assessments */}
                    <Route
                      element={<AssessmentForm />}
                      path={routes.assessmentCreate}
                    />
                    <Route
                      element={<AssessmentForm />}
                      path={routes.assessmentEdit}
                    />
                    <Route
                      element={<AssessmentDetails />}
                      path={routes.assessmentDetails}
                    />
                    <Route
                      element={<AssessmentResult />}
                      path={routes.assessmentResult}
                    />

                    {/* Teachers */}
                    <Route
                      element={<Interview />}
                      path={routes.teacherInterview}
                    />

                    <Route
                      element={<HiringTools />}
                      path={routes.assessments}
                    />

                    <Route
                      element={<TeacherTimeline />}
                      path={routes.teacherTimeline}
                    />
                    <Route
                      element={<OfferLetterSent />}
                      path={routes.offerLetterSent}
                    />
                    <Route element={<ViewOffer />} path={routes.viewOffer} />

                    <Route
                      element={<ErrorPage errorCode={404} />}
                      path={routes.pageNotFound}
                    />
                  </Route>
                </Route>
              </Route>
            </Routes>
          </GlobalState>
        </ToastProvider>
      </ThemeProvider>
    </Router>
  )
}

export default App
