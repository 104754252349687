import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Select,
  theme,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  ApplicationStatus,
  ExploreTeacher,
  InvitationStatus,
  Job,
} from "api/resources/jobs/types"
import { PaginatedResponse } from "api/types"
import clsx from "clsx"
import Filters, { getDefaultFilters } from "components/explore/Filters"
import InvitedTeachersBanner from "components/explore/InvitedTeachersBanner"
import InviteToJobDialog from "components/explore/InviteToJobDialog"
import UserDetailsCard from "components/home/UserDetailsCard"
import NoDataCard from "components/jobs/NoDataCard"
import BackButton from "components/shared/BackButton"
import Navbar from "components/shared/Navbar"
import ReactHelmet from "components/shared/ReactHelmet"
import { ArrowDown, Check, Plus } from "iconoir-react"
import metadata from "metadata.json"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { createUseStyles } from "react-jss"
import { useNavigate, useSearchParams } from "react-router-dom"
import { getAuthInfo } from "utils/auth"
import { getPlatformURL } from "utils/helpers"
import useResources from "utils/hooks/useResources"
import { routes } from "utils/routes"
import toast from "utils/toast"

const useStyles = createUseStyles(({ colors }) => ({
  filter: {
    width: "306px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  hiringFor: {
    background: colors.common.white[500],
    border: `1px solid ${colors.surface[200]}`,
    borderRadius: "4px",
  },
  topMessageBar: {
    background: theme.colors.interactive[50],
    position: "relative",
  },
  closeTopBarButton: {
    position: "absolute",
    right: 24,
    top: "25%",
  },
  viewMore: {
    width: "100%",
    "& > span": {
      justifyContent: "flex-end",
    },
  },

  dropDown: {
    [theme.breakpoints.down("xs")]: {
      width: "240px",
    },
  },
}))

const createNewJobSelectOptionId = -1
const Explore = () => {
  const classes = useStyles()
  const isLoggedIn = Boolean(getAuthInfo())

  const { subjects, curricula } = useResources(["subjects", "curricula"])

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const jobPosition = searchParams.get("jobPosition")
  const jobId = searchParams.get("jobId")

  const [showMobileFilters, setShowMobileFilters] = useState(false)
  const [page, setPage] = useState(1)
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false)
  const [teacher, setTeacher] = useState<ExploreTeacher>()
  const [jobs, setJobs] = useState<Pick<Job, "id" | "position">[]>([])
  const [selectedJob, setSelectedJob] = useState<Pick<
    Job,
    "id" | "position"
  > | null>(
    jobPosition && jobId
      ? {
          id: Number(jobId),
          position: jobPosition,
        }
      : null
  )
  const [invitedTeacherNames, setInvitedTeacherNames] = useState<string[]>([])
  const [teachers, setTeachers] = useState<
    PaginatedResponse<ExploreTeacher[]>["data"]
  >({
    data: [],
    nextPage: null,
    previousPage: null,
    total: 0,
  })

  const [loading, setLoading] = useState(true)

  /**
   * This loading is used when teachers
   * have to invited for a specific job
   */
  const [sendInviteLoading, setSendInviteLoading] = useState<
    ExploreTeacher["user"]["uuid"] | null
  >(null)

  const fetchApplicants = useCallback(
    async (filters: URLSearchParams = new URLSearchParams()) => {
      setLoading(true)
      const res = await api.teacher.explore.list({
        params: filters,
      })

      if (res.isSuccessful) {
        const filtersPage = filters.get("page")
        /**
         * if page is not defined or 1 then it's first page
         * and we dont' want to append to previous list
         */
        if (!filtersPage || filtersPage === "1") {
          setTeachers(res.data)
        } else {
          setTeachers(prevState => ({
            ...res.data,
            data: [...prevState.data, ...res.data.data],
          }))
        }
        setLoading(false)
      }
    },
    []
  )

  const fetchMoreApplicants = () => {
    const filters = searchParams
    setPage(teachers.nextPage ?? (p => p + 1))
    filters.set("page", String(teachers.nextPage ?? page + 1))
    fetchApplicants(filters)
  }

  const otherFilters = React.useMemo(
    () => ({
      jobPosition: jobPosition ?? "",
      jobId: jobId ?? "",
    }),
    [jobPosition, jobId]
  )

  const filterChoices = useMemo(
    () =>
      getDefaultFilters().map(filter => {
        if (filter.type === "multi-select") {
          if (filter.id === "subjects[]") {
            filter.options = subjects.map(({ name, uuid }) => ({
              label: name,
              value: uuid,
            }))
          }
        }

        if (filter.type === "multi") {
          if (filter.id === "curriculum[]") {
            filter.options = curricula.map(({ name, uuid }) => ({
              label: name,
              value: uuid,
            }))
          }
        }
        return filter
      }),
    [subjects, curricula]
  )

  const sendInvite = async (teacherId: string) => {
    setSendInviteLoading(teacherId)
    const res = await api.jobs.jobApplicant.create({
      urlParams: {
        jobId: jobId ?? null,
      },
      data: {
        user: teacherId,
      },
    })

    if (res.isSuccessful) {
      const invitedTeacher = teachers.data.find(
        item => item.user.uuid === teacherId
      )?.user.firstName

      if (invitedTeacher)
        setInvitedTeacherNames(prevState => [...prevState, invitedTeacher])

      toast.success("Candidate Invited!")
      setTeachers(item => ({
        ...item,
        data: item.data.map(v =>
          v.user.uuid === teacherId
            ? {
                ...v,
                jobApplicant: {
                  ...v.jobApplicant,
                  id: res.data.id,
                  applicationStatus: res.data.applicationStatus,
                  invitationStatus: res.data.invitationStatus,
                },
              }
            : v
        ),
      }))
    } else {
      toast.error(res.errors.message)
    }
    setSendInviteLoading(null)
  }

  const getAction = (item: ExploreTeacher) => {
    if (item.jobApplicant?.invitationStatus) {
      if (item.jobApplicant.invitationStatus === InvitationStatus.PENDING)
        return (
          <div className="flex items-center gap-0.5">
            <Check color={theme.colors.success[500]} />
            <Typography color="success.500">Invited</Typography>
          </div>
        )

      if (item.jobApplicant.invitationStatus === InvitationStatus.REJECTED)
        return (
          <Button
            loading={sendInviteLoading === item.user.uuid}
            startAdornment={<Plus />}
            variant="text"
            onClick={() => {
              if (!isLoggedIn)
                window.location.href = getPlatformURL(
                  "sso",
                  `school/signup/?next=${encodeURIComponent(
                    window.location.href
                  )}`
                )

              if (jobId) sendInvite(item.user.uuid)
              else {
                setTeacher(item)
                setInviteDialogOpen(true)
              }
            }}
          >
            Invite
          </Button>
        )

      if (item.jobApplicant.invitationStatus === InvitationStatus.ACCEPTED)
        return (
          <div className="flex items-center gap-0.5">
            <Check color={theme.colors.success[500]} />
            <Typography color="success.500">Accepted</Typography>
          </div>
        )
    }
    if (item.jobApplicant?.applicationStatus) {
      if (item.jobApplicant.applicationStatus === ApplicationStatus.PENDING)
        return (
          <div className="flex items-center gap-0.5">
            <Check color={theme.colors.success[500]} />
            <Typography color="success.500">Applied</Typography>
          </div>
        )
      if (item.jobApplicant.applicationStatus === ApplicationStatus.ACCEPTED)
        return (
          <div className="flex items-center gap-0.5">
            <Check color={theme.colors.success[500]} />
            <Typography color="success.500">Accepted</Typography>
          </div>
        )
    }
    return (
      <Button
        loading={sendInviteLoading === item.user.uuid}
        startAdornment={<Plus />}
        variant="text"
        onClick={() => {
          if (!isLoggedIn) {
            window.location.href = getPlatformURL(
              "sso",
              `school/signup/?next=${encodeURIComponent(window.location.href)}`
            )

            return
          }
          if (jobId) sendInvite(item.user.uuid)
          else {
            setTeacher(item)
            setInviteDialogOpen(true)
          }
        }}
      >
        Invite
      </Button>
    )
  }

  useEffect(() => {
    const listJobs = async () => {
      const res = await api.jobs.list({
        params: {
          page: "all",
        },
      })

      if (res.isSuccessful) {
        setJobs([
          ...res.data.data,
          {
            id: createNewJobSelectOptionId,
            position: "Create New Job",
          },
        ])
      }
    }

    listJobs()
  }, [])

  return (
    <>
      <ReactHelmet data={metadata.explore} />
      <Navbar
        gutterBottom={!(selectedJob && invitedTeacherNames.length > 0)}
        hideBackButton
      />
      {selectedJob && (
        <InvitedTeachersBanner
          job={selectedJob}
          teachers={invitedTeacherNames}
          onCancel={() => setInvitedTeacherNames([])}
        />
      )}

      <Container className="mb-6">
        <BackButton className="mb-2" />
        <div>
          <div className="flex flex-wrap gap-2 gap-y-2 justify-between mb-1.5 items-center">
            <Typography component="h1" variant="title2">
              Teachers
            </Typography>

            {isLoggedIn && (
              <div className="flex items-center justify-between flex-grow gap-1 sm:justify-start sm:flex-grow-0">
                <Typography
                  color="onSurface.500"
                  style={{ whiteSpace: "nowrap" }}
                  variant="smallBody"
                >
                  Hiring for
                </Typography>

                <Select
                  className={classes.dropDown}
                  getOptionLabel={option => option.position}
                  getOptionValue={option => option.id.toString()}
                  name="jobId"
                  options={jobs}
                  placeholder="Select Job"
                  value={selectedJob}
                  isClearable
                  isSearchable
                  mountOnBody
                  onChange={v => {
                    setInvitedTeacherNames([])
                    setSelectedJob(v)
                    setPage(1)
                    if (v) {
                      if (v.id === createNewJobSelectOptionId) {
                        navigate(routes.jobCreate)
                        return
                      }
                      navigate(
                        `/explore/?jobId=${v.id}&jobPosition=${v.position}`,
                        { replace: true }
                      )
                    } else {
                      navigate(`/explore`, { replace: true })
                    }
                  }}
                />
              </div>
            )}
          </div>
          <div className="flex justify-end flex-grow mb-1 sm:hidden">
            <Button
              variant="text"
              onClick={() => setShowMobileFilters(prevState => !prevState)}
            >
              Filters
            </Button>
          </div>

          <Divider className="mb-3" />
        </div>
        <div className="flex flex-col gap-3 sm:flex-row">
          <div
            className={clsx({
              "hidden sm:block": !showMobileFilters,
            })}
          >
            <Filters
              className={classes.filter}
              disabled={loading}
              filters={filterChoices}
              otherFilters={otherFilters}
              onChange={fetchApplicants}
            />
          </div>

          <div className="flex-grow">
            {loading && page === 1 ? (
              <div className="flex items-center justify-center">
                <CircularProgress />
              </div>
            ) : (
              <>
                {teachers.data.length > 0 ? (
                  teachers.data.map(item => (
                    <UserDetailsCard
                      action={getAction(item)}
                      applications={item.interviewingSchools}
                      canViewContactDetails={item.canViewContactDetails}
                      jobApplicantId={item.jobApplicant?.id}
                      key={item.user.uuid}
                      user={item.user}
                      verifiedSkillEvidences={item.verifiedSkillEvidences}
                    />
                  ))
                ) : (
                  <NoDataCard message="Looks like nothing matches this filter criteria. Try something else." />
                )}
                {teachers.data.length < teachers.total && (
                  <>
                    <Divider />
                    <Button
                      className={clsx("mt-0.25", classes.viewMore)}
                      color="black"
                      endAdornment={<ArrowDown />}
                      loading={page > 1 && loading}
                      variant="text"
                      onClick={fetchMoreApplicants}
                    >
                      View More Teachers
                    </Button>
                  </>
                )}
              </>
            )}
          </div>
        </div>

        {teacher && (
          <InviteToJobDialog
            open={inviteDialogOpen}
            teacherId={teacher.user.uuid}
            onRequestClose={() => setInviteDialogOpen(false)}
          />
        )}
      </Container>
    </>
  )
}

export default Explore
