import api from "api"
import {
  Country,
  Currency,
  Curriculum,
  Subject,
} from "api/resources/global/types"
import { Amenity, Perk } from "api/resources/profile/types"
import { useEffect, useState } from "react"

type Resources =
  | "currencies"
  | "subjects"
  | "countries"
  | "perks"
  | "amenities"
  | "curricula"

type Props = Resources[]

/**
 *
 * @param resource
 * @returns values of resources passed,
 * returns empty array if a specific resource is not passed
 */
const useResources = (resource: Props) => {
  const [currencies, setCurrencies] = useState<Currency[]>([])
  const [subjects, setSubjects] = useState<Subject[]>([])
  const [countries, setCountries] = useState<Country[]>([])
  const [perks, setPerks] = useState<Perk[]>([])
  const [amenities, setAmenities] = useState<Amenity[]>([])
  const [curricula, setCurricula] = useState<Curriculum[]>([])

  useEffect(() => {
    const fetchResources = async () => {
      if (resource.includes("currencies")) {
        const listCurrencies = await api.global.listCurrencies({
          params: { page: -1 },
        })

        if (listCurrencies.isSuccessful) {
          setCurrencies(listCurrencies.data)
        }
      }
      if (resource.includes("countries")) {
        const listCountries = await api.global.listCountries({
          params: { page: -1 },
        })
        if (listCountries.isSuccessful) {
          setCountries(listCountries.data)
        }
      }

      if (resource.includes("subjects")) {
        const listSubjects = await api.global.subjects.list({
          params: { page: -1 },
        })
        if (listSubjects.isSuccessful) {
          setSubjects(listSubjects.data)
        }
      }

      if (resource.includes("curricula")) {
        const listCurricula = await api.global.curricula.list({
          params: { page: -1 },
        })
        if (listCurricula.isSuccessful) {
          setCurricula(listCurricula.data)
        }
      }
      if (resource.includes("perks")) {
        const listPerks = await api.profile.perks.list()
        if (listPerks.isSuccessful) {
          setPerks(listPerks.data)
        }
      }
      if (resource.includes("amenities")) {
        const listAmenities = await api.profile.amenities.list()

        if (listAmenities.isSuccessful) {
          setAmenities(listAmenities.data)
        }
      }
    }
    fetchResources()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    currencies,
    subjects,
    countries,
    perks,
    amenities,
    curricula,
  }
}

export default useResources
