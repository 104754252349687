import { Button } from "@suraasa/placebo-ui"
import { InterviewDetailsStep } from "api/resources/jobs/types"
import React, { useContext, useState } from "react"
import { useParams } from "react-router-dom"
import useToggle from "utils/hooks/useToggle"
import TeacherTimelineContext from "views/teacher/context"

import EditInterviewDialog from "../Dialogs/EditInterviewDialog"
import Card from "./Card"

const Interviews = () => {
  const { actionsAllowed, interviews } = useContext(TeacherTimelineContext)
  const { jobApplicantId } = useParams()

  const [openInterviewDialog, toggleOpenInterviewDialog] = useToggle(false)
  const [interviewToEdit, setInterviewToEdit] = useState<InterviewDetailsStep>()

  const handleInterviewEdit = (interview: InterviewDetailsStep) => {
    setInterviewToEdit(interview)
    toggleOpenInterviewDialog()
  }

  return (
    <div className="flex flex-col gap-2">
      <EditInterviewDialog
        afterSubmit={data =>
          interviewToEdit
            ? interviews.update(interviewToEdit?.id, data)
            : interviews.add(data)
        }
        data={interviewToEdit}
        jobApplicant={jobApplicantId}
        open={openInterviewDialog}
        toggle={() => {
          toggleOpenInterviewDialog()
          setInterviewToEdit(undefined)
        }}
      />

      <Button
        className="ml-auto"
        disabled={!actionsAllowed}
        variant="outlined"
        onClick={() => toggleOpenInterviewDialog()}
      >
        Add Interview
      </Button>
      {interviews.data.map(interview => (
        <Card
          {...interview}
          handleInterviewEdit={() => handleInterviewEdit(interview)}
          key={interview.id}
        />
      ))}
    </div>
  )
}

export default Interviews
